import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WidgetCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.widget-card-wrap': {
    padding: 24,
    border: '1px solid' + theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    '.widget-title': {
      fontSize: '20px',
      lineHeight: '32px'
    },
    '.list-item-wrap': {
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      }
    },
    '.list-item': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      '.list-img': {
        height: 48,
        width: 48,
        flexShrink: 0,
        marginRight: 16,
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        img: {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center center'
        }
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    '.icon-text': {
      '.icon': {
        maxHeight: 24,
        img: {
          maxHeight: 24
        }
      }
    }
  }
}))
