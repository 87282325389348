import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '../../../Layout/components'
import { Main } from './packageManagementStyle'
import {
  AddNewPackageDrawerComponent,
  PackageManagementTableComponent,
  PackagePageHeaderComponent
} from '../../components'

export const PackageManagementPage = ({}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isUnpublished, setIsUnpublished] = useState(false)
  return (
    <Main open={isOpen}>
      <PackagePageHeaderComponent
        fnAddNewProduct={() => {
          setIsOpen(true)
        }}
      />
      <PackageManagementTableComponent />
      {isOpen && <AddNewPackageDrawerComponent />}
    </Main>
  )
}
