import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, DuoTab } from '@ntpkunity/controls'
import { ContentWrap } from './productConfigTabsStyle'
import {
  IntroductionComponent,
  FeaturesComponent,
  SupportComponent,
  HowToConnectComponent,
  TestimonialsComponent,
  FaqsComponent,
  PricingComponent,
  HowToConnectFields,
  CustomerLogoStripsComponent
} from '@modules/ProductConfigurations/ProductConfigurationsModule'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from '@shared/helper'
interface IProductConfigTabsComponentProps {
  filesToBeDeleted: string[]
}

export const ProductConfigTabsComponent: FC<
IProductConfigTabsComponentProps
> = ({ filesToBeDeleted }) => {
  const theme = useTheme()
  const {
    formState: { errors },
    getValues
  } = useFormContext()

  return (
    <DuoTab
      theme={theme}
      varient="underline"
      items={[
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <IntroductionComponent filesToBeDeleted={filesToBeDeleted} />
            </ContentWrap>
          ),
          title: (
            <Box
              className={
                Object.keys(errors).includes('introduction') ? 'error' : ''
              }
              theme={theme}
            >
              Introduction
            </Box>
          )
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <PricingComponent />
            </ContentWrap>
          ),
          title: (
            <Box
              className={Object.keys(errors).includes('prices') ? 'error' : ''}
              theme={theme}
            >
              Pricing
            </Box>
          )
        },
        {
          content: 'Coming Soon',
          title: 'Comparison'
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <FeaturesComponent filesToBeDeleted={filesToBeDeleted} />
            </ContentWrap>
          ),
          title: (
            <Box
              className={
                Object.keys(errors).includes('features') ? 'error' : ''
              }
              theme={theme}
            >
              Features
            </Box>
          )
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <HowToConnectComponent />
            </ContentWrap>
          ),
          title: (
            <Box
              className={
                !isEmpty(getValues(HowToConnectFields.KEY))
                  ? Object.keys(errors).includes(`${HowToConnectFields.KEY}`)
                    ? 'error'
                    : ''
                  : ''
              }
              theme={theme}
            >
              How to Connect
            </Box>
          )
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <SupportComponent filesToBeDeleted={filesToBeDeleted} />
            </ContentWrap>
          ),
          title: (
            <Box
              className={
                !isEmpty(getValues('support'))
                  ? Object.keys(errors).includes('support')
                    ? 'error'
                    : ''
                  : ''
              }
              theme={theme}
            >
              Support
            </Box>
          )
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <FaqsComponent />
            </ContentWrap>
          ),
          title: (
            <Box
              className={Object.keys(errors).includes('faqs') ? 'error' : ''}
              theme={theme}
            >
              FAQ's
            </Box>
          )
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <TestimonialsComponent filesToBeDeleted={filesToBeDeleted} />
            </ContentWrap>
          ),
          title: (
            <Box
              className={
                Object.keys(errors).includes('testimonials') ? 'error' : ''
              }
              theme={theme}
            >
              Testimonials
            </Box>
          )
        },
        {
          content: (
            <ContentWrap theme={theme} className="content-wrap">
              <CustomerLogoStripsComponent
                filesToBeDeleted={filesToBeDeleted}
              />
            </ContentWrap>
          ),
          title: (
            <Box
              className={
                Object.keys(errors).includes('customers_logos') ? 'error' : ''
              }
              theme={theme}
            >
              Customer Logos
            </Box>
          )
        }
      ]}
      defaultTabIndex={0}
    />
  )
}
