import React, { type FC, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { WidgetWrap } from './RevenueSplitComponentStyle'
import { ListWidget } from '@shared/components/ListWidget/listWidget'
import { Box, Typography, Select } from '@ntpkunity/controls'
import ArrowUp from '../../../../shared/assets/images/ic-arrow-up-green.svg'
import ArrowDown from '../../../../shared/assets/images/ic-arrow-down-red.svg'
import MinusPrimary from '../../../../shared/assets/images/ic-minus-primary.svg'

import { IProduct, type ITopRevenueProducts } from '@shared/typings'
import { TrendColor } from '@shared/constants'
import {
  convertToAmount,
  convertToDecimal,
  convertToDecimal2digit
} from '@shared/helper/methods'
import { useGetProducts, useGetRevenueByPlan } from './RevenueSplitService'
import { NoData } from '@shared/components/NoData/noData'

interface RevenueSplitComponentProps {
  startDate: Date
  endDate: Date
}

export const RevenueSplitComponent: FC<RevenueSplitComponentProps> = ({
  startDate,
  endDate
}) => {
  const { data: products } = useGetProducts()
  const [selectedProduct, setSelectedProduct] = useState<number>()
  const theme = useTheme()
  const [topRevenueProducts, setTopRevenueProducts] = useState<
  ITopRevenueProducts[] | undefined
  >()
  const { data } = useGetRevenueByPlan(selectedProduct, startDate, endDate)

  const productOptions = useMemo(
    () =>
      products
        ?.sort((a, b) => a.name?.localeCompare(b.name))
        ?.reduce(
          (agg, type) => [...agg, { text: type.name, value: type.id }],
          []
        ),
    [products]
  )

  useEffect(() => {
    setTopRevenueProducts(data)
  }, [data])

  useEffect(() => {
    if (Object.keys(productOptions ?? {}).length > 0) {
      setSelectedProduct(productOptions?.[0]?.value)
    }
  }, [productOptions])

  return (
    <WidgetWrap theme={theme} className="widget-wrap">
      <ListWidget
        theme={theme}
        widgetTitle={'Revenue Split By Subscribed Plans'}
      >
        <Box theme={theme} className="underline-select">
          <Select
            label="Select Product"
            fullWidth
            value={selectedProduct ?? ''}
            theme={theme}
            items={productOptions ?? []}
            onChange={(e) => { setSelectedProduct(e.target.value as number) }}
            sxProps={false}
            placeholder="Select"
          />
        </Box>
        <Box theme={theme} className="list-item-wrap">
          {(topRevenueProducts ?? []).length > 0
            ? (
                topRevenueProducts?.map(
                  (products: ITopRevenueProducts, index: number) => {
                    return (
                  <Box key={index} theme={theme} className="list-item">
                    <Box theme={theme} className="list-img">
                      <img
                        src={products.image_url}
                        alt={products.product_name}
                      />
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="body1"
                        component="p"
                        className="fw-medium"
                      >
                        {products?.package_name}
                      </Typography>
                      <Box
                        theme={theme}
                        className="counter-text"
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Typography
                          theme={theme}
                          variant="body2"
                          component="span"
                          display={'block'}
                          className="text-muted"
                        >
                          {'$' + convertToAmount(products.current_revenue)}
                        </Typography>
                        <Box
                          theme={theme}
                          className="icon-text"
                          display={'flex'}
                          alignItems={'center'}
                          ml={1}
                        >
                          <Box
                            theme={theme}
                            className="icon"
                            mr={0.5}
                            flexShrink={0}
                          >
                            {products.trend_percentage > 0
                              ? (
                              <img src={ArrowUp} alt="Up" />
                                )
                              : products.trend_percentage < 0
                                ? (
                              <img src={ArrowDown} alt="Down" />
                                  )
                                : (
                              <img src={MinusPrimary} alt="Neutral" />
                                  )}
                          </Box>
                          <Typography
                            theme={theme}
                            variant="body1"
                            component="p"
                            className={
                              products.trend_percentage > 0
                                ? 'text-success fw-medium'
                                : products.trend_percentage < 0
                                  ? 'text-danger fw-medium'
                                  : products.trend_percentage === 0
                                    ? 'text-primary fw-medium'
                                    : 'fw-medium'
                            }
                            flexGrow={1}
                          >
                            {products.trend_percentage !== null
                              ? convertToDecimal(products.trend_percentage) +
                                '%'
                              : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                    )
                  }
                )
              )
            : (
            <NoData />
              )}
        </Box>
      </ListWidget>
    </WidgetWrap>
  )
}

RevenueSplitComponent.propTypes = {}

RevenueSplitComponent.defaultProps = {}
