import React, { type FC, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { CounterWidget } from '@shared/components/CounterWidget/counterWidget'
import { useGetSubscriberRetention } from './SubscriberRetentionService'
import { type ISubscriberRetention, ITopSubscribedProducts } from '@shared/typings'
import { convertToDecimal } from '@shared/helper'
interface SubscriberRetentionComponentProps {
  startDate: Date
  endDate: Date
}
export const SubscriberRetentionComponent: FC<
SubscriberRetentionComponentProps
> = ({ startDate, endDate }) => {
  const theme = useTheme()
  const { data } = useGetSubscriberRetention(startDate, endDate)
  const [subscriberRetention, setSubscriberRetention] = useState<
  ISubscriberRetention | undefined
  >()

  useEffect(() => {
    if ((data ?? []).length > 0) {
      setSubscriberRetention(data[0])
    }
  }, [data])
  return (
    <CounterWidget
      theme={theme}
      title={'Subscriber Retention'}
      counterText={`${convertToDecimal(
        +(subscriberRetention?.retention_rate ?? 0)
      )}%`}
      rankImage={true}
      {...(subscriberRetention?.retention_rate > 0
        ? { rankImageUp: true }
        : { rankImageDown: true })}
      // rankText={}
      rankTextSuccess={true}
    />
  )
}

SubscriberRetentionComponent.propTypes = {}

SubscriberRetentionComponent.defaultProps = {}
