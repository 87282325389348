import { type FC, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { ContentAreaWrap } from './IntroductionStyle'
import {
  FileDragDrop,
  FilePreview,
  Grid,
  Input,
  Select,
  Textarea,
  Typography
} from '@ntpkunity/controls'
import {
  Controller,
  type UseFormReturn,
  useFormContext,
  useWatch
} from 'react-hook-form'
import {
  IAWSFileUploadData,
  type IFormData,
  type IUploadMetaData
} from '@shared/typings'
import {
  defaultValues,
  uploadFileMetaDataInitialValue
} from '@shared/constants/productConfigurations'
import {
  fileMetadata,
  openInNextWindow,
  S3FileKey,
  uploadFileToS3Bucket
} from '@shared/helper'
import {
  useDeleteFile,
  useUploadFile
} from '@modules/ProductConfigurations/services'
import { FileDragDropWrap } from '@shared/components/FileDragDropWrap/FileDragDropWrap'
import { ProductState } from '@shared/constants'

const messages = {
  name: {
    productName: 'introduction.name',
    productHeadline: 'introduction.products_tag_line',
    demoVideoUrl: 'introduction.demo_video_url',
    state: 'introduction.state',
    productPath: 'introduction.product_path',
    productDescription: 'introduction.description',
    productThumbnail: 'introduction.image_url',
    productFavicon: 'introduction.icon_url',
    productThumbnail_metadata: 'introduction.meta_data.image_url',
    productFavicon_metadata: 'introduction.meta_data.icon_url',
    productThumbnail_description: 'introduction.product_thumbnail'
  },
  label: {
    productName: 'Product Name',
    productHeadline: 'Product Headline',
    demoVideoUrl: 'Demo Video URL',
    state: 'State',
    productPath: 'Product Path',
    productDescription: 'Product Description',
    productThumbail: 'Product Thumbnail Description'
  },
  placeholder: {
    typeHere: 'Type here...',
    productPath: 'www.sample.com'
  }
}
interface IIntroductionComponentProps {
  filesToBeDeleted: string[]
}

export const IntroductionComponent: FC<IIntroductionComponentProps> = ({
  filesToBeDeleted
}) => {
  const theme = useTheme()
  const [, setRerender] = useState<number>(0)
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors
  } = useFormContext<IFormData>()
  const { mutate: uploadFile1, isLoading: uploadingFile1 } = useUploadFile(
    setValue,
    trigger
  )
  const { mutate: uploadFile2, isLoading: uploadingFile2 } = useUploadFile(
    setValue,
    trigger
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const onFileRemove = (formFieldId, metadataId) => {
    deleteFile(formFieldId)
    setRerender((prevValue) => prevValue + 1)
    setValue(formFieldId, '', { shouldDirty: true })
    setValue(metadataId, uploadFileMetaDataInitialValue)
  }

  const uploadFile = (file, componentId, formFieldId, metadataId) => {
    if (componentId === 0) {
      uploadFileToS3Bucket(
        uploadFile1,
        { file, formFieldId, metadataId },
        deleteFile
      )
    } else {
      uploadFileToS3Bucket(
        uploadFile2,
        { file, formFieldId, metadataId },
        deleteFile
      )
    }
  }

  const onImageUpload = (e: any, componentId, formFieldId, metadataId) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      if (e?.target?.files[0]?.type.startsWith('image/')) {
        uploadFile(e.target.files[0], componentId, formFieldId, metadataId)
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.'
        })
      }
    }
  }

  const onImageDrop = (e: any, componentId, formFieldId, metadataId) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      if (e?.dataTransfer?.files[0]?.type.startsWith('image/')) {
        uploadFile(
          e.dataTransfer.files[0],
          componentId,
          formFieldId,
          metadataId
        )
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.'
        })
      }
    }
  }

  const formData = useWatch({ name: 'introduction' })
  const requiredFields = [
    'name',
    'products_tag_line',
    'description',
    'image_url',
    'icon_url'
  ]
  const isAnyRequiredFieldMissing = useMemo(
    () => requiredFields.some((field) => !formData[field]),
    [formData]
  )

  const productThumbnailUploaded: boolean =
    (getValues(messages.name.productThumbnail as any) ?? '').length > 0 &&
    !uploadingFile1
  const productFaviconUploaded: boolean =
    (getValues(messages.name.productFavicon as any) ?? '').length > 0 &&
    !uploadingFile2

  const productThumbnailFileMetadata: IUploadMetaData = getValues(
    messages.name.productThumbnail_metadata as any
  )
  const productThumbnailFileName = productThumbnailFileMetadata?.name

  const productFaviconFileMetadata: IUploadMetaData = getValues(
    messages.name.productFavicon_metadata as any
  )
  const productFaviconFileName = productFaviconFileMetadata?.name

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <Grid theme={theme} container item spacing={3}>
        <Grid theme={theme} item xs={12} md={3}>
          <Controller
            name={messages.name.productName as any}
            control={control}
            render={({ field }) => (
              <Input
                testid="product-name"
                theme={theme}
                fullWidth
                type={'text'}
                {...field}
                label={messages.label.productName}
                placeholder={messages.placeholder.typeHere}
                error={errors?.introduction?.name?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
          <Controller
            name={messages.name.productThumbnail_description as any}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                type={'text'}
                {...field}
                label={messages.label.productThumbail}
                placeholder={messages.placeholder.typeHere}
                error={errors?.introduction?.product_thumbnail?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
          <Controller
            name={messages.name.productHeadline as any}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                type={'text'}
                {...field}
                label={messages.label.productHeadline}
                placeholder={messages.placeholder.typeHere}
                error={errors?.introduction?.products_tag_line?.message}
              />
            )}
          />
        </Grid>

        <Grid theme={theme} item xs={12} md={3}>
          <Controller
            name={messages.name.state as any}
            defaultValue={defaultValues.introduction.state}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={messages.label.state}
                items={[
                  { text: ProductState.DRAFT, value: ProductState.DRAFT },
                  {
                    text: ProductState.UNDER_DEVELOPMENT,
                    value: ProductState.UNDER_DEVELOPMENT
                  },
                  { text: ProductState.LIVE, value: ProductState.LIVE }
                ]}
                sxProps={false}
                placeholder="Select"
                disablePortal={false}
                disabled={isAnyRequiredFieldMissing}
                value={value}
                selectError={errors?.introduction?.state?.message}
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
          <Controller
            name={messages.name.demoVideoUrl as any}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                type={'text'}
                {...field}
                label={messages.label.demoVideoUrl}
                placeholder={messages.placeholder.typeHere}
                error={errors?.introduction?.demo_video_url?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={8}>
          <Controller
            name={messages.name.productPath as any}
            control={control}
            render={({ field }) => (
              <Input
                testid="product-path"
                theme={theme}
                fullWidth
                type={'text'}
                {...field}
                label={messages.label.productPath}
                placeholder={messages.placeholder.productPath}
                error={errors?.introduction?.product_path?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={messages.name.productDescription as any}
            control={control}
            render={({ field }) => (
              <Textarea
                label={messages.label.productDescription}
                id={''}
                theme={theme}
                fullWidth
                type={'text'}
                rows={3}
                {...field}
                placeholder={messages.placeholder.typeHere}
                error={errors?.introduction?.description?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <FileDragDropWrap className="file-drag-drop-wrap">
            {!productThumbnailUploaded ? (
              <>
                <Controller
                  name={messages.name.productThumbnail as any}
                  control={control}
                  render={({ field }) => (
                    <FileDragDrop
                      {...field}
                      id={messages.name.productThumbnail}
                      theme={theme}
                      imageButton={false}
                      isLoading={uploadingFile1}
                      accept={'image/*'}
                      hoverLabel={
                        <>
                          Drag and drop product thumbnail, or{' '}
                          <span className="text-primary link">browse</span>
                        </>
                      }
                      onDrop={(event: React.DragEvent<HTMLInputElement>) => {
                        onImageDrop(
                          event,
                          0,
                          messages.name.productThumbnail,
                          messages.name.productThumbnail_metadata
                        )
                        // field.onChange(event.dataTransfer.files[0])
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        onImageUpload(
                          event,
                          0,
                          messages.name.productThumbnail,
                          messages.name.productThumbnail_metadata
                        )
                        // field.onChange(event)
                      }}
                      error={Boolean(errors?.introduction?.image_url?.message)}
                    />
                  )}
                />
                {errors?.introduction?.image_url?.message?.length > 0 &&
                errors?.introduction?.image_url?.type === 'checkFileType'
                  ? (
                  <Typography
                    mt={{ md: 1, xs: 2 }}
                    theme={theme}
                    variant="caption"
                    component="small"
                    display={'block'}
                    className={`${
                      errors?.introduction?.image_url?.type === 'checkFileType'
                        ? 'text-error'
                        : ''
                    }`}
                  >
                    {errors?.introduction?.image_url?.message}
                  </Typography>
                    )
                  : null}
              </>
            ) : (
              <FilePreview
                files={[
                  (productThumbnailFileName ?? '').length > 0
                    ? productThumbnailFileMetadata
                    : uploadFileMetaDataInitialValue
                ]}
                preview={'imageIcon'}
                viewIcon
                onRemoveFile={() => {
                  onFileRemove(
                    messages.name.productThumbnail,
                    messages.name.productThumbnail_metadata
                  )
                }
                }
                onClickIcon={() =>
                  openInNextWindow(
                    getValues(messages.name.productThumbnail as any)
                  )
                }
                theme={theme}
              />
            )}
          </FileDragDropWrap>
        </Grid>
        <Grid theme={theme} item xs={6}>
          <FileDragDropWrap className="file-drag-drop-wrap">
            {!productFaviconUploaded ? (
              <>
                <Controller
                  name={messages.name.productFavicon as any}
                  control={control}
                  render={({ field }) => (
                    <FileDragDrop
                      {...field}
                      id={messages.name.productFavicon}
                      theme={theme}
                      imageButton={false}
                      isLoading={uploadingFile2}
                      accept={'image/*'}
                      hoverLabel={
                        <>
                          Drag and drop favicon, or{' '}
                          <span className="text-primary link">browse</span>
                        </>
                      }
                      onDrop={(event: React.DragEvent<HTMLInputElement>) => {
                        onImageDrop(
                          event,
                          1,
                          messages.name.productFavicon,
                          messages.name.productFavicon_metadata
                        )
                        // field.onChange(event.dataTransfer.files[0])
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        onImageUpload(
                          event,
                          1,
                          messages.name.productFavicon,
                          messages.name.productFavicon_metadata
                        )
                        // field.onChange(event)
                      }}
                      error={Boolean(errors?.introduction?.icon_url?.message)}
                    />
                  )}
                />
                {errors?.introduction?.icon_url?.message?.length > 0 &&
                errors?.introduction?.icon_url?.type === 'checkFileType'
                  ? (
                  <Typography
                    mt={{ md: 1, xs: 2 }}
                    theme={theme}
                    variant="caption"
                    component="small"
                    display={'block'}
                    className={`${
                      errors?.introduction?.icon_url?.type === 'checkFileType'
                        ? 'text-error'
                        : ''
                    }`}
                  >
                    {errors?.introduction?.icon_url?.message}
                  </Typography>
                    )
                  : null}
              </>
            ) : (
              <FilePreview
                files={[
                  (productFaviconFileName ?? '').length > 0
                    ? productFaviconFileMetadata
                    : uploadFileMetaDataInitialValue
                ]}
                preview={'imageIcon'}
                viewIcon
                onRemoveFile={() => {
                  onFileRemove(
                    messages.name.productFavicon,
                    messages.name.productFavicon_metadata
                  )
                }
                }
                onClickIcon={() =>
                  openInNextWindow(
                    getValues(messages.name.productFavicon as any)
                  )
                }
                theme={theme}
              />
            )}
          </FileDragDropWrap>
        </Grid>
      </Grid>
    </ContentAreaWrap>
  )
}
