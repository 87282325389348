import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '../../../Layout/components'
import {} from './DashboardLayoutStyle'
import {
  DashboardPageHeaderComponent,
  DashboardTabsComponent
} from '@modules/Dashboard/components'
import { Box } from '@ntpkunity/controls'
import { DashboardTabName } from '@shared/constants/enums'

export const DashboardLayoutPage = (props) => {
  const theme = useTheme()
  const currentDate = new Date()
  const thirtyDaysAgo = new Date(currentDate)
  thirtyDaysAgo.setDate(currentDate.getDate() - 29)
  const [dateRange, setDateRange] = useState([thirtyDaysAgo, currentDate])
  const [startDate, endDate] = dateRange
  const [defaultTabIndex] = useState(DashboardTabName.Business_KPIs_Dashboard)
  const [activeTab, setActiveTab] = useState(defaultTabIndex)

  return (
    <>
      <Box theme={theme} pb={3}>
        <DashboardPageHeaderComponent
          activeTab={activeTab}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
        />
        <DashboardTabsComponent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>
    </>
  )
}
