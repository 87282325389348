import React, { type FC, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import ArrowUp from '../../../../shared/assets/images/ic-arrow-up-green.svg'
import ArrowDown from '../../../../shared/assets/images/ic-arrow-down-red.svg'
import {} from './TotalSubscribersStyle'
import { CounterWidget } from '@shared/components/CounterWidget/counterWidget'
import { useGetTotalSubscribers } from './TotalSubscribersService'
import { type ITotalNoOfSubscribers } from '@shared/typings'
import { TrendArrow, TrendColor } from '@shared/constants'
import { convertToDecimal } from '@shared/helper'

interface TotalSubscribersComponentProps {
  startDate: Date
  endDate: Date
}

export const TotalSubscribersComponent: FC<TotalSubscribersComponentProps> = ({
  startDate,
  endDate
}) => {
  const { data } = useGetTotalSubscribers(startDate, endDate)
  const theme = useTheme()
  const [totalSubscribers, settotalSubscribers] = useState<
  ITotalNoOfSubscribers | undefined
  >()

  useEffect(() => {
    if ((data ?? []).length > 0) {
      settotalSubscribers(data[0])
    }
  }, [data])
  return (
    <CounterWidget
      theme={theme}
      title={'Total No. of Subscribers'}
      counterText={totalSubscribers?.current_subscribers || 0}
      rankImage={true}
      {...(totalSubscribers?.current_subscribers > 0
        ? { rankImageUp: true }
        : { rankImageDown: true })}
      rankText={
        totalSubscribers
          ? `${convertToDecimal(totalSubscribers?.trend_percentage ?? 0)}%`
          : ''
      }
      rankTextSuccess={true}
    />
  )
}

TotalSubscribersComponent.propTypes = {}

TotalSubscribersComponent.defaultProps = {}
