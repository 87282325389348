import React from 'react'
import { Typography } from '@ntpkunity/controls'
import { type IListWidgetProps } from './graphWidget.types'
import { WidgetCardWrap } from './graphWidgetStyle'

export const GraphWidget: React.FC<IListWidgetProps> = ({
  widgetTitle,
  children,
  theme
}) => {
  return (
    <WidgetCardWrap theme={theme} className="widget-card-wrap">
      {widgetTitle && (
        <Typography
          theme={theme}
          variant="h3"
          component="h3"
          className="widget-title fw-sbold"
          mb={3}
        >
          {widgetTitle}
        </Typography>
      )}
      {children}
    </WidgetCardWrap>
  )
}
