import { FaqsFields } from '@modules/ProductConfigurations/ProductConfigurationsModule'
import { useTheme } from '@mui/material'
import { Grid, Input, Textarea, Typography } from '@ntpkunity/controls'
import { type IFormData } from '@shared/typings'
import { type FC } from 'react'
import { Controller, useFormContext, type UseFormReturn } from 'react-hook-form'

interface IFaqProps {
  index: number
}

const messages = {
  label: {
    question: 'Question',
    answer: 'Answer'
  },
  placeholder: {
    question: 'Sample text...',
    answer: 'Sample text...'
  }
}

export const Faq: FC<IFaqProps> = ({ index }) => {
  const theme = useTheme()
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext()

  const maxCharLimit = 300

  return (
    <Grid theme={theme} container item spacing={3}>
      <Grid theme={theme} item xs={12}>
        <Controller
          name={`${FaqsFields.FAQS_KEY}.${index}.${FaqsFields.QUESTION}`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              theme={theme}
              fullWidth={true}
              type={'text'}
              label={messages.label.question}
              placeholder={messages.placeholder.question}
              error={errors?.faqs?.[index]?.question?.message}
              id={''}
              onChange={(e) => {
                field.onChange(e.slice(0, maxCharLimit))
              }}
            />
          )}
        />
        <Typography
          mt={{ md: 1, xs: 2 }}
          theme={theme}
          variant="caption"
          component="small"
          display={'block'}
          className={`${
            errors?.faqs?.[index]?.question?.type === 'maxLength'
              ? 'text-error'
              : ''
          }`}
        >
          {`${
            (
              getValues(
                `${FaqsFields.FAQS_KEY}.${index}.${FaqsFields.QUESTION}`
              ) ?? ''
            ).length
          }/${maxCharLimit} characters`}
        </Typography>
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name={`${FaqsFields.FAQS_KEY}.${index}.${FaqsFields.ANSWER}`}
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              theme={theme}
              rows={3}
              fullWidth={true}
              type={'text'}
              label={messages.label.answer}
              placeholder={messages.placeholder.answer}
              error={errors?.faqs?.[index]?.answer?.message}
              id={''}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
