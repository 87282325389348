import React, { type FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Box, Button, Container, Typography } from '@ntpkunity/controls'
import PlayIcon from '@shared/assets/images/ic-play.svg'
import { HeroBanner } from './IntroductionSectionStyle'
import { type IIntroduction } from '@shared/typings'
import { useModal } from 'react-modal-hook'
import { ProductDemoVideoComponent } from '@modules/ProductDemoVideo/productDemoVideo'

interface IIProductDetail {
  introduction: IIntroduction
}
export const IntroductionSectionComponent: FC<IIProductDetail> = ({
  introduction
}) => {
  const theme = useTheme()
  const [showDemoVideo, hideDemoVideo] = useModal(
    () => (
      <ProductDemoVideoComponent
        hide={hideDemoVideo}
        videoLink={introduction?.demo_video_url ?? ''}
      />
    ),
    [introduction]
  )
  return (
    <Box theme={theme} className="template-section-wrap">
      <Container theme={theme} maxWidth="lg" className="custom-container">
        <HeroBanner theme={theme} className="hero-banner">
          <Box theme={theme} className="hero-content">
            <Typography
              className="prd-title"
              theme={theme}
              variant="h2"
              component="h2"
              mb={{ xs: 2, md: 3 }}
            >
              {introduction?.name}
            </Typography>
            <Typography
              theme={theme}
              variant="h3"
              component="h3"
              mb={{ xs: 2, md: 3 }}
              className="text-h3"
            >
              {introduction?.products_tag_line}
            </Typography>
            <Typography
              className="text-muted text-body2"
              theme={theme}
              variant="body1"
              component="p"
              display={'block'}
              mb={{ xs: 2, md: 3 }}
            >
              {introduction?.description}
            </Typography>
            <Button
              theme={theme}
              defaultBtn
              text={'Watch Product Demo'}
              startIcon={<img src={PlayIcon} alt="Watch Product Demo" />}
              onClick={() => {
                Boolean(introduction?.demo_video_url) && showDemoVideo()
              }}
            />
          </Box>
          <Box theme={theme} className="hero-img">
            {introduction?.image_url && (
              <img src={introduction.image_url} alt="" />
            )}
          </Box>
        </HeroBanner>
      </Container>
    </Box>
  )
}

IntroductionSectionComponent.propTypes = {}

IntroductionSectionComponent.defaultProps = {}
