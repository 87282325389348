import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const DataTableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.data-table-wrap': {
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.link': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '.main-table-wrap': {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius
    },
    '.u-table-wrap': {
      '.u-table-container': {
        '&::-webkit-scrollbar-track': {
          marginTop: 89
        }
      },
      '.has-sorting': {
        td: {
          position: 'relative',
          paddingRight: '38px !important',
          overflow: 'hidden !important',
          '.sort-action': {
            position: 'absolute',
            top: 12,
            right: 12,
            transition: 'all 0.3s ease-in-out 0s',
            opacity: 0,
            '.btn': {
              '&.with-icon': {
                '&.icon-only': {
                  padding: 0
                }
              },
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }
          },
          '&:hover': {
            '.sort-action': {
              opacity: 1
            }
          }
        }
      },
      '.u-table': {
        '.img-cell': {
          '.prd-img': {
            maxHeight: 32
          }
        },
        '.toggle-btn': {
          marginRight: 0,
          transition: 'all 0.3s ease-in-out 0s',
          '&.ch-right': {
            transform: 'rotate(-180deg)'
          }
        },
        '.text-overflow': {
          whiteSpace: 'nowrap',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '.email-cell': {
          minWidth: 344,
          maxWidth: 344,
          width: '344',
          '.text-overflow': {
            width: 'auto',
            maxWidth: '270px'
          },
          '.icon-element': {
            marginRight: 8,
            display: 'block',
            width: 24,
            height: 24
          }
        },
        '.width-auto': {
          maxWidth: 'initial'
        }
      },
      '.u-table-head': {
        td: {
          '&.drag-icon-cell': {
            padding: '4px 0px !important',
            width: 48,
            minWidth: 48,
            maxWidth: '100%'
          }
        },
        '.filters-row': {
          td: {
            '&.has-date-range': {
              '.date-range': {
                '.rmdp-container': {
                  input: {
                    height: 36,
                    padding: '0px 32px 0px 12px',
                    borderRadius: 4,
                    fontSize: theme.typography.body2.fontSize
                  }
                },
                '.calendar-icon': {
                  right: 7,
                  top: 7
                }
              }
            }
          }
        }
      },
      '.u-table-body': {
        tr: {
          td: {
            '&.drag-icon-cell': {
              padding: '4px 0px !important',
              width: 48,
              minWidth: 48,
              maxWidth: '100%',
              '.btn': {
                '&.with-icon': {
                  '&.icon-only': {
                    padding: 0,
                    margin: 'auto'
                  }
                }
              }
            },
            '&.product-name': {
              '.styled-tooltip': {
                div: {
                  maxWidth: 200,
                  minWidth: 200,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }
              }
            },
            '.styled-tooltip': {
              '.MuiTooltip-popper[data-popper-placement*="bottom-start"]': {
                '.MuiTooltip-arrow': {
                  left: '8px !important',
                  transform: 'none !important',
                  top: 1
                }
              }
            }
          }
        }
      }
    },
    '.MuiTablePagination-root': {
      marginTop: '-4px',
      borderRight: '1px solid',
      borderBottom: '1px solid',
      borderLeft: '1px solid',
      paddingTop: '4px',
      borderColor: theme.palette.grey[100],
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily
    },
    '.MuiTablePagination-selectIcon': {
      top: 'calc(35% - 0.5em)',
      left: 24
    },
    '.MuiTablePagination-select:focus': {
      backgroundColor: 'transparent'
    },
    '.scroll-hide': {
      backgroundColor: theme.palette.common.white,
      borderBottom: '1px solid' + theme.palette.divider,
      borderTop: '1px solid' + theme.palette.divider,
      borderRight: '1px solid' + theme.palette.divider,
      position: 'absolute',
      width: 11,
      height: 90,
      right: 0,
      top: 0,
      display: 'block',
      borderRadius: '0 8px 0 0',
      '&.spr-border': {
        '&::after': {
          display: 'inline-block',
          content: "''",
          width: 11,
          borderStyle: 'solid',
          borderWidth: '0 0 1px 0',
          borderColor: theme.palette.divider,
          position: 'absolute',
          top: '50%',
          marginTop: 0
        }
      }
    },
    '.status-indicator': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.palette.primary.main,
      marginRight: 8,
      '&.ind-warning': {
        backgroundColor: '#F3B140'
      },
      '&.ind-danger': {
        backgroundColor: theme.palette.error.main
      },
      '&.ind-primary': {
        backgroundColor: theme.palette.primary.main
      }
    }
  }
}))
