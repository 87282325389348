import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const AuthFooter = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.auth-footer': {
    color: theme.palette.grey[600],
    a: {
      color: theme.palette.grey[600]
    }
  }
}))
