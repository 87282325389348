import React, { FC, Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ChevronDown from 'shared/assets/images/chevron-down'
import { TableCell, TablePagination, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Icon,
  Input,
} from '@ntpkunity/controls'
import { TableWrap } from './SubscriberUsageTableStyle'
import { ITrackUserFilter } from '@shared/typings'
import { useGetSignupUsers } from './SubscriberUsageService'
import {
  SortingOrder,
  dateFormat,
  formatDate,
  PaidState,
  SortingColumn,
} from '@shared/constants'
import { formatedDate, isDateValid } from '@shared/helper/methods'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import { useDebouncedState } from '@shared/helper/hooks'
import DateRange from '@shared/components/DateRange'
import { DateObject } from 'react-multi-date-picker'
import { AccordionRow } from './AccordionRow'
import { TableSkeleton } from '@shared/components/TableSkeleton/TableSkeleton'
interface IDebouncedState {
  company: string
  product: string
  allowedTransactions: string
  transactionsPerformed: string
  totalUsers: string
  country: string
  paymentState: string
  onboardingDate: DateObject | null
  subscriptionPeriod: DateObject[] | null[]
}

const initialState: IDebouncedState = {
  company: '',
  product: '',
  allowedTransactions: '',
  transactionsPerformed: '',
  totalUsers: '',
  country: '',
  paymentState: '',
  onboardingDate: null,
  subscriptionPeriod: [null, null],
}

export const SubscriberUsageTableComponent = () => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  )
  const { data: userTracking, isLoading: userTrackingLoading } =
    useGetSignupUsers(columnFilters)

  const trackuserfilter: ITrackUserFilter = userTracking

  const [filteredData, setFilteredData] = useState<ITrackUserFilter>()
  const [order, setOrder] = useState(SortingOrder.ASC)
  const [sortingColumn, setSortingColumn] = useState<string | null>(null)

  const [debouncedState, setState, state] = useDebouncedState(initialState)
  const {
    company,
    product,
    transactionsPerformed,
    allowedTransactions,
    totalUsers,
    country,
    paymentState,
    onboardingDate,
    subscriptionPeriod,
  } = state

  useEffect(() => setFilteredData(userTracking), [userTracking])
  useEffect(() => handleFilter(), [debouncedState])
  useEffect(
    () => setColumnFilters(setQueryString(pageNumber, pageSize)),
    [order, sortingColumn]
  )

  const sorting = (sortingdata): void => {
    let sortedData = [...filteredData.result]
    setSortingColumn(sortingdata)

    const sortString = (
      a: string | null,
      b: string | null,
      order: SortingOrder
    ): number => {
      if (a === null && b === null) return 0
      if (a === null) return order === SortingOrder.ASC ? -1 : 1
      if (b === null) return order === SortingOrder.ASC ? 1 : -1
      return (order === SortingOrder.ASC ? 1 : -1) * a.localeCompare(b)
    }

    const sortNumber = (
      a: number | null,
      b: number | null,
      order: SortingOrder
    ): number => {
      if (a === null && b === null) return 0
      if (a === null) return order === SortingOrder.ASC ? -1 : 1
      if (b === null) return order === SortingOrder.ASC ? 1 : -1
      return (order === SortingOrder.ASC ? 1 : -1) * (a - b)
    }

    const sortBoolean = (
      a: boolean | null,
      b: boolean | null,
      order: SortingOrder
    ): number => {
      if (a === null && b === null) return 0
      if (a === null) return order === SortingOrder.ASC ? -1 : 1
      if (b === null) return order === SortingOrder.ASC ? 1 : -1
      return (order === SortingOrder.ASC ? 1 : -1) * Number(a) - Number(b)
    }

    switch (sortingdata) {
      case SortingColumn.PRODUCT_NAME:
        sortedData = sortedData.sort((a, b) =>
          sortString(
            a?.subscribed_products_data
              ?.map((product) => product.product_name)
              ?.join(',') || null,
            b?.subscribed_products_data
              ?.map((product) => product.product_name)
              ?.join(',') || null,
            order
          )
        )
        break
      case SortingColumn.PAYMENT_STATE:
        sortedData = sortedData.sort((a, b) =>
          sortBoolean(
            b?.subscribed_products_data?.some(
              (product) => product.payment_state === PaidState.PAID
            ) ?? null,
            a?.subscribed_products_data?.some(
              (product) => product.payment_state === PaidState.PAID
            ) ?? null,
            order
          )
        )
        break
      case SortingColumn.SUBSCRIPTION_PERIOD:
        sortedData = sortedData.sort((a, b) =>
          sortString(
            `${
              a?.subscribed_products_data?.[0]?.subscription_start_date ?? ''
            } - ${a?.subscribed_products_data?.[0]?.subscription_end_date}` ||
              null,
            `${
              b?.subscribed_products_data?.[0]?.subscription_start_date ?? ''
            } - ${b?.subscribed_products_data?.[0]?.subscription_end_date}` ||
              null,
            order
          )
        )
        break
      case SortingColumn.TRANSACTION_PERFORMED:
      case SortingColumn.ALLOWED_TRANSACTIONS:
        sortedData = sortedData.sort((a, b) =>
          sortNumber(
            a?.subscribed_products_data?.reduce(
              (total, product) => total + product[sortingdata],
              0
            ) || null,
            b?.subscribed_products_data?.reduce(
              (total, product) => total + product[sortingdata],
              0
            ) || null,
            order
          )
        )
        break
      default:
        sortedData = sortedData.sort((a, b) => {
          if (typeof a[sortingdata] === 'number') {
            return sortNumber(
              a[sortingdata] || null,
              b[sortingdata] || null,
              order
            )
          } else if (typeof a[sortingdata] === 'boolean') {
            return sortBoolean(
              a[sortingdata] || null,
              b[sortingdata] || null,
              order
            )
          }
          return sortString(
            a[sortingdata] || null,
            b[sortingdata] || null,
            order
          )
        })
        break
    }

    setFilteredData((prevState) => ({
      ...prevState,
      result: sortedData,
    }))

    setOrder(order === SortingOrder.ASC ? SortingOrder.DSC : SortingOrder.ASC)
  }

  const setQueryString = (pgNo, pgSize) => {
    const queryParams = []

    const addQueryParam = (paramName, paramValue) => {
      if ((paramValue ?? '').length > 0 && paramValue.trimStart() !== '') {
        queryParams.push(`${paramName}=${paramValue}`)
      }
    }

    queryParams.push(`page_number=${pgNo}`)
    queryParams.push(`page_size=${pgSize}`)

    addQueryParam('company_name', company)
    addQueryParam('product_name', product)
    addQueryParam('performed_transactions', transactionsPerformed)
    addQueryParam('allowed_transactions', allowedTransactions)
    addQueryParam('total_sub_users', totalUsers)
    addQueryParam('country', country)
    addQueryParam('payment_state', paymentState)

    if (isDateValid(onboardingDate?.toDate())) {
      addQueryParam(
        'onboarding_date',
        `${formatedDate(onboardingDate?.toDate(), formatDate)}`
      )
    }

    if (subscriptionPeriod?.[0]?.isValid && subscriptionPeriod?.[1]?.isValid) {
      document.dispatchEvent(new Event('scroll'))
      addQueryParam(
        'subscription_start_date',
        `${subscriptionPeriod[0]?.format(formatDate)}`
      )
      addQueryParam(
        'subscription_end_date',
        `${subscriptionPeriod[1]?.format(formatDate)}`
      )
    }

    addQueryParam('order', order)
    addQueryParam('sorting_colunm', sortingColumn)

    const query_string = queryParams.join('&')
    return query_string
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }
  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
    setFilteredData(userTracking)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }

  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <TableWrap theme={theme} className="main-table-wrap">
        <span className="scroll-hide spr-border"></span>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell className="checkbox-cell">
                  <Checkbox theme={theme} label=""></Checkbox>
                </TableCell>
                <TableCell className="toggle-cell"></TableCell>
                <TableCell>
                  Company Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        setSortingColumn(SortingColumn.COMPANY_NAME)
                        setOrder(
                          order === SortingOrder.ASC
                            ? SortingOrder.DSC
                            : SortingOrder.ASC
                        )
                      }}
                      iconText={
                        sortingColumn === SortingColumn.COMPANY_NAME ? (
                          order === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="ArrowUpDown" />
                        )
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Onboarding Date
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        setSortingColumn(SortingColumn.ONBOARDING_DATE)
                        setOrder(
                          order === SortingOrder.ASC
                            ? SortingOrder.DSC
                            : SortingOrder.ASC
                        )
                      }}
                      iconText={
                        sortingColumn === SortingColumn.ONBOARDING_DATE ? (
                          order === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="ArrowUpDown" />
                        )
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Product Name
                </TableCell>
                <TableCell>
                  Performed Transactions
                </TableCell>
                <TableCell>
                  Allowed Transactions
                </TableCell>
                <TableCell>
                  Total Users
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        setSortingColumn(SortingColumn.TOTAL_SUB_USERS)
                        setOrder(
                          order === SortingOrder.ASC
                            ? SortingOrder.DSC
                            : SortingOrder.ASC
                        )
                      }}
                      iconText={
                        sortingColumn === SortingColumn.TOTAL_SUB_USERS ? (
                          order === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="ArrowUpDown" />
                        )
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Country
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        setSortingColumn(SortingColumn.COUNTRY)
                        setOrder(
                          order === SortingOrder.ASC
                            ? SortingOrder.DSC
                            : SortingOrder.ASC
                        )
                      }}
                      iconText={
                        sortingColumn === SortingColumn.COUNTRY ? (
                          order === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="ArrowUpDown" />
                        )
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Payment State
                </TableCell>
                <TableCell>
                  Subscription Period
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        setSortingColumn(SortingColumn.SUBSCRIPTION_PERIOD)
                        setOrder(
                          order === SortingOrder.ASC
                            ? SortingOrder.DSC
                            : SortingOrder.ASC
                        )
                      }}
                      iconText={
                        sortingColumn === SortingColumn.SUBSCRIPTION_PERIOD ? (
                          order === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="ArrowUpDown" />
                        )
                      }
                    />
                  </span>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell"></TableCell>
                <TableCell className="toggle-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={company?.trimStart()}
                      onChange={(e) => {
                        setState({
                          ...state,
                          company: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="has-date-range">
                  <Box theme={theme} className="table-filter">
                    <DateRange
                      id="onboarding-date-range"
                      theme={theme}
                      value={onboardingDate}
                      placeholder={dateFormat}
                      onChange={(value) => {
                        setState({
                          ...state,
                          onboardingDate: value,
                        })
                      }}
                      format={dateFormat}
                      maxDate={new Date()}
                      calendarPosition="right-end"
                      endAdornment={
                        <Icon name="CalendarIcon" className="calendar-icon" />
                      }
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={product?.trimStart()}
                      onChange={(e) => {
                        setState({
                          ...state,
                          product: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={transactionsPerformed}
                      onChange={(e) => {
                        setState({
                          ...state,
                          transactionsPerformed: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={allowedTransactions}
                      onChange={(e) => {
                        setState({
                          ...state,
                          allowedTransactions: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={totalUsers}
                      onChange={(e) => {
                        setState({
                          ...state,
                          totalUsers: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={country?.trimStart()}
                      onChange={(e) => {
                        setState({
                          ...state,
                          country: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={paymentState}
                      onChange={(e) => {
                        setState({
                          ...state,
                          paymentState: e,
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="has-date-range">
                  <Box theme={theme} className="table-filter">
                    <DateRange
                      id="subscription-period-date-range"
                      theme={theme}
                      value={subscriptionPeriod}
                      placeholder={`${dateFormat} - ${dateFormat}`}
                      onChange={(value) => {
                        setState({
                          ...state,
                          subscriptionPeriod: value,
                        })
                      }}
                      format={dateFormat}
                      range
                      rangeHover
                      calendarPosition="right-end"
                      endAdornment={
                        <Icon name="CalendarIcon" className="calendar-icon" />
                      }
                      dateSeparator={' - '}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {userTrackingLoading ? (
                <TableSkeleton theme={theme} columns={12} rows={6} />
              ) : (
                filteredData?.result?.map((item, index) => (
                  <AccordionRow key={index} item={item} />
                ))
              )}
            </>
          }
        />
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={trackuserfilter ? trackuserfilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
          }}
        />
      </TableWrap>
    </DataTableWrap>
  )
}

SubscriberUsageTableComponent.propTypes = {}

SubscriberUsageTableComponent.defaultProps = {}
