import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const NoDataWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.no-data-wrap': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
