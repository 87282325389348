import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const PageHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-header': {
    padding: '24px 0',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    zIndex: 1201,
    '&.border-bottom': {
      borderBottom: '1px solid' + theme.palette.grey[100]
    },
    '.title-wrap': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 'calc(100% - 270px)',
      width: '100%',
      marginRight: 40,
      '.MuiTypography-h2': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    },
    '.back-btn': {
      padding: 0,
      flexShrink: 0,
      marginRight: 24,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    },
    '.title': {
      float: 'left'
    },
    '.counter': {
      minWidth: 30,
      height: 40,
      borderRadius: 40,
      lineHeight: '40px',
      padding: '0 5px',
      display: 'inline-block',
      textAlign: 'center',
      color: 'white',
      fontWeight: theme.typography.subtitle1.fontWeight,
      fontSize: theme.typography.subtitle1.fontSize,
      backgroundColor: theme.palette.primary.main,
      position: 'relative',
      marginLeft: 4,
      float: 'left'
    },
    '.caption': {
      width: '100%',
      display: 'block',
      clear: 'both',
      color: theme.palette.grey[600]
    },
    '.action-area': {
      width: '35%',
      justifyContent: 'flex-end',
      '.btn': {
        '&:not(:first-of-type)': {
          marginLeft: 16
        }
      }
    }
  }
}))
