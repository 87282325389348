import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {} from './LeadingIndicatorStyle'
import { GraphWidget } from '@shared/components/GraphWidget/graphWidget'
import { Box } from '@ntpkunity/controls'

export const LeadingIndicatorComponent = ({}) => {
  const theme = useTheme()
  return (
    <GraphWidget theme={theme} widgetTitle={'Leading Indicators'}>
      <Box theme={theme} className="graph-widget-container"></Box>
    </GraphWidget>
  )
}

LeadingIndicatorComponent.propTypes = {}

LeadingIndicatorComponent.defaultProps = {}
