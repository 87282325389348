import { checkWordCount, isEmpty, isValidUrl, pick } from '@shared/helper'
import * as yup from 'yup'
import { ProductState, layoutOptionsValue, pricingModelOptions } from './enums'

const uploadFileMetaDataSchema = {
  name: yup.string().nullable(),
  size: yup.number().nullable(),
  type: yup.string().nullable()
}

const featureSchema = {
  id: yup.number().nullable(),
  product_id: yup.number().nullable(),
  name: yup
    .string()
    .required('Feature headline is required')
    .max(300, 'Feature headline character limit is 300'),
  description: yup.string().required('Feature description is required'),
  image_url: yup.string().required(),
  feature_variant: yup.string().required(),
  meta_data: yup.object().shape({
    image_url: yup.object().shape(uploadFileMetaDataSchema)
  })
}

const logoSchema = {
  id: yup.number().nullable(),
  customer_name: yup.string().required('Customer name is required'),
  customer_logo_url: yup.string().required(),
  meta_data: yup.object().shape({
    image_url: yup.object().shape(uploadFileMetaDataSchema)
  })
}

export const schema = yup.object().shape({
  introduction: yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().required('Product name is required'),
    products_tag_line: yup.string().required('Product headline is required'),
    demo_video_url: yup
      .string()
      .nullable()
      .test({
        name: 'valid url',
        params: {},
        message: 'Valid URL is required',
        test: (value) => ((value ?? '').length > 0 ? isValidUrl(value) : true)
      }),
    state: yup.string().required('State is required'),
    product_path: yup.string().nullable(),
    description: yup.string().required('Product description is required'),
    product_thumbnail: yup.string().required('Product Thumbnail is required'),
    image_url: yup.string().required(),
    icon_url: yup.string().required(),
    meta_data: yup.object().shape({
      icon_url: yup.object().shape(uploadFileMetaDataSchema),
      image_url: yup.object().shape(uploadFileMetaDataSchema)
    })
  }),
  prices: yup.array().of(
    yup.lazy((item) =>
      yup.object().shape({
        id: yup.number().nullable(),
        product_id: yup.number().nullable(),
        stripe_price_id: yup.string().nullable(),
        name: yup.string().required('Package name is required'),
        pricing_model: yup.string().required('Pricing model is required'),
        amount:
          item?.pricing_model === pricingModelOptions.FREE_TRIAL
            ? yup.number().nullable()
            : yup
              .number()
              .nullable()
              .typeError('Base price is required')
              .required('Base price is required')
              .min(1, 'Base price must be greater than 1$'),
        allowed_transactions: yup
          .number()
          .nullable()
          .typeError('Maximum allowed usage is required')
          .required('Maximum allowed usage is required'),
        additional_price_per_trans:
          item?.pricing_model === 'Free Trial'
            ? yup.number().nullable()
            : yup
              .number()
              .nullable()
              .typeError('Excess usage price is required')
              .required('Excess usage price is required'),
        description: yup.string().required('Package description is required'),
        stripe_nick_name: yup.string().required('Package nickname is required'),
        icon_url: yup.string().required('* Select package thumbnail'),
        price_details: yup.string().required('Package features are required'),
        validity_in_days:
          item?.pricing_model !== pricingModelOptions.FREE_TRIAL
            ? yup.number().nullable()
            : yup
              .number()
              .nullable()
              .typeError('Duration is Required')
              .required('Duration is Required'),
        is_taxable:
          item?.pricing_model === 'Free Trial'
            ? yup.boolean().nullable()
            : yup.boolean().nullable().required('Taxable is Required'),
        is_subscribed: yup.boolean()
      })
    )
  ),
  features: yup.array().of(yup.object().shape(featureSchema)),
  how_to_connect: yup.lazy((value) => {
    const _value =
      value !== null
        ? pick(value, [
          'main_text',
          'video_url',
          'sub_text',
          'product_usage_steps'
        ])
        : value

    return isEmpty(_value)
      ? yup
        .object()
        .nullable()
        .shape({
          id: yup.number().nullable(),
          product_id: yup.number().nullable(),
          main_text: yup.string().nullable(),
          video_url: yup.string().nullable(),
          sub_text: yup.string().nullable(),
          product_usage_steps: yup.array().of(
            yup.object().shape({
              step_headline: yup.string().nullable(),
              step_description: yup.string().nullable()
            })
          )
        })
      : yup.object().shape({
        id: yup.number().nullable(),
        product_id: yup.number().nullable(),
        main_text: yup.string().required('Headline is required'),
        video_url: yup
          .string()
          .required('Video URL is required')
          .test({
            name: 'valid url',
            params: {},
            message: 'Valid URL is required',
            test: (value) =>
              (value ?? '').length > 0 ? isValidUrl(value) : true
          }),
        sub_text: yup.string().required('Sub headline is required'),
        product_usage_steps: yup.array().of(
          yup.object().shape({
            step_headline: yup.string().required('Step headline is required'),
            step_description: yup
              .string()
              .required('Step description is required')
          })
        )
      })
  }),
  support: yup.lazy((value) => {
    const _value =
      value !== null
        ? pick(value, ['documentation_url', 'pr_pack_url'])
        : value

    return isEmpty(_value)
      ? yup
        .object()
        .nullable()
        .shape({
          documentation_url: yup.string().nullable(),
          pr_pack_url: yup.string().nullable(),
          meta_data: yup.object().shape({
            pr_pack_url: yup.object().shape(uploadFileMetaDataSchema)
          })
        })
      : yup.object().shape({
        documentation_url: yup
          .string()
          .required('Api Documentation Link is required'),
        pr_pack_url: yup
          .string()
          .required('PR Pack Link is required')
          .test({
            name: 'valid url',
            params: {},
            message: 'Valid URL is required',
            test: (value) =>
              (value ?? '').length > 0 ? isValidUrl(value) : true
          }),
        meta_data: yup.object().shape({
          pr_pack_url: yup.object().shape(uploadFileMetaDataSchema)
        })
      })
  }),
  faqs: yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(),
      product_id: yup.number().nullable(),
      question: yup
        .string()
        .required('Question is required')
        .max(300, 'Question character limit is 300'),
      answer: yup.string().required('Answer is required')
    })
  ),
  testimonials: yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(),
      product_id: yup.number().nullable(),
      description: yup
        .string()
        .required('Testimonial is required')
        .test({
          name: 'maxLength',
          params: {},
          message: 'Testimonial word limit is 300',
          test: (value) => checkWordCount(value, 300)
        }),
      person_name: yup.string().required('Person name is required'),
      person_designation: yup
        .string()
        .required('Person designation is required'),
      profile_picture: yup.string().required('Profile picture is required'),
      company_logo: yup.string().required('Company logo is required'),
      meta_data: yup.object().shape({
        profile_picture: yup.object().shape(uploadFileMetaDataSchema),
        company_logo: yup.object().shape(uploadFileMetaDataSchema)
      })
    })
  ),
  makeFormDirty: yup.boolean().nullable(),
  customers_logos: yup.array().of(yup.object().shape(logoSchema))
})

export const uploadFileMetaDataInitialValue = { name: '', size: 0, type: '' }
export const productUsageStepInitialValue = {
  step_headline: '',
  step_description: ''
}
export const testimonialsInitialValue = {
  id: null,
  product_id: null,
  description: '',
  person_name: '',
  person_designation: '',
  profile_picture: '',
  company_logo: '',
  meta_data: {
    profile_picture: uploadFileMetaDataInitialValue,
    company_logo: uploadFileMetaDataInitialValue
  }
}

export const introductionDefaultValues = {
  id: null,
  name: '',
  products_tag_line: '',
  demo_video_url: '',
  state: ProductState.DRAFT,
  product_path: '',
  description: '',
  product_thumbnail: '',
  image_url: '',
  icon_url: '',
  meta_data: {
    image_url: uploadFileMetaDataInitialValue,
    icon_url: uploadFileMetaDataInitialValue
  }
}

export const pricesDefaultValues = {
  id: null,
  product_id: null,
  stripe_price_id: null,
  name: '',
  amount: null,
  allowed_transactions: null,
  additional_price_per_trans: null,
  description: '',
  stripe_nick_name: '',
  icon_url: '',
  price_details: '',
  validity_in_days: null,
  is_taxable: null,
  is_subscribed: false,
  pricing_model: ''
}

export const featureDefaultValues = {
  id: null,
  product_id: null,
  name: '',
  description: '',
  image_url: '',
  feature_variant: layoutOptionsValue.COL_6_TEXT_LAST,
  meta_data: {
    image_url: uploadFileMetaDataInitialValue
  }
}

export const howToConnectDefaultValues = {
  id: null,
  product_id: null,
  main_text: '',
  video_url: '',
  sub_text: '',
  product_usage_steps: [{ ...productUsageStepInitialValue }]
}

export const supportDefaultValues = {
  documentation_url: '',
  pr_pack_url: '',
  meta_data: {
    pr_pack_url: uploadFileMetaDataInitialValue
  }
}

export const faqsDefaultValues = {
  id: null,
  product_id: null,
  question: '',
  answer: ''
}

export const logosDefaultValue = {
  id: null,
  customer_logo_url: '',
  customer_name: '',
  meta_data: {
    image_url: uploadFileMetaDataInitialValue
  }
}

export const defaultValues = {
  introduction: introductionDefaultValues,
  prices: [],
  features: [],
  how_to_connect: howToConnectDefaultValues,
  support: supportDefaultValues,
  faqs: [],
  testimonials: [],
  makeFormDirty: false,
  customers_logos: []
}
