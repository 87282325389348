import { useTheme } from '@mui/material'
import {
  ForgotPasswordComponent,
  type IResponse
} from '@ntpkunity/controls-ums'
import { APP_ROUTES, getItem } from '@shared/configuration'
import {
  EMAIL,
  forgotPasswordErrorMessage,
  HttpStatus
} from '@shared/constants'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { AuthLayoutComponent } from '../../components'

export const ForgotPasswordPage = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [response, setResponse] = useState<IResponse>()

  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      navigate(APP_ROUTES.AUTHENTICATION.RESEND_PASSWORD_LINK, {
        replace: true,
        state: { email: response?.data?.email }
      })
    } else if (response?.status === HttpStatus.Bad_Request) {
      setToaster({
        isDisplay: true,
        message: forgotPasswordErrorMessage,
        type: 'error'
      })
    }
  }, [response])

  return (
    <AuthLayoutComponent>
      <ForgotPasswordComponent
        email={getItem(EMAIL)}
        theme={theme}
        setResponse={setResponse}
        nextScreenUrl={`${APP_ROUTES.AUTHENTICATION.RESET_PASSWORD}`}
        isSuperAdmin={true}
      />
    </AuthLayoutComponent>
  )
}
