import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'
import { Button, Box, Typography, Input, Icon } from '@ntpkunity/controls'

export const FormForgotPasswordComponent = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <form>
      <Box theme={theme} mb={4}>
        <Input
          fullWidth
          label={'Enter Your Registered Email Address'}
          type={'text'}
          startAdornment={''}
          endAdornment={''}
          theme={theme}
          id={'txtEmail'}
          name={'txtEmail'}
          placeholder="sample@sample.com"
        />
      </Box>
      <Button
        type={'submit'}
        primary
        text={'Send Reset Link'}
        fullWidth
        theme={theme}
        onClick={() => {
          navigate(APP_ROUTES.AUTHENTICATION.LOGIN)
        }}
      ></Button>
      <Box theme={theme} mt={4}>
        <Typography
          className="text-muted"
          theme={theme}
          variant="caption"
          component="small"
        >
          Please enter your registered email address for security purposes. When
          you receive the reset password email, please follow the instructions.
        </Typography>
      </Box>
    </form>
  )
}

FormForgotPasswordComponent.propTypes = {}

FormForgotPasswordComponent.defaultProps = {}
