import React, { type FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Box,
  Grid,
  Typography,
  Accordion,
  Container
} from '@ntpkunity/controls'
import { FaqsWrap } from './FaqsSectionStyle'
import { type IAccordianProps, type IFaqs } from '@shared/typings'

interface IProductFaq {
  faqs: IFaqs[]
}
export const FaqsSectionComponent: FC<IProductFaq> = ({ faqs }) => {
  const theme = useTheme()

  const options = (): IAccordianProps[] => {
    let _options = []
    if (faqs?.length > 0) {
      _options = faqs.map((faq: IFaqs) => {
        return {
          content: (
            <>
              <Box theme={theme} className="content-area">
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  className="text-muted text-body2"
                >
                  {faq?.answer}
                </Typography>
              </Box>
            </>
          ),
          title: (
            <Typography
              theme={theme}
              variant="subtitle1"
              component={'p'}
              className="fw-sbold text-body1"
            >
              {faq?.question}
            </Typography>
          ),
          key: faq?.id,
          isExpanded: false
        }
      })
    }
    return _options
  }

  return (
    <Box theme={theme} className="template-section-wrap">
      <Container theme={theme} maxWidth="lg" className="custom-container">
        <FaqsWrap theme={theme} className="faqs-wrap">
          {faqs?.length > 0
            ? (
            <>
              <Typography
                mb={{ md: 5, xs: 3 }}
                theme={theme}
                variant="h2"
                component="h2"
                textAlign="center"
                className="text-h2"
              >
                Frequently asked questions
              </Typography>
              <Grid
                theme={theme}
                container
                item
                spacing={3}
                justifyContent={'center'}
              >
                <Grid theme={theme} item lg={10} md={12}>
                  <Box theme={theme} className="faqs-accordion">
                    <Accordion
                      theme={theme}
                      varient="grouped"
                      shouldCollapse={true}
                      items={options()}
                    />
                  </Box>
                  <Box theme={theme} mt={{ md: 5, xs: 3 }} textAlign="center">
                    <Typography
                      theme={theme}
                      component="span"
                      variant="body2"
                      display={'block'}
                      className="text-muted text-body2"
                    >
                      Didn’t find the answer you are looking for?{' '}
                      <a
                        href={`mailto:${process.env.SUPPORT_EMAIL}`}
                        className="link text-primary"
                      >
                        Contact our support
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
              )
            : null}
        </FaqsWrap>
      </Container>
    </Box>
  )
}

FaqsSectionComponent.propTypes = {}

FaqsSectionComponent.defaultProps = {}
