import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const RadioButtonsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.radio-btns': {
    '.custom-radio-wrap': {
      width: '100%',
      '.MuiFormGroup-row': {
        display: 'flex',
        justifyContent: 'space-between'
      }
    }
  }
}))
