import {
  TestimonialFields,
  useDeleteFile,
  useUploadFile
} from '@modules/ProductConfigurations/ProductConfigurationsModule'
import { useTheme } from '@mui/material'
import {
  FileDragDrop,
  FilePreview,
  Grid,
  Input,
  Textarea,
  Typography
} from '@ntpkunity/controls'
import { FileDragDropWrap } from '@shared/components/FileDragDropWrap/FileDragDropWrap'
import { uploadFileMetaDataInitialValue } from '@shared/constants/productConfigurations'
import {
  fileMetadata,
  openInNextWindow,
  S3FileKey,
  uploadFileToS3Bucket,
  wordArrayInString,
  wordCount
} from '@shared/helper'
import {
  IAWSFileUploadData,
  type IFormData,
  type IUploadMetaData
} from '@shared/typings'
import { useMemo, useState, type FC } from 'react'
import { Controller, useFormContext, type UseFormReturn } from 'react-hook-form'
interface ITestimonialProps {
  index: number
  id: string
  filesToBeDeleted: string[]
}

const maxCharLimit = 300

const messages = {
  label: {
    personName: 'Representative Name',
    designation: 'Designation',
    description: 'Testimonial'
  },
  placeholder: {
    personName: 'Sample Name',
    designation: 'Sample text',
    description: 'Sample text...'
  }
}

export const Testimonial: FC<ITestimonialProps> = ({
  id,
  index,
  filesToBeDeleted
}) => {
  const theme = useTheme()
  const [, setRerender] = useState<number>(0)
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors
  } = useFormContext<IFormData>()
  const { mutate: uploadFile1, isLoading: uploadingFile1 } = useUploadFile(
    setValue,
    trigger
  )
  const { mutate: uploadFile2, isLoading: uploadingFile2 } = useUploadFile(
    setValue,
    trigger
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const onFileRemove = (formFieldId, metadataId) => {
    deleteFile(formFieldId)
    setRerender((prevValue) => prevValue + 1)
    setValue(formFieldId, '', { shouldDirty: true })
    setValue(metadataId, uploadFileMetaDataInitialValue)
  }

  const uploadFile = (file, componentId, formFieldId, metadataId) => {
    if (componentId === 0) {
      uploadFileToS3Bucket(
        uploadFile1,
        { file, formFieldId, metadataId },
        deleteFile
      )
    } else {
      uploadFileToS3Bucket(
        uploadFile2,
        { file, formFieldId, metadataId },
        deleteFile
      )
    }
  }

  const onImageUpload = (e: any, componentId, formFieldId, metadataId) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      if (e?.target?.files[0]?.type.startsWith('image/')) {
        uploadFile(e.target.files[0], componentId, formFieldId, metadataId)
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.'
        })
      }
    }
  }

  const onImageDrop = (e: any, componentId, formFieldId, metadataId) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      if (e?.dataTransfer?.files[0]?.type.startsWith('image/')) {
        uploadFile(
          e.dataTransfer.files[0],
          componentId,
          formFieldId,
          metadataId
        )
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.'
        })
      }
    }
  }

  const profilePictureUploaded: boolean =
    (
      getValues(
        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`
      ) ?? ''
    ).length > 0 && !uploadingFile1
  const companyLogoUploaded: boolean =
    (
      getValues(
        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`
      ) ?? ''
    ).length > 0 && !uploadingFile2

  const profilePictureFileMetadata: IUploadMetaData = getValues(
    `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE_METADATA}`
  )
  const profilePictureFileName = profilePictureFileMetadata?.name

  const companyLogoFileMetadata: IUploadMetaData = getValues(
    `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO__METADATA}`
  )
  const companyLogoFileName = companyLogoFileMetadata?.name

  return (
    <Grid theme={theme} container item spacing={3}>
      <Controller
        name={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.METADATA}`}
        control={control}
        render={({ field }) => <> </>}
      />
      <Grid theme={theme} item xs={12} md={6}>
        <Controller
          name={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PERSON_NAME}`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              theme={theme}
              fullWidth={true}
              type={'text'}
              label={messages.label.personName}
              placeholder={messages.placeholder.personName}
              error={errors?.testimonials?.[index]?.person_name?.message}
              id={''}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} md={6}>
        <Controller
          name={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PERSON_DESIGNATION}`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              theme={theme}
              fullWidth={true}
              type={'text'}
              label={messages.label.designation}
              placeholder={messages.placeholder.designation}
              error={errors?.testimonials?.[index]?.person_designation?.message}
              id={''}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.DESCRIPTION}`}
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              theme={theme}
              rows={3}
              fullWidth={true}
              type={'text'}
              label={messages.label.description}
              placeholder={messages.placeholder.description}
              error={errors?.testimonials?.[index]?.description?.message}
              id={'description'}
              onChange={(e) => {
                const words = e.split(' ')
                const wordsArray = words.slice(0, maxCharLimit)
                const maxCharLimitWords = wordsArray.join(' ')
                if (wordsArray.length <= maxCharLimit) {
                  field.onChange(maxCharLimitWords)
                }
              }}
            />
          )}
        />
        <Typography
          mt={{ md: 1, xs: 2 }}
          theme={theme}
          variant="caption"
          component="small"
          display={'block'}
          className={`${
            errors?.testimonials?.[index]?.description?.type === 'maxLength'
              ? 'text-error'
              : ''
          }`}
        >
          {`${wordCount(
            getValues(
              `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.DESCRIPTION}`
            ) ?? ''
          )}/${maxCharLimit} words`}
        </Typography>
      </Grid>
      <Grid theme={theme} item xs={12} md={6}>
        <FileDragDropWrap className="file-drag-drop-wrap">
          {!profilePictureUploaded ? (
            <>
              <Controller
                name={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`}
                control={control}
                render={({ field }) => (
                  <FileDragDrop
                    {...field}
                    id={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`}
                    theme={theme}
                    imageButton={false}
                    isLoading={uploadingFile1}
                    accept={'image/*'}
                    hoverLabel={
                      <>
                        Drag and drop profile image, or{' '}
                        <span className="text-primary link">browse</span>
                      </>
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onImageUpload(
                        event,
                        0,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE_METADATA}`
                      )
                      // field.onChange(event)
                    }}
                    onDrop={(event: React.DragEvent<HTMLElement>) => {
                      onImageDrop(
                        event,
                        0,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE_METADATA}`
                      )
                      // field.onChange(event.dataTransfer.files[0])
                    }}
                    error={Boolean(
                      errors?.testimonials?.[index]?.profile_picture?.message
                    )}
                  />
                )}
              />
              {errors?.testimonials?.[index]?.profile_picture?.message?.length >
                0 &&
              errors?.testimonials?.[index]?.profile_picture?.type ===
                'checkFileType'
                ? (
                <Typography
                  mt={{ md: 1, xs: 2 }}
                  theme={theme}
                  variant="caption"
                  component="small"
                  display={'block'}
                  className={`${
                    errors?.testimonials?.[index]?.profile_picture?.type ===
                    'checkFileType'
                      ? 'text-error'
                      : ''
                  }`}
                >
                  {errors?.testimonials?.[index]?.profile_picture?.message}
                </Typography>
                  )
                : null}
            </>
          ) : (
            <FilePreview
              files={[
                (profilePictureFileMetadata?.name ?? '').length > 0
                  ? profilePictureFileMetadata
                  : uploadFileMetaDataInitialValue
              ]}
              preview={'imageIcon'}
              viewIcon
              onRemoveFile={() => {
                onFileRemove(
                  `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`,
                  `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE_METADATA}`
                )
              }
              }
              onClickIcon={() =>
                openInNextWindow(
                  getValues(
                    `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PROFILE_PICTURE}`
                  )
                )
              }
              theme={theme}
            />
          )}
        </FileDragDropWrap>
      </Grid>
      <Grid theme={theme} item xs={12} md={6}>
        <FileDragDropWrap className="file-drag-drop-wrap">
          {!companyLogoUploaded ? (
            <>
              <Controller
                name={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`}
                control={control}
                render={({ field }) => (
                  <FileDragDrop
                    {...field}
                    id={`${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`}
                    theme={theme}
                    imageButton={false}
                    isLoading={uploadingFile2}
                    accept={'image/*'}
                    hoverLabel={
                      <>
                        Drag and drop company logo, or{' '}
                        <span className="text-primary link">browse</span>
                      </>
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onImageUpload(
                        event,
                        1,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO__METADATA}`
                      )
                      // field.onChange(event)
                    }}
                    onDrop={(event: React.DragEvent<HTMLElement>) => {
                      onImageDrop(
                        event,
                        1,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`,
                        `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO__METADATA}`
                      )
                      // field.onChange(event.dataTransfer.files[0])
                    }}
                    error={Boolean(
                      errors?.testimonials?.[index]?.company_logo?.message
                    )}
                  />
                )}
              />
              {errors?.testimonials?.[index]?.company_logo?.message?.length >
                0 &&
              errors?.testimonials?.[index]?.company_logo?.type ===
                'checkFileType'
                ? (
                <Typography
                  mt={{ md: 1, xs: 2 }}
                  theme={theme}
                  variant="caption"
                  component="small"
                  display={'block'}
                  className={`${
                    errors?.testimonials?.[index]?.company_logo?.type ===
                    'checkFileType'
                      ? 'text-error'
                      : ''
                  }`}
                >
                  {errors?.testimonials?.[index]?.company_logo?.message}
                </Typography>
                  )
                : null}
            </>
          ) : (
            <FilePreview
              files={[
                (companyLogoFileMetadata?.name ?? '').length > 0
                  ? companyLogoFileMetadata
                  : uploadFileMetaDataInitialValue
              ]}
              preview={'imageIcon'}
              viewIcon
              onRemoveFile={() => {
                onFileRemove(
                  `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`,
                  `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO__METADATA}`
                )
              }
              }
              onClickIcon={() =>
                openInNextWindow(
                  getValues(
                    `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.COMPANY_LOGO}`
                  )
                )
              }
              theme={theme}
            />
          )}
        </FileDragDropWrap>
      </Grid>
    </Grid>
  )
}
