import { useTheme } from '@mui/material'
import { Box, Button, Dialog, Icon, Typography } from '@ntpkunity/controls'
import { type FC } from 'react'
import {} from './DeleteConfirmationPopupStyle'
import { DialogWrap } from '@shared/style/dialogStyle'

interface IDeleteConfirmationPopupComponentProps {
  hide: () => void
  onConfirm: () => void
}

export const DeleteConfirmationPopupComponent: FC<
IDeleteConfirmationPopupComponentProps
> = ({ hide, onConfirm }) => {
  const theme = useTheme()
  return (
    <DialogWrap theme={theme} className="dialog-wrap">
      <Dialog
        disablePortal={true}
        open
        theme={theme}
        variant="confirmation"
        size="xs"
        onCloseDialog={hide}
        customFooter={
          <>
            <Button
              theme={theme}
              secondary
              text={'Cancel'}
              onClick={hide}
            ></Button>
            <Button
              theme={theme}
              danger
              text={'Delete'}
              onClick={onConfirm}
            ></Button>
          </>
        }
      >
        <Box theme={theme} className="dialog-icon">
          <Icon name="DialogAlertIcon" />
        </Box>
        <Box theme={theme}>
          <Typography
            theme={theme}
            mb={2}
            className="content-title"
            variant="h2"
            component="h2"
          >
            Are you sure you want to delete?
          </Typography>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component="span"
          >
            Once deleted, you cannot undo this action.
          </Typography>
        </Box>
      </Dialog>
    </DialogWrap>
  )
}
