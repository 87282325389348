import { useTheme } from '@mui/material'
import {
  ResetPasswordComponent,
  type IResponse
} from '@ntpkunity/controls-ums'
import { APP_ROUTES } from '@shared/configuration'
import {
  changePassword,
  errorTitle,
  expiredLinkTypes,
  expiredResetLinkButtonText,
  expiredResetLinkSubTitle,
  HttpStatus
} from '@shared/constants'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { AuthLayoutComponent } from '../../components'

export const ResetPasswordPage = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const token = queryParams.get('tab')

  const [response, setResponse] = useState<IResponse>()

  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.Bad_Request) {
      navigate(APP_ROUTES.AUTHENTICATION.EMAIL_LINK_EXPIRED, {
        replace: true,
        state: {
          token,
          verificationScreenUrl: `${APP_ROUTES.AUTHENTICATION.RESET_PASSWORD}`,
          type: expiredLinkTypes.password,
          layoutText: {
            title: errorTitle,
            subTitle: expiredResetLinkSubTitle,
            buttonText: expiredResetLinkButtonText
          }
        }
      })
    }
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: changePassword,
        type: ''
      })
      navigate(`${APP_ROUTES.AUTHENTICATION.LOGIN}`)
    }
  }, [response])

  return (
    <AuthLayoutComponent>
      {(token ?? '').length > 0 && (
        <ResetPasswordComponent
          setResponse={setResponse}
          validateUrl={token }
          nextScreenUrl={`${APP_ROUTES.AUTHENTICATION.LOGIN}`}
          theme={theme}
        />
      )}
    </AuthLayoutComponent>
  )
}
