import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Input,
  Radio,
  Select,
  Typography,
  PersistentDrawer
} from '@ntpkunity/controls'
import { RadioButtonsWrap } from './addNewPackageDrawerStyle'

export const AddNewPackageDrawerComponent = ({}) => {
  const theme = useTheme()
  return (
    <PersistentDrawer
      title={'Add New Package'}
      openPopUp={true}
      setPopUpState={undefined}
      theme={theme}
      customFooter={
        <Button
          theme={theme}
          primary
          type="submit"
          text="Save Package"
          fullWidth
        />
      }
    >
      <form>
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Product Name"
          sxProps={undefined}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Package Name"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Product Initials"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Pricing Strategy"
          sxProps={undefined}
        />
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Subscription Period"
          sxProps={undefined}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Request ID (Optional)"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Status"
          sxProps={undefined}
        />
        <Box className="divider-with-text" theme={theme}>
          <Typography
            theme={theme}
            className="divider-text"
            variant="caption"
            component="small"
          >
            Base Price
          </Typography>
        </Box>
        <Input
          theme={theme}
          fullWidth={true}
          label="Enter Base Price"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Box className="divider-with-text" theme={theme}>
          <Typography
            theme={theme}
            className="divider-text"
            variant="caption"
            component="small"
          >
            transactional Pricing
          </Typography>
        </Box>
        <Input
          theme={theme}
          fullWidth={true}
          label="Maximum Allowed Transactions"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Maximum Transactions Per Second"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Additional Price Per Transaction"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Box className="divider-with-text" theme={theme}>
          <Typography
            theme={theme}
            className="divider-text"
            variant="caption"
            component="small"
          >
            Free Trial
          </Typography>
        </Box>
        <Input
          theme={theme}
          fullWidth={true}
          label="Maximum Allowed Transactions"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Maximum Transactions Per Second"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Trial Duration"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Box className="divider-with-text" theme={theme}></Box>
        <Typography
          theme={theme}
          variant="subtitle2"
          component="span"
          display={'block'}
        >
          Taxation
        </Typography>
        <RadioButtonsWrap className="radio-btns" theme={theme} mt={1} mb={2}>
          <Radio
            theme={theme}
            defaultValue={1}
            row={true}
            options={[
              { label: 'Applicable', value: 1 },
              { label: 'Not Applicable', value: 2 }
            ]}
          />
        </RadioButtonsWrap>
        <Input
          theme={theme}
          fullWidth={true}
          label="Tax Amount"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
      </form>
    </PersistentDrawer>
  )
}

AddNewPackageDrawerComponent.propTypes = {}

AddNewPackageDrawerComponent.defaultProps = {}
