import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Menu,
  Tooltip
} from '@ntpkunity/controls'

import { TableWrap } from './productManagementTableStyle'
import { type IFetchProducts } from '@shared/typings'
import { useGetProducts } from './ProductManagement TableService'
import {
  draftProductToolTipMessage,
  ProductKeys,
  EllipsisOptions,
  ProductState,
  statusTask
} from '@shared/constants'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'
import { openInNextWindow } from '@shared/helper'
import { CommentsPopupComponent } from '../CommentsPopup/CommentsPopupComponent'
import { useModal } from 'react-modal-hook'
import Switch from '@shared/components/Switch/Switch'
import { TableSkeleton } from '@shared/components/TableSkeleton/TableSkeleton'
import {
  PRODUCT_COMMENTS,
  useAddComment
} from '../CommentsPopup/CommentsPopUpService'

export const ProductManagementTableComponent = ({ unpublished }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { data, isLoading: productsLoading } = useGetProducts()
  const [productName, setProductName] = useState('')
  const [state, setState] = useState('')
  const [status, setStatus] = useState('')
  const productIdRef = useRef<number>()
  const { mutate: addProductComment } = useAddComment()
  const [filteredData, setFilteredData] = useState<IFetchProducts[]>()

  useEffect(() => {
    if (productName?.length > 0 || status.length > 0 || state.length > 0) {
      let _tempUsers: IFetchProducts[] = data
      if (productName?.length > 0) {
        _tempUsers = _tempUsers?.filter((product) =>
          product.name?.toLowerCase().includes(productName?.toLowerCase())
        )
      }

      if (status?.length > 0) {
        if (statusTask.PUBLISHED.toLowerCase().includes(status.toLowerCase())) {
          _tempUsers = _tempUsers?.filter(
            (user: IFetchProducts) => user.is_active
          )
        } else if (
          statusTask.UNPUBLISHED.toLowerCase().includes(status.toLowerCase())
        ) {
          _tempUsers = _tempUsers?.filter(
            (user: IFetchProducts) => !user.is_active
          )
        }
      }
      if (state?.length > 0) {
        _tempUsers = _tempUsers?.filter((product) =>
          product.state?.toLowerCase().includes(state?.toLowerCase())
        )
      }
      setFilteredData(_tempUsers)
    } else {
      setFilteredData(data)
    }
  }, [data])

  const [showCommentsPopupComponent, hideCommentsPopupComponent] = useModal(
    () => (
      <CommentsPopupComponent
        hide={hideCommentsPopupComponent}
        onSave={onSave}
        id={productIdRef.current}
        url={PRODUCT_COMMENTS}
      />
    ),
    [productIdRef]
  )

  const onSave = (comment, reset, getComments) => {
    const data = {
      comments: comment,
      status_task: statusTask.GENERAL,
      product_id: productIdRef.current
    }
    addProductComment(data, {
      onSuccess () {
        reset()
        getComments()
      }
    })
  }

  const getActive = (): IFetchProducts[] =>
    filteredData.filter((user: IFetchProducts) => user.is_active)

  const getInActive = (): IFetchProducts[] =>
    filteredData.filter((user: IFetchProducts) => !user.is_active)

  const getFilteredData = (key: string, value: string): void => {
    let tempUsers = [...filteredData]
    if (value?.trim() === '') {
      setFilteredData(data)
    } else {
      if (key === 'Product Name') {
        tempUsers = getByProductName(value)
      } else if (key === 'State') {
        tempUsers = getbyState(value)
      } else if (key === 'Status') {
        tempUsers = getbyStatus(value)
      }

      tempUsers &&
        setFilteredData(
          tempUsers.length > 0 ? tempUsers : value ? [] : filteredData
        )
    }
  }
  const getByProductName = (value: string): IFetchProducts[] => {
    return data?.filter((product) =>
      product.name?.toLowerCase().includes(value?.toLowerCase())
    )
  }

  const getbyState = (value: string): IFetchProducts[] => {
    const filtered = data?.filter((product) =>
      product.state?.toLowerCase().includes(value?.toLowerCase())
    )
    return filtered
  }
  const getbyStatus = (value: string): IFetchProducts[] => {
    if (
      'unpublished'.startsWith(
        value.toLowerCase().slice(0, Math.max('unpublished'.length - 1, 1))
      )
    ) {
      return data?.filter((x) => !x.is_active)
    } else if (
      'published'.startsWith(
        value.toLowerCase().slice(0, Math.max('published'.length - 1, 1))
      )
    ) {
      return data?.filter((x) => x.is_active)
    }
  }

  const handleOptionClick = (
    _event,
    key: string,
    value: IFetchProducts
  ): void => {
    switch (key) {
      case EllipsisOptions.VIEW_PAGE: {
        const productId = { productId: value.id.toString() }
        const productName = { productName: value.name }
        const searchParams = createSearchParams({
          ...productId,
          ...productName
        })
        const url = `${APP_ROUTES.PREVIEW.PREVIEW_PAGE}?${searchParams}`
        openInNextWindow(url)
        break
      }
      case EllipsisOptions.EDIT: {
        const params = { productId: value.id.toString() }
        navigate({
          pathname: APP_ROUTES.PRODUCT_CONFIGURATION.PRODUCT_TABS,
          search: `?${createSearchParams(params)}`
        })
        break
      }
      case EllipsisOptions.ADD_COMMENT: {
        productIdRef.current = value.id
        showCommentsPopupComponent()
        break
      }
    }
  }

  const getStateClass = (state: string | undefined): string => {
    if ((state ?? '').length > 0) {
      return state === ProductState.LIVE
        ? 'ind-primary'
        : state === ProductState.UNDER_DEVELOPMENT
          ? 'ind-warning'
          : 'ind-danger'
    } else {
      return ''
    }
  }
  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <TableWrap theme={theme} className="main-table-wrap">
        <span className="scroll-hide spr-border"></span>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                {/* <TableCell className="checkbox-cell">
                  <Checkbox theme={theme} label=""></Checkbox>
                </TableCell> */}
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                {/* <TableCell className="checkbox-cell"></TableCell> */}
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type="text"
                      id={''}
                      value={productName}
                      onChange={(e) => {
                        setProductName(e)
                        getFilteredData(ProductKeys.product_name, e)
                      }}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type="text"
                      id={''}
                      value={state}
                      onChange={(e) => {
                        setState(e)
                        getFilteredData(ProductKeys.state, e)
                      }}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type="text"
                      id={''}
                      value={status?.trimStart()}
                      onChange={(e) => {
                        setStatus(e)
                        getFilteredData(ProductKeys.status, e)
                      }}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      startAdornment={''}
                      endAdornment={''}
                      id={''}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {productsLoading ? (
                <TableSkeleton theme={theme} columns={12} rows={6} />
              ) : (
                filteredData?.map((products: IFetchProducts, index: number) => {
                  const switchComponent = (
                    <Switch
                      theme={theme}
                      varient={'basic'}
                      label={products?.is_active ? 'Published' : 'Unpublished'}
                      switchEnabled={products?.is_active}
                      onChange={(e) => {
                        unpublished(e.target.checked, products.id)
                      }}
                      disabled={products?.state === ProductState.DRAFT ?? true}
                      id={products?.name}
                    ></Switch>
                  )

                  return (
                    <TableRow key={index} className="child-tr">
                      {/* <TableCell className="checkbox-cell">
                      <Checkbox theme={theme} label=""></Checkbox>
                    </TableCell> */}
                      <TableCell className="img-cell">
                        {products?.image_url && (
                          <img
                            className="prd-img"
                            src={products.image_url}
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell>{products?.name}</TableCell>
                      <TableCell>
                        <span
                          className={`status-indicator ${getStateClass(
                            products?.state
                          )}`}
                        ></span>
                        {products?.state}
                      </TableCell>
                      <TableCell>
                        {(products?.state ?? '') === ProductState.DRAFT
                          ? (
                          <Tooltip
                            theme={theme}
                            title={draftProductToolTipMessage}
                            placement="bottom-start"
                          >
                            {switchComponent}
                          </Tooltip>
                            )
                          : (
                          <>{switchComponent}</>
                            )}
                      </TableCell>
                      <TableCell>
                        <a
                          href="javascript:void(0)"
                          className="text-primary link"
                          onClick={() => {
                            productIdRef.current = products.id
                            showCommentsPopupComponent()
                          }}
                        >
                          View Comments
                        </a>
                      </TableCell>
                      <TableCell className="action-cell fixed-cell">
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: (
                                <>
                                  {' '}
                                  <Icon
                                    className="menu-icon"
                                    name="IcView"
                                  />{' '}
                                  {'View Page'}{' '}
                                </>
                              ),
                              optionkey: EllipsisOptions.VIEW_PAGE,
                              optionValue: products
                            },
                            {
                              optionText: (
                                <>
                                  {' '}
                                  <Icon
                                    className="menu-icon"
                                    name="EditIcon"
                                  />{' '}
                                  {'Edit'}{' '}
                                </>
                              ),
                              optionkey: EllipsisOptions.EDIT,
                              optionValue: products
                            },
                            {
                              optionText: (
                                <>
                                  {' '}
                                  <Icon
                                    className="menu-icon"
                                    name="AddIcon"
                                  />{' '}
                                  {'Add Comment'}{' '}
                                </>
                              ),
                              optionkey: EllipsisOptions.ADD_COMMENT,
                              optionValue: products
                            }
                          ]}
                          handleOptionClick={handleOptionClick}
                          render={(cb) => (
                            <Button
                              defaultBtn
                              theme={theme}
                              iconText={<Icon name="MoreIcon" />}
                              onClick={cb}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </>
          }
        />
      </TableWrap>
    </DataTableWrap>
  )
}

ProductManagementTableComponent.propTypes = {
  unpublished: PropTypes.func
}

ProductManagementTableComponent.defaultProps = {}
