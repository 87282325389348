import { type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type ITrackUserFilter } from '@shared/typings'
import { useQuery } from 'react-query'
const BASE_URL = process.env.BASE_URL
const SIGN_UP_USERS = 'marketplace/admin/dashboard/track-ums-users?'

export const useGetSignupUsers = (
  columnFilters: string
): {
  data: ITrackUserFilter | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  let url = SIGN_UP_USERS
  if ((columnFilters ?? '').length > 0) {
    url += columnFilters
  }
  const { data, error, isLoading } = useQuery(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: !columnFilters.includes('Invalid Date')
    }
  )
  return { data, error, isLoading }
}
