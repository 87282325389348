import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WidgetWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.widget-wrap': {
    height: '100%',
    '.widget-title': {
      marginBottom: 0
    },
    '.list-item-wrap': {
      maxHeight: 320,
      height: '100%'
    },
    '.underline-select': {
      marginBottom: 24,
      '.u-form-group': {
        maxWidth: 150,
        '.u-form-control': {
          height: 32,
          '&.u-select': {
            '.MuiInputBase-input': {
              padding: '6px 24px 6px 0'
            },
            svg: {
              right: 0
            },
            fieldset: {
              borderColor: 'transparent transparent rgba(0, 0, 0, 0.23)',
              borderRadius: 0
            },
            '&.Mui-focused': {
              fieldset: {
                borderColor:
                  'transparent transparent' + theme.palette.primary.main
              }
            }
          }
        },
        '.u-input-label': {
          transform: 'translate(0px, 6px) scale(1)',
          '&.Mui-focused': {
            transform: 'translate(0px, 6px) scale(1)',
            color: 'rgba(0,0,0,0.87)'
          },
          '&.MuiFormLabel-filled': {
            display: 'none'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        borderRight: 'none',
        paddingRight: 0,
        marginRight: 0
      }
    }
  }
}))
