import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {} from './RecurringRevenueAnalysisStyle'
import { GraphWidget } from '@shared/components/GraphWidget/graphWidget'
import { Box } from '@ntpkunity/controls'

export const RecurringRevenueAnalysisComponent = ({}) => {
  const theme = useTheme()
  return (
    <GraphWidget theme={theme} widgetTitle={'Recurring Revenue Analysis'}>
      <Box theme={theme} className="graph-widget-container"></Box>
    </GraphWidget>
  )
}

RecurringRevenueAnalysisComponent.propTypes = {}

RecurringRevenueAnalysisComponent.defaultProps = {}
