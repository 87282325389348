import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WidgetCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.widget-card-wrap': {
    padding: 24,
    border: '1px solid' + theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    '.value': {
      fontSize: '32px',
      lineHeight: '40px'
    },
    '.icon-text': {
      '.icon': {
        maxHeight: 24,
        img: {
          maxHeight: 24
        }
      }
    }
  }
}))
