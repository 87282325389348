import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Icon,
  ImageViewer,
  Input,
  Menu,
  Tooltip
} from '@ntpkunity/controls'

import { TableWrap } from './WorkQueueTableStyle'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import {
  GET_PRE_SIGNED_URLS,
  useGetPaymentMethodQueue,
  useGetS3PreSignedUrls,
  useUpdateStatus
} from '../../services/workQueueService'
import { formatedDate } from '@shared/helper'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import {
  ButtonType,
  DialogHeading,
  IconType,
  PaymentMethodAction,
  PaymentMethodStatus,
  dateFormat,
  paymentMethodApprove,
  paymentMethodDecline,
  WorkQueueQueryTypes
} from '@shared/constants'
import { useModal } from 'react-modal-hook'
import { useEffect, useState } from 'react'
import { useStoreContext } from 'store/storeContext'
import { TableSkeleton } from '@shared/components/TableSkeleton/TableSkeleton'
import { type IPaymentTenantDetails } from '@shared/typings'

const indicatorColor = {
  approved: 'ind-primary',
  declined: 'ind-danger',
  pending: 'ind-warning'
}

export const WorkQueueTableComponent = () => {
  const theme = useTheme()
  const [selectedItem, setSelectedItem] =
    useState<IPaymentTenantDetails | null>(null)
  const { data: paymentMethodQueue, isLoading: workQueueLoading } =
    useGetPaymentMethodQueue()
  const { mutate: updatePaymentMethodStatus } = useUpdateStatus()
  const { data: preSignedUrls, isLoading: loadingPreSignedUrls } =
    useGetS3PreSignedUrls(
      selectedItem?.document_keys ?? [],
      selectedItem?.shouldFetch
    )

  const [images, setImages] = useState<string[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  useEffect(() => {
    const preSignedUrlsValues = Object.values(preSignedUrls ?? {})
    if (preSignedUrlsValues.length > 0 && selectedItem.shouldFetch) {
      setImages(
        preSignedUrlsValues.map((preSignedUrl) => preSignedUrl.url) ?? []
      )
      setVisible(true)
    }
  }, [preSignedUrls])

  const [filteredWorkQueue, setFilteredWorkQueue] =
    useState(paymentMethodQueue)
  const [filters, setFilters] = useState({
    tenantName: '',
    queryType: '',
    initiationDate: '',
    status: ''
  })

  const {
    actions: { setToaster }
  } = useStoreContext()

  const [showApprove, hideApprove] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.APPROVE}
        iconName={IconType.ALERT}
        title={DialogHeading.APPROVE}
        message={paymentMethodApprove}
        onConfirm={() => { updateStatus(PaymentMethodStatus.APPROVED) }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={() => {
          setSelectedItem(null)
          hideApprove()
        }}
      />
    ),
    [selectedItem]
  )

  const [showDecline, hideDecline] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DECLINE}
        iconName={IconType.ALERT}
        title={DialogHeading.DECLINE}
        message={paymentMethodDecline}
        onConfirm={() => { updateStatus(PaymentMethodStatus.DECLINED) }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={() => {
          setSelectedItem(null)
          hideDecline()
        }}
        danger
      />
    ),
    [selectedItem]
  )

  const updateStatus = (status: string) => {
    const payload = {
      requestId: selectedItem.id,
      queryType: selectedItem.query_type,
      data: { status }
    }
    hideApprove()
    hideDecline()
    updatePaymentMethodStatus(payload as any, {
      onSuccess () {
        setToaster({
          isDisplay: true,
          message: 'Status has been updated',
          type: ''
        })
      }
    })
  }

  const handleSelection = (
    key: string,
    paymentMethod: IPaymentTenantDetails
  ): void => {
    setSelectedItem({
      ...paymentMethod,
      shouldFetch: key === PaymentMethodAction.PROOF_OF_PAYMENT
    })
    switch (key) {
      case PaymentMethodAction.APPROVE:
        showApprove()
        break
      case PaymentMethodAction.DECLINE:
        showDecline()
        break
      case PaymentMethodAction.PROOF_OF_PAYMENT:
        break
    }
  }

  useEffect(() => {
    if (paymentMethodQueue) {
      const filteredData = paymentMethodQueue?.filter((item) => {
        return (
          item.tenant_name
            .toLowerCase()
            .includes(filters.tenantName.trim().toLowerCase()) &&
          `${item.query_type}-${item.payment_frequency}`
            .toLowerCase()
            .includes(filters.queryType.trim().toLowerCase()) &&
          formatedDate(item.created_at, dateFormat)
            .toLowerCase()
            .includes(filters.initiationDate.trim().toLowerCase()) &&
          item.status
            .toLowerCase()
            .includes(filters.status.trim().toLowerCase())
        )
      })
      setFilteredWorkQueue(filteredData)
    }
  }, [filters, paymentMethodQueue])

  const handleInputChange = (value, column) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value
    }))
  }

  const handleClose = () => {
    setVisible(false)
    setImages([])
    setSelectedItem(null)
  }

  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <TableWrap theme={theme} className="main-table-wrap">
        <span className="scroll-hide spr-border"></span>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell className="checkbox-cell">
                  <Checkbox theme={theme} label={''} />
                </TableCell>
                <TableCell>
                  Tenant Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Query Type
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Initiation Date
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Status
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.tenantName}
                      onChange={(e) => { handleInputChange(e, 'tenantName') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.queryType}
                      onChange={(e) => { handleInputChange(e, 'queryType') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.initiationDate}
                      onChange={(e) => { handleInputChange(e, 'initiationDate') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={filters.status}
                      onChange={(e) => { handleInputChange(e, 'status') }}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {workQueueLoading
                ? (
                <TableSkeleton theme={theme} columns={12} rows={4} />
                  )
                : (
                    filteredWorkQueue?.map((paymentMethod, key) => (
                  <TableRow className="child-tr" key={`paymentMethod-${key}`}>
                    <TableCell className="checkbox-cell">
                      <Checkbox theme={theme} label={''} />
                    </TableCell>
                    <TableCell>{paymentMethod.tenant_name}</TableCell>
                    <TableCell>
                      {paymentMethod.query_type ===
                      WorkQueueQueryTypes.BANK_TRANSFER_REQUEST
                        ? `${paymentMethod.query_type} (${paymentMethod.payment_frequency})`
                        : `${paymentMethod.query_type}`}
                    </TableCell>
                    <TableCell>
                      {formatedDate(paymentMethod.created_at, dateFormat)}
                    </TableCell>
                    <TableCell>
                      <span
                        className={`status-indicator ${
                          indicatorColor[paymentMethod?.status?.toLowerCase()]
                        }`}
                      ></span>
                      {paymentMethod.status}
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: PaymentMethodAction.APPROVE,
                            optionkey: PaymentMethodAction.APPROVE,
                            optionValue: PaymentMethodAction.APPROVE,
                            disabled:
                              paymentMethod.status !==
                              PaymentMethodStatus.PENDING
                          },
                          {
                            optionText: PaymentMethodAction.DECLINE,
                            optionkey: PaymentMethodAction.DECLINE,
                            optionValue: PaymentMethodAction.DECLINE,
                            disabled:
                              paymentMethod.status !==
                              PaymentMethodStatus.PENDING
                          },
                          ...(paymentMethod.query_type ===
                          WorkQueueQueryTypes.PAYMENT_PROOF_STATUS_UPDATE
                            ? [
                                {
                                  optionText:
                                    PaymentMethodAction.PROOF_OF_PAYMENT,
                                  optionkey:
                                    PaymentMethodAction.PROOF_OF_PAYMENT,
                                  optionValue:
                                    PaymentMethodAction.PROOF_OF_PAYMENT,
                                  disabled:
                                    paymentMethod.document_keys.length === 0
                                }
                              ]
                            : [])
                        ]}
                        handleOptionClick={(e) => { handleSelection(e.target.innerText, paymentMethod) }
                        }
                        render={(cb) => (
                          <Button
                            defaultBtn
                            theme={theme}
                            iconText={<Icon name="MoreIcon" />}
                            onClick={cb}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                    ))
                  )}
            </>
          }
        />
      </TableWrap>
      <ImageViewer
        theme={theme}
        sliderImagesWithVisible
        sliderImages={images}
        isLoading={loadingPreSignedUrls}
        visible={visible}
        onClose={handleClose}
      />
    </DataTableWrap>
  )
}
