import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const InvoiceCard = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.invoice-card': {
    border: '1px solid' + theme.palette.grey[100],
    padding: 34,
    borderRadius: theme.shape.borderRadius
  }
}))

export const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.table-wrap': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 415px)',
        [theme.breakpoints.up('xl')]: {
          maxHeight: 'calc(100vh - 415px)'
        }
      },
      '.u-table': {
        '.u-table-head, .u-table-body': {
          td: {
            '&.cell-md': {
              minWidth: 380,
              maxWidth: 380
            }
          }
        }
      }
    },
    '.status-indicator': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.palette.primary.main,
      marginRight: 8,
      '&.ind-warning': {
        backgroundColor: theme.palette.warning.main
      },
      '&.ind-success': {
        backgroundColor: theme.palette.primary.main
      }
    },
    '.text-right': {
      textAlign: 'right'
    }
  }
}))
