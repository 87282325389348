import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { type IAccordionProps } from './Accordion.types'

export const AccordionGroup = styled('div', {
  // shouldForwardProp: props => isPropValid(props),
})(({ theme }) => ({
  '&.u-accordion-group': {
    marginBottom: 0,
    borderRadius: theme.shape.borderRadius,
    border: '1px solid' + theme.palette.grey[100],
    backgroundColor: theme.palette.common.white,
    '&:not(:last-child)': {
      borderBottom: '0px none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: 0
    },
    '&:last-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    '.u-accordian-wrap': {
      marginBottom: 0
    },
    '&.is-dragging': {
      backgroundColor: theme.palette.grey[100]
    }
  }
}))

export const Accordion = styled(MuiAccordion, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IAccordionProps>>(({ theme }) => ({
  '&.u-accordian-wrap': {
    color: theme.palette.common.black,
    border: 'none',
    marginBottom: 24,
    backgroundColor: 'transparent',
    '&::before': {
      display: 'none'
    }
  }
}))

export const AccordionSummary = styled(MuiAccordionSummary, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IAccordionProps>>(({ theme }) => ({
  '&.u-accordion-header': {
    backgroundColor: 'transparent',
    '&.filter-accordion': {
      padding: 0,
      minHeight: 0,
      '.MuiAccordionSummary-content': {
        margin: 0
      }
    },
    '.MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      marginLeft: 8
    },
    '&.Mui-expanded': {
      '.MuiAccordionSummary-expandIconWrapper': {
        '&.Mui-expanded': {
          transform: 'rotate(180deg)'
        }
      }
    },
    '.sub-title': {
      color: theme.palette.grey[900],
      maxWidth: 160,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '&.u-accordion-group-header': {
      padding: '4px 16px 4px 16px',
      '.MuiAccordionSummary-content': {
        flexWrap: 'wrap',
        margin: 0
      },
      '.main-title': {
        fontWeight: theme.customVariables.fontWeightSemiBold,
        flex: '0 0 30%',
        maxWidth: '30%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '.title-with-icon': {
          'span, svg': {
            float: 'left'
          },
          span: {
            maxWidth: '90%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          },
          svg: {
            marginLeft: 10,
            height: 20
          }
        }
      },
      '.sub-title': {
        color: theme.palette.grey[600],
        flex: '0 0 60%',
        maxWidth: '60%'
      },
      '.action-btn': {
        display: 'flex',
        alignItems: 'center',
        '.btn': {
          padding: '0 10px'
        }
      }
    }
  }
}))

export const AccordionDetails = styled(MuiAccordionDetails, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IAccordionProps>>(({ theme }) => ({
  '&.u-accordian-body': {
    borderTop: 'none',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[100],
    '&.filter-accordion': {
      padding: '0px 0 24px',
      maxHeight: 144,
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      paddingTop: 24,
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 1px',
        backgroundColor: '#f0f0f0'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: '#cdcdcd'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a6a6a6'
      }
    },
    '&.u-accordion-group-body': {
      padding: 40,
      borderTop: '1px solid' + theme.palette.grey[100],
      borderBottom: 'none'
    }
  }
}))
