import { AuthLayoutComponent } from '@modules/Authentication/components'
import { useTheme } from '@mui/material'
import { Box, Container } from '@ntpkunity/controls'
import { ExpiredLinkComponent, type IResponse } from '@ntpkunity/controls-ums'
import {
  expiredLinkTypes,
  HttpStatus,
  resetPasswordEmailSent,
  verificationEmailSent
} from '@shared/constants'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { ExpireLinkWrap } from './emailLinkExpirationStyle'

interface IState {
  token: string
  priceId?: string
  verificationScreenUrl: string
  resendEmailEndPoint?: string
  type: 'verfication' | 'password'
  layoutText: {
    title: string
    subTitle: string
    buttonText: string
  }
}

export const EmailLinkExpired = () => {
  const theme = useTheme()
  const { state } = useLocation()
  const {
    token,
    verificationScreenUrl,
    type,
    layoutText,
    resendEmailEndPoint,
    priceId
  } = (state as IState) ?? {}
  const [response, setResponse] = useState<IResponse>()
  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message:
          (type as string).toLowerCase() === expiredLinkTypes.password
            ? resetPasswordEmailSent
            : verificationEmailSent,
        type: ''
      })
    }
  }, [response])

  return (
    <>
      <AuthLayoutComponent>
        <ExpireLinkWrap theme={theme} className="expire-link-wrap">
          <ExpiredLinkComponent
            priceId={priceId}
            resendEmailEndPoint={resendEmailEndPoint}
            theme={theme}
            setResponse={setResponse}
            verificationScreenUrl={verificationScreenUrl}
            type={type}
            token={token}
            layoutText={layoutText}
          />
        </ExpireLinkWrap>
      </AuthLayoutComponent>
    </>
  )
}
