import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ContentAreaWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.content-area-wrap': {
    '.u-accordion-header': {
      '&.u-accordion-group-header': {
        justifyContent: 'flex-start',
        '.MuiAccordionSummary-content': {
          maxWidth: 'calc(100% - 30px)',
          width: '100%'
        },
        '.main-title': {
          flexGrow: 1,
          maxWidth: '100%',
          '.MuiTypography-root': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }
        },
        '.action-btn': {
          flexShrink: 0
        }
      }
    }
  }
}))
