const BASE_URL = '/admin'
export const APP_ROUTES = {
  AUTHENTICATION: {
    LOGIN: `${BASE_URL}/login`,
    FORGOT_PASSWORD: `${BASE_URL}/forgot-password`,
    RESET_PASSWORD: `${BASE_URL}/reset-password`,
    RESEND_PASSWORD_LINK: `${BASE_URL}/resend-password-link`,
    EMAIL_LINK_EXPIRED: `${BASE_URL}/email-link-expired`,
    PROFILE: `${BASE_URL}/profile`
  },
  PRODUCT_MANAGEMENT: {
    PRODUCT_LIST: `${BASE_URL}/product-management`
  },
  PRODUCT_CONFIGURATION: {
    PRODUCT_TABS: `${BASE_URL}/product-configurations`
  },
  PACKAGE_MANAGEMENT: {
    PACKAGE_LIST: `${BASE_URL}/package-management`
  },
  USER_MANAGEMENT: {
    USER_LIST: `${BASE_URL}/user-management`
  },
  DASHBOARD: {
    DASHBOARD_PAGE: `${BASE_URL}/dashboard`
  },
  PREVIEW: {
    PREVIEW_PAGE: `${BASE_URL}/preview`
  },
  WORK_QUEUE: {
    WORK_QUEUE_LIST: `${BASE_URL}/work-queue`
  },
  BLOG: {
    BLOG_LIST: `${BASE_URL}/blog`
  }
}

export const tabName = {
  [`${BASE_URL}`]: 'Get Started',
  [`${BASE_URL}/login`]: 'Get Started',
  [`${BASE_URL}/forgot-password`]: 'Fogot Password',
  [`${BASE_URL}/reset-password`]: 'Reset Password',
  [`${BASE_URL}/resend-password-link`]: 'Resend Password Link',
  [`${BASE_URL}/email-link-expired`]: 'Email Link Expired',
  [`${BASE_URL}/profile`]: 'Profile',
  [`${BASE_URL}/dashboard`]: 'Dashboard',
  [`${BASE_URL}/product-management`]: 'Product Management',
  [`${BASE_URL}/package-management`]: 'Package Management',
  [`${BASE_URL}/user-management`]: 'User Management',
  [`${BASE_URL}/product-configurations`]: 'Product Configurations',
  [`${BASE_URL}/work-queue`]: 'Work Queue',
  [`${BASE_URL}/blog`]: 'Blog Management'
}
