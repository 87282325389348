import { useUploadFile } from '@modules/ProductConfigurations/services'
import { useTheme } from '@mui/material'
import {
  Box,
  FileDragDrop,
  FilePreview,
  Grid,
  Input,
  Radio,
  Textarea,
  Typography
} from '@ntpkunity/controls'
import { FileDragDropWrap } from '@shared/components/FileDragDropWrap/FileDragDropWrap'
import { layoutOptionsValue } from '@shared/constants'
import { uploadFileMetaDataInitialValue } from '@shared/constants/productConfigurations'
import {
  openInNextWindow,
  S3FileKey,
  uploadFileToS3Bucket
} from '@shared/helper'
import { type IFormData, type IUploadMetaData } from '@shared/typings'
import { type FC, useState } from 'react'
import { Controller, useFormContext, type UseFormReturn } from 'react-hook-form'

interface IFeatureProps {
  index: number
  id: string
  filesToBeDeleted: string[]
}

const messages = {
  label: {
    featureHeadline: 'Feature Headline',
    featureDescription: 'Feature Description'
  },
  placeholder: {
    typeHere: 'Type here...'
  }
}

const maxCharLimit = 300

export const Feature: FC<IFeatureProps> = ({ id, index, filesToBeDeleted }) => {
  const theme = useTheme()
  const [, setRerender] = useState<number>(0)
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors
  } = useFormContext<IFormData>()
  const { mutate: uploadFile, isLoading: uploadingFile } = useUploadFile(
    setValue,
    trigger
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const onFileRemove = (formFieldId, metadataId) => {
    deleteFile(formFieldId)
    setRerender((prevValue) => prevValue + 1)
    setValue(formFieldId, '', { shouldDirty: true })
    setValue(metadataId, uploadFileMetaDataInitialValue)
  }

  const _uploadFile = (file, formFieldId, metadataId) => {
    uploadFileToS3Bucket(
      uploadFile,
      { file, formFieldId, metadataId },
      deleteFile
    )
  }

  const onImageUpload = (e: any, formFieldId, metadataId) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      if (e?.target?.files[0]?.type.startsWith('image/')) {
        _uploadFile(e.target.files[0], formFieldId, metadataId)
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.'
        })
      }
    }
  }

  const onImageDrop = (e: any, formFieldId, metadataId) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      if (e?.dataTransfer?.files[0]?.type.startsWith('image/')) {
        _uploadFile(e.dataTransfer.files[0], formFieldId, metadataId)
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.'
        })
      }
    }
  }

  const getLabelClassName = (feedbackOption: string): string =>
    ((getValues(`features.${index}.feature_variant`) ?? '').length > 0
      ? getValues(`features.${index}.feature_variant`)
      : layoutOptionsValue.COL_6_TEXT_LAST) === feedbackOption
      ? 'selected'
      : ''

  const layoutOptions = [
    {
      label: '',
      value: layoutOptionsValue.COL_6_TEXT_LAST,
      labelClassName: getLabelClassName(layoutOptionsValue.COL_6_TEXT_LAST)
    },
    {
      label: '',
      value: layoutOptionsValue.COL_12_TEXT_RIGHT,
      labelClassName: getLabelClassName(layoutOptionsValue.COL_12_TEXT_RIGHT)
    },
    {
      label: '',
      value: layoutOptionsValue.COL_6_TEXT_FIRST,
      labelClassName: getLabelClassName(layoutOptionsValue.COL_6_TEXT_FIRST)
    },
    {
      label: '',
      value: layoutOptionsValue.COL_12_TEXT_LEFT,
      labelClassName: getLabelClassName(layoutOptionsValue.COL_12_TEXT_LEFT)
    }
  ]

  const imageUrlUpload: boolean =
    (getValues(`features.${index}.image_url`) ?? '').length > 0 &&
    !uploadingFile

  const uploadedImageFileMetadata: IUploadMetaData = getValues(
    `features.${index}.meta_data.image_url`
  )
  const fileName = uploadedImageFileMetadata?.name

  return (
    <Grid theme={theme} container item spacing={3}>
      <Controller
        name={`features.${index}.meta_data`}
        control={control}
        render={({ field }) => <> </>}
      />
      <Grid theme={theme} item xs={12}>
        <Box theme={theme} className="layout-selection-wrap">
          <Controller
            name={`features.${index}.feature_variant` as any}
            control={control}
            defaultValue={layoutOptionsValue.COL_6_TEXT_LAST}
            render={({ field }) => (
              <Radio
                theme={theme}
                row={true}
                options={layoutOptions}
                defaultValue={field.value}
                {...field}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name={`features.${index}.name` as any}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              type={'text'}
              {...field}
              onChange={(e) => {
                field.onChange(e.slice(0, maxCharLimit))
              }}
              label={messages.label.featureHeadline}
              placeholder={messages.placeholder.typeHere}
              error={errors?.features?.[index]?.name?.message}
            />
          )}
        />
        <Typography
          mt={{ md: 1, xs: 2 }}
          theme={theme}
          variant="caption"
          component="small"
          display={'block'}
          className={`${
            errors?.features?.[index]?.name?.type === 'maxLength'
              ? 'text-error'
              : ''
          }`}
        >
          {`${
            (getValues(`features.${index}.name`) ?? '').length
          }/${maxCharLimit} characters`}
        </Typography>
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Controller
          name={`features.${index}.description` as any}
          control={control}
          render={({ field }) => (
            <Textarea
              label={messages.label.featureDescription}
              id={''}
              theme={theme}
              fullWidth
              type={'text'}
              rows={3}
              {...field}
              placeholder={messages.placeholder.typeHere}
              error={errors?.features?.[index]?.description?.message}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <FileDragDropWrap className="file-drag-drop-wrap">
          {!imageUrlUpload ? (
            <>
              <Controller
                name={`features.${index}.image_url` as any}
                control={control}
                render={({ field }) => (
                  <FileDragDrop
                    {...field}
                    id={`features.${index}.image_url`}
                    theme={theme}
                    imageButton={false}
                    isLoading={uploadingFile}
                    accept={'image/*'}
                    hoverLabel={
                      <>
                        Drag and drop feature image, or{' '}
                        <span className="text-primary link">browse</span>
                      </>
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onImageUpload(
                        event,
                        `features.${index}.image_url`,
                        `features.${index}.meta_data.image_url`
                      )
                      // field.onChange(event)
                    }}
                    onDrop={(event: React.DragEvent<HTMLElement>) => {
                      onImageDrop(
                        event,
                        `features.${index}.image_url`,
                        `features.${index}.meta_data.image_url`
                      )
                      // field.onChange(event.dataTransfer.files[0])
                    }}
                    error={Boolean(
                      errors?.features?.[index]?.image_url?.message
                    )}
                  />
                )}
              />
              {errors?.features?.[index]?.image_url?.message?.length > 0 &&
              errors?.features?.[index]?.image_url?.type === 'checkFileType'
                ? (
                <Typography
                  mt={{ md: 1, xs: 2 }}
                  theme={theme}
                  variant="caption"
                  component="small"
                  display={'block'}
                  className={`${
                    errors?.features?.[index]?.image_url?.type ===
                    'checkFileType'
                      ? 'text-error'
                      : ''
                  }`}
                >
                  {errors?.features?.[index]?.image_url?.message}
                </Typography>
                  )
                : null}
            </>
          ) : (
            <FilePreview
              files={[
                (fileName ?? '').length > 0
                  ? uploadedImageFileMetadata
                  : uploadFileMetaDataInitialValue
              ]}
              preview={'imageIcon'}
              viewIcon
              onRemoveFile={() => {
                onFileRemove(
                  `features.${index}.image_url`,
                  `features.${index}.meta_data.image_url`
                )
              }
              }
              onClickIcon={() =>
                openInNextWindow(getValues(`features.${index}.image_url`))
              }
              theme={theme}
            />
          )}
        </FileDragDropWrap>
      </Grid>
    </Grid>
  )
}
