import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import GreenCheckCircle from '@shared/assets/images/check-cirlce-green.svg'

export const ProductPackages = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.prd-packages-wrap': {
    '.package-card': {
      height: '100%',
      padding: 40,
      borderRadius: 16,
      transition: 'all 0.3s ease-in-out 0s',
      '&:hover': {
        backgroundColor: '#F5F5F7',
        '.package-action': {
          '.btn': {
            '&.btn-secondary': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[900],
                borderColor: theme.palette.grey[900]
              }
            }
          }
        },
        '.pkg-img': {
          transform: 'scale(1.1) translateX(10px)'
        }
      },
      '&.subscribed': {
        backgroundColor: '#F5F5F7',
        '.package-action': {
          '.btn': {
            '&.btn-primary': {
              '&:hover': {
                borderColor: theme.palette.grey[300],
                color: theme.palette.common.white
              }
            }
          }
        }
      },
      '.pkg-shout': {
        '.prd-desc': {
          [theme.breakpoints.up('lg')]: {
            minHeight: 60
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        padding: 32,
        border: '1px solid' + theme.palette.grey[100]
      },
      '.pkg-img': {
        transition: 'all 0.3s ease-in-out 0s'
      }
    },
    '.seperator': {
      backgroundColor: theme.palette.grey[100],
      height: 4,
      width: 40,
      borderRadius: 47
    },
    '.pkg-options': {
      '.list': {
        margin: 0,
        paddingLeft: 32,
        color: theme.palette.grey[600],
        listStyle: 'none outside none',
        li: {
          position: 'relative',
          marginBottom: 8,
          lineHeight: '20px',
          '&:last-child': {
            marginBottom: 0
          },
          '&:before': {
            content: "' '",
            display: 'block',
            backgroundImage: `url(${GreenCheckCircle})`,
            width: 24,
            height: 24,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            left: -32,
            top: -2
          }
        }
      }
    },
    '.package-action': {
      '.btn': {
        padding: '12px 6px',
        marginTop: 16,
        [theme.breakpoints.down('md')]: {
          '&.btn-secondary': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[900],
              borderColor: theme.palette.grey[900]
            }
          }
        }
      }
    },
    '.gwZiig': {
      paddingBottom: 90
    },
    '.sc-bxivhb': {
      minWidth: '292px !important'
    },
    '.sc-ifAKCX': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.grey[100],
      width: 48,
      height: 48,
      marginTop: -60,
      top: '50%',
      borderRadius: '50%',
      transform: 'translateY(-50%)',
      '&.fDBYnF': {
        right: -24,
        transform: 'translatey(-50%) rotate(180deg)'
      },
      '&.jlBBEG': {
        left: -24
      }
    },
    '.custom-plan-wrap': {
      backgroundColor: '#F5F5F7',
      padding: 40,
      borderRadius: 16,
      [theme.breakpoints.down('md')]: {
        padding: 32,
        '.plan-cta': {
          '.btn': {
            width: '100%',
            '&.btn-secondary': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[900],
                borderColor: theme.palette.grey[900]
              }
            }
          }
        }
      }
    }
  }
}))
