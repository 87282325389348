import { type FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Box, Grid, Typography, LinearProgressBar } from '@ntpkunity/controls'
import PlayIcon from '@shared/assets/images/ic-play-circle.svg'
import { HowToConnectWrap } from './HowToConnectSectionStyle'
import { type IHowToConnect, type IProductUsageSteps } from '@shared/typings'
import { ProductDemoVideoComponent } from '@modules/ProductDemoVideo/productDemoVideo'
import { useModal } from 'react-modal-hook'

interface IProductOnBoarding {
  onboarding: IHowToConnect
  name: string
}

export const HowToConnectSectionComponent: FC<IProductOnBoarding> = ({
  onboarding,
  name
}) => {
  const theme = useTheme()
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [activeStep, setActiveStep] = useState(0)
  const [progress, setProgress] = useState(0)
  const [progressSteps, setProgressSteps] = useState(0)
  const stepDuration = 10200 // 5 seconds per step
  const totalSteps = onboarding?.product_usage_steps.length

  const setStepWidht = (totalSteps) => {
    let max_width
    switch (totalSteps) {
      case 5:
        max_width = 20
        break
      case 4:
        max_width = 25
        break
      case 3:
        max_width = 33.33
        break
      case 2:
        max_width = 50
        break
      case 1:
        max_width = 100
        break
      default:
        max_width = null
        break
    }
    return max_width
  }

  function handleStepClick (stepIndex: number): void {
    setProgressSteps(0)
    setActiveStep(stepIndex)
    setProgress(
      (stepIndex + 1) *
        (100 / (onboarding ?? [])?.[0]?.product_usage_steps.length)
    )
  }

  const [showDemoVideo, hideDemoVideo] = useModal(
    () => (
      <ProductDemoVideoComponent hide={hideDemoVideo} videoLink={videoUrl} />
    ),
    [videoUrl]
  )
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const stepDuration = 10200 // 5 seconds per step
        const increment = 100 / totalSteps
        const nextProgress = prevProgress + increment
        if (nextProgress >= increment * (activeStep + 1)) {
          setActiveStep((prevStep) => (prevStep === 3 ? 0 : prevStep + 1))
        } else if (nextProgress >= 100) {
          clearInterval(timer)
          setActiveStep(0)
          setProgress(0)
        }
        return nextProgress
      })
      setProgressSteps(0)
    }, stepDuration) // 1 second interval for progress updates

    return () => {
      clearInterval(timer)
    }
  }, [activeStep, onboarding])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressSteps((prevProgress) =>
        prevProgress === 100 ? 0 : prevProgress + 1
      )
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [activeStep])

  const getThumbnail = (src: string): string | undefined => {
    const youtubeVideoId = src
      ?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
      ?.pop()

    if (youtubeVideoId?.length === 11) {
      const videoThumbnail = `//img.youtube.com/vi/${youtubeVideoId}/0.jpg`
      return videoThumbnail
    }
  }
  return (
    <>
      <HowToConnectWrap theme={theme} className="how-to-connect-wrap">
        <Box theme={theme} className="inner-content">
          <Grid
            theme={theme}
            container
            item
            spacing={3}
            justifyContent={'center'}
          >
            <Grid theme={theme} item xs={12} lg={10}>
              <Typography
                display={'inline-block'}
                mb={3}
                theme={theme}
                className="badge text-white text-uppercase"
                variant="caption"
                component="small"
              >
                HOW TO CONNECT
              </Typography>
            </Grid>
          </Grid>
          <Grid
            theme={theme}
            container
            item
            spacing={{ xs: 3, md: 10 }}
            justifyContent={'center'}
          >
            <Grid theme={theme} item xs={12} md={6.5} lg={5}>
              <Box theme={theme} className="main-title">
                <Typography
                  theme={theme}
                  className="text-h1 text-white"
                  variant="h1"
                  component="h1"
                >
                  {onboarding?.main_text}{' '}
                  <span className="fw-light text-muted">
                    Learn how to connect {name}.
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid theme={theme} item xs={12} md={5.5} lg={5}>
              <Box
                theme={theme}
                className="prd-img"
                mb={2}
                onClick={() => {
                  setVideoUrl(onboarding?.video_url)
                  showDemoVideo()
                }}
              >
                <img src={getThumbnail(onboarding?.video_url)} />
                <span className="play-icon">
                  <img src={PlayIcon} alt="Play" />
                </span>
              </Box>
            </Grid>
          </Grid>
          <Grid
            theme={theme}
            container
            item
            spacing={3}
            justifyContent={'center'}
          >
            <Grid theme={theme} item xs={12} lg={10} overflow={'hidden'}>
              <Typography
                theme={theme}
                mt={{ xs: 0.75, md: 3 }}
                className="text-muted text-body2"
                display={'block'}
                component="span"
                variant="body2"
              >
                {onboarding?.sub_text}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            theme={theme}
            container
            item
            spacing={3}
            justifyContent={'center'}
            mt={{ xs: 5, md: 12 }}
          >
            <Grid theme={theme} item xs={12} lg={10}>
              <Box theme={theme} className="progress-steps">
                {onboarding?.product_usage_steps?.map(
                  (step: IProductUsageSteps, index) => (
                    <Box
                      theme={theme}
                      className={`step-item ${
                        index === activeStep ? 'step-item active' : 'step-item '
                      }`}
                      key={step?.step_headline}
                      maxWidth={`${setStepWidht(totalSteps)}%`}
                    >
                      <LinearProgressBar
                        theme={theme}
                        value={index === activeStep ? progressSteps : 0}
                      />
                      <Typography
                        mt={{ xs: 0, md: 2 }}
                        ml={{ xs: 2, md: 0 }}
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                        className="text-muted text-body2 step-count"
                      >
                        0{index + 1}.
                      </Typography>
                      <Typography
                        mt={{ xs: 0, md: 0.5 }}
                        ml={{ xs: 2, md: 0 }}
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="fw-normal text-muted text-h4"
                        onClick={() => {
                          handleStepClick(index)
                        }}
                      >
                        {step?.step_headline}
                      </Typography>
                      <Typography
                        mt={{ xs: 0, md: 2 }}
                        ml={{ xs: 2, md: 0 }}
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                        className="text-muted text-body2 desc-text"
                      >
                        {index === activeStep && step?.step_description}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </HowToConnectWrap>
    </>
  )
}
