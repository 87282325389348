import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Icon,
  Input,
  Menu,
  Switch,
  Typography
} from '@ntpkunity/controls'
import { TableWrap } from './packageManagementTableStyle'

export const PackageManagementTableComponent = ({}) => {
  const theme = useTheme()
  return (
    <TableWrap theme={theme} className="main-table-wrap">
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell colSpan={7}>Package Details</TableCell>
              <TableCell>Base Price</TableCell>
              <TableCell colSpan={3}>Transactional Pricing</TableCell>
              <TableCell colSpan={3}>Free Trial</TableCell>
              <TableCell>Taxation</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Package Name</TableCell>
              <TableCell>Package Initials</TableCell>
              <TableCell>Pricing Strategy</TableCell>
              <TableCell>Subscription Period</TableCell>
              <TableCell>Request ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Max. Allowed Transactions</TableCell>
              <TableCell>Max. Transactions / Sec.</TableCell>
              <TableCell>Additional Price / Transactions</TableCell>
              <TableCell>Max. Allowed Transactions</TableCell>
              <TableCell>Max. Transactions / Sec.</TableCell>
              <TableCell>Trial Duration</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <TableRow className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell>Sample Product Name</TableCell>
              <TableCell>Free Trial</TableCell>
              <TableCell>ABC</TableCell>
              <TableCell>Flat</TableCell>
              <TableCell>1 Month</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={'basic'}
                  label="Enabled"
                  switchEnabled={true}
                ></Switch>
              </TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>1 Month</TableCell>
              <TableCell>-</TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell>Sample Product Name</TableCell>
              <TableCell>Free Trial</TableCell>
              <TableCell>ABC</TableCell>
              <TableCell>Volume Based</TableCell>
              <TableCell>00 Days</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={'basic'}
                  label="Disabled"
                  switchEnabled={false}
                ></Switch>
              </TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>00 Days</TableCell>
              <TableCell>-</TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell>Sample Product Name</TableCell>
              <TableCell>Free Trial</TableCell>
              <TableCell>ABC</TableCell>
              <TableCell>Flat</TableCell>
              <TableCell>1 Month</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={'basic'}
                  label="Enabled"
                  switchEnabled={true}
                ></Switch>
              </TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>1 Month</TableCell>
              <TableCell>-</TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell>Sample Product Name</TableCell>
              <TableCell>Free Trial</TableCell>
              <TableCell>ABC</TableCell>
              <TableCell>Flat</TableCell>
              <TableCell>1 Month</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={'basic'}
                  label="Enabled"
                  switchEnabled={true}
                ></Switch>
              </TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>$ 000.00</TableCell>
              <TableCell>000</TableCell>
              <TableCell>000</TableCell>
              <TableCell>1 Month</TableCell>
              <TableCell>-</TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        }
      />
    </TableWrap>
  )
}

PackageManagementTableComponent.propTypes = {}

PackageManagementTableComponent.defaultProps = {}
