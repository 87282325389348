import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-table-wrap': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 332px)'
      },
      '.u-table': {
        '.u-table-head': {
          td: {
            overflow: 'visible',
            '.date-range': {
              '.rmdp-container ': {
                '.ep-arrow': {
                  '+': {
                    div: {
                      position: 'fixed !important',
                      transform: 'translate(739.6px, 180px) !important'
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    '.scroll-hide': {
      height: 93,
      '&.spr-border': {
        '&::after': {
          marginTop: 0,
          top: '48%'
        }
      }
    }
  }
}))
