import { formatRelativeDateTime, getInitials } from '@shared/helper'
import type {
  INotificationData,
  INotifications,
  INotificationsBackend,
  INotificationsResponse
} from '@shared/typings'
import { type QueryClient, useQueryClient, InfiniteData } from 'react-query'
import parse from 'html-react-parser'
import { NOTIFICATIONS } from '@modules/Layout/components/layoutWithSideNav/notifications/services'
import { NotificationFilterBy } from '@shared/constants'

export type INotificationState = INotifications

export const initialNotificationState: INotificationState = {
  totalUnreadMessages: 0,
  notifications: [],
  unreadNotifications: [],
  mentionNotifications: [],
  orderNotifications: []
}

export const ACTION_TYPE = {
  SET_ALL_NOTIFICATIONS: 'SET_ALL_NOTIFICATIONS',
  SET_UNREAD_NOTIFICATIONS: 'SET_UNREAD_NOTIFICATIONS',
  SET_MENTION_NOTIFICATIONS: 'SET_MENTION_NOTIFICATIONS',
  SET_ORDER_NOTIFICATIONS: 'SET_ORDER_NOTIFICATIONS',
  REFETCH_NOTIFICATIONS: 'REFETCH_NOTIFICATIONS',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  ERROR_READ_NOTIFICATION: 'ERROR_READ_NOTIFICATION'
}

export interface ISetNotifications {
  type: typeof ACTION_TYPE.SET_ALL_NOTIFICATIONS
  payload: {
    notifications: INotificationsBackend[]
    totalUnreadMessages: number
    handleClick: (data: INotificationsBackend) => void
  }
}
export interface IRefetchNotifications {
  type: typeof ACTION_TYPE.REFETCH_NOTIFICATIONS
}
export interface IReadNotification {
  type: typeof ACTION_TYPE.READ_NOTIFICATION
  payload: {
    notification: INotificationsBackend
    navigate: (cta: string | undefined) => void
  }
}
export interface IErrorReadNotification {
  type: typeof ACTION_TYPE.ERROR_READ_NOTIFICATION
  payload: {
    notificationData: INotificationsBackend
  }
}

export type Action =
  | ISetNotifications
  | IRefetchNotifications
  | IReadNotification
  | IErrorReadNotification

const notificationDataMapper = (
  notificationData: INotificationsBackend,
  handleClick: (data: INotificationsBackend) => void
): INotificationData => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    id,
    created_at,
    action,
    detail,
    first_name,
    last_name,
    is_read,
    ref
  } = notificationData
  const { date, time } = formatRelativeDateTime(created_at)
  return {
    id,
    action,
    notificationMessage: parse(detail ?? ''),
    userInitials: getInitials(first_name, last_name ?? ''),
    isRead: is_read,
    date,
    time,
    ref,
    onClick: () => {
      handleClick(notificationData)
    }
  }
}

const updateNotificationReadStatusInStore = (
  notifications: INotificationData[],
  id: number,
  readStatus: boolean
): INotificationData[] => {
  return notifications.map((notification) => {
    if (notification.id === id) {
      notification.isRead = readStatus
    }
    return notification
  })
}

const updateNotificationReadStatusInCache = (
  queryClient: QueryClient,
  id: number,
  readStatus: boolean
): void => {
  Object.values(NotificationFilterBy).forEach((notificationCategory) => {
    const cachedNotifications: InfiniteData<INotificationsResponse> = queryClient.getQueryData([
      NOTIFICATIONS,
      notificationCategory
    ])

    if (cachedNotifications) {
      const updatedPages = cachedNotifications?.pages.map((page) => ({
        ...page,
        notifications: page.notifications.map((notification) => {
          if (notification.id === id) {
            return {
              ...notification,
              is_read: readStatus
            }
          }
          return notification
        })
      }))

      const lastPageIndex = updatedPages.length - 1
      updatedPages[lastPageIndex].total_unread += readStatus ? -1 : 1

      const updatedNotificationsForCache = {
        pageParams: cachedNotifications.pageParams,
        pages: updatedPages
      }

      queryClient.setQueryData(
        [NOTIFICATIONS, notificationCategory],
        updatedNotificationsForCache
      )
    }
  })
}

const reducer =
  (queryClient: QueryClient) =>
    (state: INotificationState, action: Action): INotificationState => {
      switch (action.type) {
        case ACTION_TYPE.SET_ALL_NOTIFICATIONS: {
          const { payload } = action as ISetNotifications
          const { notifications, totalUnreadMessages, handleClick } = payload
          return {
            ...state,
            totalUnreadMessages,
            notifications: notifications.map((notificationData) =>
              notificationDataMapper(notificationData, handleClick)
            )
          }
        }
        case ACTION_TYPE.SET_UNREAD_NOTIFICATIONS: {
          const { payload } = action as ISetNotifications
          const { notifications, totalUnreadMessages, handleClick } = payload
          return {
            ...state,
            totalUnreadMessages,
            unreadNotifications: notifications
              .map((notificationData) =>
                notificationDataMapper(notificationData, handleClick)
              )
              .filter((notification) => !notification.isRead)
          }
        }
        case ACTION_TYPE.SET_MENTION_NOTIFICATIONS: {
          const { payload } = action as ISetNotifications
          const { notifications, totalUnreadMessages, handleClick } = payload
          return {
            ...state,
            totalUnreadMessages,
            mentionNotifications: notifications
              .map((notificationData) =>
                notificationDataMapper(notificationData, handleClick)
              )
              .filter((notification) => !notification.isRead)
          }
        }
        case ACTION_TYPE.SET_ORDER_NOTIFICATIONS: {
          const { payload } = action as ISetNotifications
          const { notifications, totalUnreadMessages, handleClick } = payload
          return {
            ...state,
            totalUnreadMessages,
            orderNotifications: notifications
              .map((notificationData) =>
                notificationDataMapper(notificationData, handleClick)
              )
              .filter((notification) => !notification.isRead)
          }
        }
        case ACTION_TYPE.REFETCH_NOTIFICATIONS: {
          void queryClient.refetchQueries([NOTIFICATIONS])
          return state
        }
        case ACTION_TYPE.READ_NOTIFICATION: {
          const { payload } = action as IReadNotification
          const { notification, navigate } = payload
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { id, action: cta, is_read } = notification

          if (!is_read) {
            const notifications = updateNotificationReadStatusInStore(
              state.notifications,
              id,
              true
            )
            const unreadNotifications = updateNotificationReadStatusInStore(
              state.unreadNotifications,
              id,
              true
            ).filter((notification) => !notification.isRead)
            const mentionNotifications = updateNotificationReadStatusInStore(
              state.mentionNotifications,
              id,
              true
            ).filter((notification) => !notification.isRead)
            const orderNotifications = updateNotificationReadStatusInStore(
              state.orderNotifications,
              id,
              true
            ).filter((notification) => !notification.isRead)

            const updatedNotifications = {
              totalUnreadMessages: Math.max(0, state.totalUnreadMessages - 1),
              notifications,
              unreadNotifications,
              mentionNotifications,
              orderNotifications
            }

            updateNotificationReadStatusInCache(queryClient, id, true)

            navigate?.(cta)
            return {
              ...state,
              ...updatedNotifications
            }
          }
          navigate?.(cta)
          return state
        }
        case ACTION_TYPE.ERROR_READ_NOTIFICATION: {
          const { payload } = action as IErrorReadNotification
          const { notificationData } = payload
          const { id: errorId } = notificationData

          const notifications = updateNotificationReadStatusInStore(
            state.notifications,
            errorId,
            false
          )

          const unreadNotifications = updateNotificationReadStatusInStore(
            state.unreadNotifications,
            errorId,
            false
          ).filter((notification) => !notification.isRead)

          const mentionNotifications = updateNotificationReadStatusInStore(
            state.mentionNotifications,
            errorId,
            false
          ).filter((notification) => !notification.isRead)

          const orderNotifications = updateNotificationReadStatusInStore(
            state.orderNotifications,
            errorId,
            false
          ).filter((notification) => !notification.isRead)

          const updatedNotifications = {
            totalUnreadMessages: Math.max(0, state.totalUnreadMessages + 1),
            notifications,
            unreadNotifications,
            mentionNotifications,
            orderNotifications
          }

          updateNotificationReadStatusInCache(queryClient, errorId, false)

          return {
            ...state,
            ...updatedNotifications
          }
        }
        default:
          return state
      }
    }

export default reducer
