import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WidgetWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.widget-wrap': {
    height: '100%',
    '.list-item-wrap': {
      maxHeight: 193,
      height: '100%'
    }
  }
}))
