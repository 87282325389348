import { type AppError, formatedDate } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type ITopSubscribedProducts } from '@shared/typings'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL
const TOP_SUBSCRIBED_PRODUCTS =
  'marketplace/admin/dashboard/top-subscribed-products'
export const useGetTopSubscribedProducts = (
  startDate: Date,
  endDate: Date
): {
  data: ITopSubscribedProducts[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const STARTDATE = formatedDate(startDate, 'YYYY-MM-DD')
  const ENDDATE = formatedDate(endDate, 'YYYY-MM-DD')
  let url = `${TOP_SUBSCRIBED_PRODUCTS}`
  if (STARTDATE.length > 0 && ENDDATE.length > 0) {
    url += `?start_date=${STARTDATE}&end_date=${ENDDATE}`
  }
  const { data, error, isLoading } = useQuery(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}
