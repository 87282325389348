import { PricingFields } from '@modules/ProductConfigurations/ProductConfigurationsModule'
import { useTheme } from '@mui/material'
import { Box, Grid, Input, Radio, Select, Textarea } from '@ntpkunity/controls'
import {
  packageImageOptionValue,
  pricingModelOptions
} from '@shared/constants'
import { type IFormData } from '@shared/typings'
import { useState, type FC } from 'react'
import { Controller, useFormContext, type UseFormReturn } from 'react-hook-form'

interface IPricingPackageProps {
  index: number
  id: string
  iconOptionsMap: Map<string, string>
}

const messages = {
  label: {
    packageName: 'Package Name',
    packageNickName: 'Package Nickname',
    packageDescription: 'Package Description',
    packageFeatures: 'Package Features',
    pricingModel: 'Pricing Model',
    basePrice: 'Base Price',
    maximumAllowedUsage: 'Maximum Allowed Usage',
    excessUsagePrice: 'Excess Usage Price',
    isTaxable: 'Taxable',
    duration: 'Duration'
  },
  placeholder: {
    packageName: 'Sample Package Name',
    packageNickName: 'Sample Nickname',
    packageDescription: 'Sample description text...',
    packageFeatures: 'Sample features text...',
    basePrice: '0.00',
    maximumAllowedUsage: '00',
    excessUsagePrice: '0.00',
    duration: '00'
  }
}

export const PricingPackage: FC<IPricingPackageProps> = ({
  id,
  index,
  iconOptionsMap
}) => {
  const theme = useTheme()

  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext()
  const [pricingModel, setPricingModel] = useState<string>(
    getValues(
      `${PricingFields.PRICING_KEY}.${index}.${PricingFields.PRICING_MODEL}`
    )
  )
  const [isPricingModelAvailable, setIsPricingModelAvailable] = useState<boolean>(
    getValues(
      `${PricingFields.PRICING_KEY}.${index}.${PricingFields.PRICING_MODEL}`
    ) ?? false
  )

  const getLabelClassName = (feedbackOption: string): string => {
    const iconUrl = getValues(
      `${PricingFields.PRICING_KEY}.${index}.${PricingFields.ICON_URL}`
    )
    if ((iconUrl ?? '').length > 0) {
      return iconUrl === feedbackOption ? 'selected' : ''
    }
    return ''
  }

  const packageImageOptions = [
    {
      label: '',
      value: packageImageOptionValue.STARTER,
      labelClassName: getLabelClassName(packageImageOptionValue.STARTER),
      disabled:
        [...iconOptionsMap.values()].includes(
          packageImageOptionValue.STARTER
        ) && iconOptionsMap.get(id) !== packageImageOptionValue.STARTER
    },
    {
      label: '',
      value: packageImageOptionValue.BASIC,
      labelClassName: getLabelClassName(packageImageOptionValue.BASIC),
      disabled:
        [...iconOptionsMap.values()].includes(packageImageOptionValue.BASIC) &&
        iconOptionsMap.get(id) !== packageImageOptionValue.BASIC
    },
    {
      label: '',
      value: packageImageOptionValue.STANDARD,
      labelClassName: getLabelClassName(packageImageOptionValue.STANDARD),
      disabled:
        [...iconOptionsMap.values()].includes(
          packageImageOptionValue.STANDARD
        ) && iconOptionsMap.get(id) !== packageImageOptionValue.STANDARD
    },
    {
      label: '',
      value: packageImageOptionValue.PREMIUM,
      labelClassName: getLabelClassName(packageImageOptionValue.PREMIUM),
      disabled:
        [...iconOptionsMap.values()].includes(
          packageImageOptionValue.PREMIUM
        ) && iconOptionsMap.get(id) !== packageImageOptionValue.PREMIUM
    }
  ]

  const isPricingModelFreeTrial =
    pricingModel === pricingModelOptions.FREE_TRIAL
  const isSubscribed =
    (
      getValues(
        `${PricingFields.PRICING_KEY}.${index}.${PricingFields.STRIPE_PRICE_ID}`
      ) ?? ''
    ).length > 0

  return (
    <>
      <Box theme={theme} className="package-img-wrap">
        <Controller
          name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.ICON_URL}`}
          control={control}
          defaultValue={packageImageOptionValue.STARTER}
          render={({ field }) => (
            <Radio
              theme={theme}
              row={true}
              options={packageImageOptions}
              defaultValue={field.value}
              radioError={errors?.prices?.[index]?.icon_url?.message}
              {...field}
              onChange={(value) => {
                field.onChange(value)
                iconOptionsMap.set(id, value)
              }}
            />
          )}
        />
      </Box>
      <Box theme={theme} mt={3}>
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12} md={3}>
            <Controller
              name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.PACKAGE_NAME}`}
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  type="text"
                  fullWidth
                  {...field}
                  label={messages.label.packageName}
                  placeholder={messages.placeholder.packageName}
                  error={errors?.prices?.[index]?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={3}>
            <Controller
              name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.STRIPE_NICK_NAME}`}
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  type="text"
                  fullWidth
                  {...field}
                  label={messages.label.packageNickName}
                  placeholder={messages.placeholder.packageNickName}
                  error={errors?.prices?.[index]?.stripe_nick_name?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={6}>
            <Controller
              name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.DESCRIPTION}`}
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  type="text"
                  fullWidth
                  {...field}
                  label={messages.label.packageDescription}
                  placeholder={messages.placeholder.packageDescription}
                  error={errors?.prices?.[index]?.description?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.PRICE_DETAILS}`}
              control={control}
              render={({ field }) => (
                <Textarea
                  theme={theme}
                  rows={2}
                  id={''}
                  fullWidth
                  type={'text'}
                  {...field}
                  label={messages.label.packageFeatures}
                  placeholder={messages.placeholder.packageFeatures}
                  error={errors?.prices?.[index]?.price_details?.message}
                  onChange={(value) => {
                    if (value === '') {
                      field.onChange('• ')
                    } else {
                      field.onChange(value)
                    }
                  }}
                  onFocus={(event) => {
                    (field?.value?.length ?? '') === 0
                      ? field.onChange(field.value + '• ')
                      : null
                  }
                  }
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                      field.onChange(field.value + '\n• ')
                    }
                  }}
                  onBlur={(event) => {
                    if (field.value.trim() === '•') {
                      field.onChange('')
                    }
                    field.onBlur()
                  }}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={3}>
            <Controller
              name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.PRICING_MODEL}`}
              control={control}
              render={({ field }) => (
                <Select
                  theme={theme}
                  disablePortal={false}
                  disabled={isPricingModelAvailable&&isSubscribed}
                  items={[
                    {
                      text: pricingModelOptions.FREE_TRIAL,
                      value: pricingModelOptions.FREE_TRIAL
                    },
                    {
                      text: pricingModelOptions.STANDARD_PRICING,
                      value: pricingModelOptions.STANDARD_PRICING
                    }
                  ]}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                    setPricingModel(event.target.value as string)
                  }}
                  label={messages.label.pricingModel}
                  placeholder="Select"
                  selectError={errors?.prices?.[index]?.pricing_model?.message}
                />
              )}
            />
          </Grid>
          {!isPricingModelFreeTrial
            ? (
            <Grid theme={theme} item xs={12} md={3}>
              <Controller
                name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.AMOUNT}`}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    type="text"
                    fullWidth
                    disabled={isSubscribed}
                    startAdornment={
                      <Box className="endornment" theme={theme} pr={1}>
                        $
                      </Box>
                    }
                    {...field}
                    label={messages.label.basePrice}
                    masking
                    maskNumeric
                    maskDecimalScale={2}
                    placeholder={messages.placeholder.basePrice}
                    error={errors?.prices?.[index]?.amount?.message}
                  />
                )}
              />
            </Grid>
              )
            : null}
          <Grid theme={theme} item xs={12} md={3}>
            <Controller
              name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.ALLOWED_TRANSACTIONS}`}
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  type="text"
                  fullWidth
                  max={999999999}
                  endAdornment={
                    <Box className="end-endornment endornment" theme={theme}>
                      Unit(s)
                    </Box>
                  }
                  adornmentOnFocus={true}
                  {...field}
                  label={messages.label.maximumAllowedUsage}
                  placeholder={messages.placeholder.maximumAllowedUsage}
                  error={errors?.prices?.[index]?.allowed_transactions?.message}
                />
              )}
            />
          </Grid>
          {isPricingModelFreeTrial
            ? (
            <Grid theme={theme} item xs={12} md={3}>
              <Controller
                name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.VALIDITY_IN_DAYS}`}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    type="text"
                    fullWidth
                    endAdornment={
                      <Box className="end-endornment endornment" theme={theme}>
                        Day(s)
                      </Box>
                    }
                    adornmentOnFocus={true}
                    {...field}
                    label={messages.label.duration}
                    placeholder={messages.placeholder.duration}
                    error={errors?.prices?.[index]?.validity_in_days?.message}
                  />
                )}
              />
            </Grid>
              )
            : null}
          {!isPricingModelFreeTrial
            ? (
            <Grid
              theme={theme}
              item
              xs={12}
              md={3}
              className="dual-endornament"
            >
              <Controller
                name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.ADDITIONAL_PRICE_PER_TRANSACTION}`}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    type="text"
                    fullWidth
                    startAdornment={
                      <Box className="endornment" theme={theme} pr={1}>
                        $
                      </Box>
                    }
                    endAdornment={
                      <Box
                        className="end-endornment endornment"
                        minWidth={84}
                        theme={theme}
                      >
                        Per Unit(s)
                      </Box>
                    }
                    adornmentOnFocus={true}
                    {...field}
                    label={messages.label.excessUsagePrice}
                    placeholder={messages.placeholder.excessUsagePrice}
                    error={
                      errors?.prices?.[index]?.additional_price_per_trans
                        ?.message
                    }
                  />
                )}
              />
            </Grid>
              )
            : null}
          {!isPricingModelFreeTrial
            ? (
            <Grid theme={theme} item xs={12} md={3}>
              <Controller
                name={`${PricingFields.PRICING_KEY}.${index}.${PricingFields.IS_TAXABLE}`}
                control={control}
                render={({ field }) => (
                  <Select
                    theme={theme}
                    disablePortal={false}
                    items={[
                      { text: 'No', value: 'false' },
                      { text: 'Yes', value: 'true' }
                    ]}
                    {...field}
                    label={messages.label.isTaxable}
                    placeholder="Select"
                    selectError={errors?.prices?.[index]?.is_taxable?.message}
                  />
                )}
              />
            </Grid>
              )
            : null}
        </Grid>
      </Box>
    </>
  )
}
