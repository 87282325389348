import { GET_USER_PROFILE } from '@modules/ApplicationSetting/services'
import { useTheme } from '@mui/material'
import { LoginComponent, type IResponse } from '@ntpkunity/controls-ums'
import { APP_ROUTES, setItem } from '@shared/configuration'
import {
  ACCESS_TOKEN_KEY,
  EMAIL,
  HttpStatus,
  invalidCredentials,
  userDisabled,
  USER_NAME
} from '@shared/constants'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { AuthLayoutComponent } from '../../components'

export const LoginPage = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [response, setResponse] = useState<IResponse>()
  const [userEmail, setUserEmail] = useState<string>()

  const {
    states: { userProfile },
    actions: { setUserProfile, setToaster }
  } = useStoreContext()

  useEffect(() => {
    setItem(EMAIL, userEmail)
  }, [userEmail])

  useEffect(() => {
    if (response != null) {
      const { status, data } = response
      const {
        access_token: accessToken,
        roles,
        profile,
        signup_steps: signupSteps,
        tenant_profile: tanantProfile
      } = data ?? {}
      const { name, is_super_admin: isSuperAdmin } = tanantProfile ?? {
        name: '',
        is_super_admin: false
      }

      if (status === HttpStatus.OK) {
        if (!isSuperAdmin) {
          setToaster({
            isDisplay: true,
            message: invalidCredentials,
            type: 'error'
          })
        } else {
          setItem(ACCESS_TOKEN_KEY, accessToken)
          setItem(
            USER_NAME,
            `${(profile?.first_name as string) ?? ''} ${
              (profile?.last_name as string) ?? ''
            }`
          )
          setUserProfile({
            ...userProfile,
            ...profile,
            ...tanantProfile,
            ...roles,
            company_name: name
          })

          queryClient.setQueryData([GET_USER_PROFILE], {
            ...userProfile,
            ...profile,
            ...tanantProfile,
            ...roles,
            company_name: name,
            signup_steps: signupSteps
          })

          navigate(APP_ROUTES.DASHBOARD.DASHBOARD_PAGE, {
            replace: true
          })
        }
      } else if (status === HttpStatus.Locked) {
        setToaster({
          isDisplay: true,
          message: response.message ?? userDisabled,
          type: 'error'
        })
      } else {
        setToaster({
          isDisplay: true,
          message: invalidCredentials,
          type: 'error'
        })
      }
    }
  }, [response])

  return (
    <AuthLayoutComponent>
      <LoginComponent
        theme={theme}
        setResponse={setResponse}
        setUserEmail={setUserEmail}
        forgetScreenUrl={`${APP_ROUTES.AUTHENTICATION.FORGOT_PASSWORD}`}
        layoutText={{
          title: 'Welcome to Unity',
          subTitle: ' '
        }}
      />
    </AuthLayoutComponent>
  )
}
