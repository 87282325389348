import React, { type FC, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { WidgetWrap } from './TopSubscribedProductsStyle'
import { ListWidget } from '@shared/components/ListWidget/listWidget'
import { Box, Typography } from '@ntpkunity/controls'
import ProductImage from '../../../../shared/assets/images/ic-prd-flex.png'
import { useGetTopSubscribedProducts } from './TopSubscribedProductsService'
import { type ITopSubscribedProducts } from '@shared/typings'
import { NoData } from '@shared/components/NoData/noData'

interface TopSubscribedProductsComponentProps {
  startDate: Date
  endDate: Date
}

export const TopSubscribedProductsComponent: FC<
TopSubscribedProductsComponentProps
> = ({ startDate, endDate }) => {
  const theme = useTheme()
  const [subscribedProducts, setSubscribedProducts] = useState<
  ITopSubscribedProducts[] | undefined
  >()
  const { data } = useGetTopSubscribedProducts(startDate, endDate)

  useEffect(() => {
    if ((data ?? []).length > 0) {
      setSubscribedProducts(data)
    }
  }, [data])

  return (
    <WidgetWrap theme={theme} className="widget-wrap">
      <ListWidget theme={theme} widgetTitle={'Top Subscribed Products'}>
        <Box theme={theme} className="list-item-wrap">
          {(subscribedProducts ?? []).length > 0
            ? (
                subscribedProducts?.map(
                  (products: ITopSubscribedProducts, index: number) => {
                    return (
                  <Box key={index} theme={theme} className="list-item">
                    <Box theme={theme} className="list-img">
                      <img
                        src={products?.product_image_url}
                        alt={products?.product_name}
                      />
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="body1"
                        component="p"
                        className="fw-medium"
                      >
                        {products?.product_name}
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                        className="text-muted"
                      >
                        {products.packages.join(', ')}
                      </Typography>
                    </Box>
                  </Box>
                    )
                  }
                )
              )
            : (
            <NoData />
              )}
        </Box>
      </ListWidget>
    </WidgetWrap>
  )
}
