import { type FC, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Button,
  DateRangePicker,
  Icon,
  Menu,
  Typography
} from '@ntpkunity/controls'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import {} from './DashboardPageHeaderStyle'
import DateRange from '@shared/components/DateRange'
import { DateObject } from 'react-multi-date-picker'
import { isDateValid } from '@shared/helper'
import { DashboardTabName } from '@shared/constants'

interface DashboardPageHeaderComponentProps {
  startDate: Date
  endDate: Date
  setDateRange: (date: any) => void
  activeTab: number
}

export const DashboardPageHeaderComponent: FC<
DashboardPageHeaderComponentProps
> = ({ startDate, endDate, setDateRange, activeTab }) => {
  const theme = useTheme()
  const [dateRange, setDateRange1] = useState<DateObject[]>([
    new DateObject().set({ date: startDate }),
    new DateObject().set({ date: endDate })
  ])
  const [selectedStartDate, setSelectedStartDate] = useState<Date>()
  const [selectedEndDate, setSelectedEndDate] = useState<Date>()

  return (
    <CustomPageHeader
      theme={theme}
      variant="border"
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Dashboard
          </Typography>
        </>
      }
      actionArea={
        <>
          {activeTab === DashboardTabName.Business_KPIs_Dashboard
            ? (
            <DateRange
              theme={theme}
              value={dateRange}
              onFocus={onfocus}
              onBlur={onblur}
              masking={true}
              maskNumeric={true}
              placeholder="DD/MM/YYYY - DD/MM/YYYY"
              onChange={(value) => {
                setDateRange1(value)
              }}
              format="MM/DD/YYYY"
              onClose={() => {
                const startDate = dateRange?.[0]?.toDate()
                const endDate = dateRange?.[1]?.toDate()

                if (isDateValid(startDate)) {
                  setSelectedStartDate(startDate)
                }

                if (isDateValid(endDate)) {
                  setSelectedEndDate(endDate)
                }
                if (isDateValid(startDate) && isDateValid(endDate)) {
                  setDateRange([startDate, endDate])
                }
              }}
              range
              rangeHover
              maxDate={new Date()}
              endAdornment={
                <Icon name="CalendarIcon" className="calendar-icon" />
              }
              dateSeparator={' - '}
            />
              )
            : activeTab === DashboardTabName.Subscriber_Usage_Dashboard
              ? (
            <Button
              theme={theme}
              secondary
              startIcon={<Icon name="SaveIcon" />}
              text="Download Report"
            />
                )
              : null}
        </>
      }
    />
  )
}

DashboardPageHeaderComponent.propTypes = {}

DashboardPageHeaderComponent.defaultProps = {}
