import { yupResolver } from '@hookform/resolvers/yup'
import {
  ProductConfigPageHeaderComponent,
  ProductConfigTabsComponent
} from '@modules/ProductConfigurations/components'
import { useGetProduct } from '@modules/ProductConfigurations/services'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { defaultValues, schema } from '@shared/constants/productConfigurations'
import { type IFormData } from '@shared/typings'
import { useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FormProvider, type Resolver, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

const makeDefaultValues = (productData: IFormData): IFormData => {
  productData.makeFormDirty = false
  return productData
}

export const ProductConfigurationsPage = () => {
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId')
  const filesToBeDeletedRef = useRef<string[]>([])

  const { data: productData, error, isLoading } = useGetProduct(productId)

  const formMethod = useForm<IFormData>({
    defaultValues:
      productId && Object.keys(productData ?? {}).length > 0
        ? makeDefaultValues(productData)
        : defaultValues,
    values:
      productId && Object.keys(productData ?? {}).length > 0
        ? makeDefaultValues(productData)
        : defaultValues,
    resetOptions: {
      keepErrors: true // input errors will be retained with value update
    },
    resolver: yupResolver(schema) as Resolver<IFormData, any>,
    mode: 'all'
  })

  return (
    <>
      <Box theme={theme} pb={3}>
        <FormProvider {...formMethod}>
          <form>
            <DndProvider backend={HTML5Backend}>
              <ProductConfigPageHeaderComponent
                filesToBeDeleted={filesToBeDeletedRef.current}
                filesToBeDeletedRef={filesToBeDeletedRef}
              />
              <ProductConfigTabsComponent
                filesToBeDeleted={filesToBeDeletedRef.current}
              />
            </DndProvider>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}
