import { type Dispatch, type ReducerAction } from 'react'
import type IActions from '../configs'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'

export const initialState: any = {}

const reducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        ...action.payload
      }
  }
  return state
}

export const setUserProfile = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: UPDATE_USER_PROFILE, payload: data })
}

export default reducer
