import React, { useEffect, type FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Box, Container, Grid, Typography } from '@ntpkunity/controls'
import QuoteIcon from '@shared/assets/images/ic-quotes.svg'
import {
  ResponsiveCarousel,
  type ResponsiveType
} from '@shared/components/ResponsiveCarousel/ResponsiveCarousel'
import { TestimonialWrap } from './TestimonialSectionStyle'
import { type ITestimonials } from '@shared/typings'
interface IProductTestimonial {
  testimonial: ITestimonials[]
}
const responsive = ({
  itemXl,
  itemLg,
  itemMd,
  itemSm,
  itemXs
}): ResponsiveType => ({
  xl: {
    breakpoint: { max: 4000, min: 1920 },
    items: itemXl
  },
  lg: {
    breakpoint: { max: 1920, min: 1201 },
    items: itemLg
  },
  md: {
    breakpoint: { max: 1200, min: 992 },
    items: itemMd
  },
  sm: {
    breakpoint: { max: 991, min: 576 },
    items: itemSm
  },
  xs: {
    breakpoint: { max: 575, min: 0 },
    items: itemXs
  }
})

const TestimonialItem: FC<{ testimonial: ITestimonials }> = ({
  testimonial
}) => {
  const theme = useTheme()
  const [progress, setProgress] = React.useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 1
      )
    }, 40)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
  // {testimonial.map((testimonialItem, index) => (

    <Box theme={theme} className="testimonial-item">
      <Box theme={theme} className="user-img">
        <img src={testimonial?.profile_picture} alt="" />
      </Box>
      <Box theme={theme} className="user-info" mt={3}>
        <Typography
          theme={theme}
          variant="subtitle1"
          component="p"
          display={{ xs: 'block', md: 'inline-block' }}
        >
          {testimonial?.person_name}
        </Typography>{' '}
        <Typography
          theme={theme}
          variant="body1"
          component="p"
          display={{ xs: 'block', md: 'inline-block' }}
          className="text-muted text-body1"
        >
          <span className="seperator">{' - '}</span>{' '}
          {testimonial?.person_designation}
        </Typography>
      </Box>
      <Box theme={theme} className="testimonial-content" mt={1}>
        <Typography
          theme={theme}
          className="text-muted text-body1"
          variant="body1"
          component="p"
        >
          {testimonial?.description}
        </Typography>
      </Box>
      <Box theme={theme} className="testimonial-image" mt={3}>
        <img src={testimonial?.company_logo} alt="EM Bank" />
      </Box>
    </Box>
    // ))}
  )
}

export const TestimonialSectionComponent: FC<IProductTestimonial> = ({
  testimonial
}) => {
  const theme = useTheme()
  return (
    <>
      {(testimonial ?? [])?.length > 0
        ? (
        <>
          <TestimonialWrap theme={theme} className="testimonial-wrap">
            <Container theme={theme} maxWidth="lg" className="custom-container">
              <Grid
                theme={theme}
                container
                item
                spacing={{ xs: 5, md: 10 }}
                justifyContent={'center'}
              >
                <Grid theme={theme} item xs={12} md={5.5} lg={4.5}>
                  <Box theme={theme} className="section-title">
                    <Typography
                      display={'inline-block'}
                      mb={{ xs: 2, md: 3 }}
                      theme={theme}
                      className="badge text-primary text-uppercase"
                      variant="caption"
                      component="small"
                    >
                      TESTIMONIALS
                    </Typography>
                    <Typography
                      theme={theme}
                      variant="h2"
                      component="h2"
                      className="text-h1"
                    >
                      What our clients say about us!
                    </Typography>
                    <Box theme={theme} className="quote-icon">
                      <img src={QuoteIcon} alt="TESTIMONIALS" />
                    </Box>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={12} md={6.5} lg={5.5}>
                  <Box theme={theme} className="testimonial-slider">
                    <ResponsiveCarousel
                      responsive={responsive({
                        itemXl: 1,
                        itemLg: 1,
                        itemMd: 1,
                        itemSm: 1,
                        itemXs: 1
                      })}
                    >
                      {testimonial?.map((testimonials: ITestimonials) => {
                        return (
                          <TestimonialItem
                            testimonial={testimonials}
                            key={testimonials.id}
                          />
                        )
                      })}
                    </ResponsiveCarousel>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </TestimonialWrap>
        </>
          )
        : null}
    </>
  )
  // return (
  //   <Box theme={theme} className='template-section-wrap'>
  //     <Container theme={theme} maxWidth="lg" className="custom-container">
  //       <TestimonialWrap theme={theme} className='testimonial-wrap'>
  //         <Grid theme={theme} container item spacing={{ xs: 5, md: 10 }} justifyContent={'center'}>
  //           <Grid theme={theme} item xs={12} md={5.5} lg={4.5}>
  //             <Box theme={theme} className='section-title'>
  //               <Typography
  //                 display={'inline-block'}
  //                 mb={{ xs: 2, md: 3 }}
  //                 theme={theme}
  //                 className="badge text-primary text-uppercase"
  //                 variant="caption"
  //                 component="small"
  //               >
  //                 TESTIMONIALS
  //               </Typography>
  //               <Typography theme={theme} variant='h2' component='h2' className='text-h1'>What our clients say about us!</Typography>
  //               <Box theme={theme} className='quote-icon'>
  //                 <img src={QuoteIcon} alt="TESTIMONIALS" />
  //               </Box>
  //             </Box>
  //           </Grid>
  //           <Grid theme={theme} item xs={12} md={6.5} lg={5.5}>
  //             <Box theme={theme} className='testimonial-slider'>
  //               <ResponsiveCarousel
  //                 responsive={responsive({
  //                   itemXl: 1,
  //                   itemLg: 1,
  //                   itemMd: 1,
  //                   itemSm: 1,
  //                   itemXs: 1
  //                 })}
  //               >
  //                 <TestimonialItem />

  //               </ResponsiveCarousel>
  //             </Box>
  //           </Grid>
  //         </Grid>
  //       </TestimonialWrap>
  //     </Container>
  //   </Box>
  // )
}

TestimonialSectionComponent.propTypes = {}

TestimonialSectionComponent.defaultProps = {}
