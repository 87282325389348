import { type IAccordionProps } from './Accordion.types'
import clsx from 'clsx'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary
} from './DraggableAccordionStyle'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

interface DragItem {
  index: number
  key: string
}

type Identifier = string | symbol

const ItemTypes = {
  TESTIMONIAL: 'TESTIMONIAL'
}

const CustomizedAccordions: React.FC<IAccordionProps> = ({
  items,
  theme,
  index,
  className,
  shouldCollapse,
  varient,
  ...props
}) => {
  const {
    content,
    key,
    isExpanded,
    title,
    subtitle,
    actionBtn,
    handleChange,
    moveListItem
  } = items

  const dragRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.TESTIMONIAL,
    item: () => {
      return { key, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.TESTIMONIAL,
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover (item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveListItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const opacity = isDragging ? 0.4 : 1
  drag(dragRef)
  // preview(drop(ref));
  drop(ref)

  return (
    <AccordionGroup
      theme={theme}
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
      className={clsx({
        'u-accordion-group': varient === 'grouped',
        'is-dragging': isDragging
      })}
    >
      <Accordion
        {...props}
        className={clsx({ 'u-accordian-wrap': true, className })}
        disableGutters
        elevation={0}
        key={key}
        square
        defaultExpanded={isExpanded}
        expanded={isExpanded}
        onChange={handleChange(`panel-${index}`)}
        theme={theme}
      >
        <AccordionSummary
          className={clsx({
            'u-accordion-header': true,
            'filter-accordion': varient === 'filters',
            'u-accordion-group-header': varient === 'grouped'
          })}
          expandIcon={<Icon name="ChevronDown" />}
          aria-controls="panel1d-content"
          id="panel1d-header"
          theme={theme}
        >
          {title && (
            <Typography
              theme={theme}
              className="main-title"
              variant="subtitle2"
              component="span"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              theme={theme}
              className="sub-title"
              variant="body2"
              component="span"
            >
              {subtitle}
            </Typography>
          )}
          <Box theme={theme} className="action-btn" role="button">
            <div ref={dragRef}>
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="DragIcon" />}
              />
            </div>
            {actionBtn || null}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          theme={theme}
          className={clsx({
            'u-accordian-body': true,
            'filter-accordion': varient === 'filters',
            'u-accordion-group-body': varient === 'grouped'
          })}
        >
          {typeof content === 'string'
            ? (
            <Typography theme={theme} variant="body1" component="span">
              {content}
            </Typography>
              )
            : (
            <>{content}</>
              )}
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  )
}

export default CustomizedAccordions
