import { type FC, useState } from 'react'
import { TableCell, TableRow, useTheme } from '@mui/material'
import { Button, Checkbox, Icon, Menu } from '@ntpkunity/controls'
import { formatedDate } from '@shared/helper'
import { dateFormat, PaidState } from '@shared/constants'
import { ProductNameTooltip } from './ProductNameTooltip'
import { UsersDialogComponent } from '../UsersDialog/UsersDialogComponent'
import { useModal } from 'react-modal-hook'
import { IItem } from '@shared/typings'

interface IAccordionRowProps {
  item: any
}

enum MenuOptions {
  VIEW_DETAILS = 'View Details',
}

export const AccordionRow: FC<IAccordionRowProps> = ({ item }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [selecteditem, setSelectedItem] = useState<IItem | null>(null)

  const [show, hide] = useModal(
    () => <UsersDialogComponent item={selecteditem} onCloseDialog={hide} />,
    [selecteditem]
  )

  const areProductsSubscribed =
    (item?.subscribed_products_data ?? []).length > 0
  const isSubscribedToOnlyOneProduct =
    areProductsSubscribed && (item?.subscribed_products_data ?? []).length === 1
  const onlySubscribedProduct = isSubscribedToOnlyOneProduct
    ? item?.subscribed_products_data[0]
    : null

  const paymentState = areProductsSubscribed
    ? item.subscribed_products_data.some(
        (product) => product.payment_state.toLowerCase() === PaidState.PAID
      )
      ? 'Paid'
      : 'Free'
    : null

  const handleOptionClick = (_event, key, value): void => {
    switch (key) {
      case MenuOptions.VIEW_DETAILS: {
        setSelectedItem(value)
        show()
        break
      }
    }
  }

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="checkbox-cell">
          <Checkbox theme={theme} label=""></Checkbox>
        </TableCell>
        <TableCell className="toggle-cell">
          <>
            {open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )}
          </>
        </TableCell>
        <TableCell>{item?.company_name}</TableCell>
        <TableCell>
          {item?.onboarding_date
            ? formatedDate(item?.onboarding_date, dateFormat)
            : '-'}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{item?.total_sub_users}</TableCell>

        <TableCell>{item?.country}</TableCell>
        <TableCell>{paymentState}</TableCell>
        <TableCell></TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: (
                  <>
                    {' '}
                    <Icon className="menu-icon" name="IcView" />{' '}
                    {MenuOptions.VIEW_DETAILS}{' '}
                  </>
                ),
                optionkey: MenuOptions.VIEW_DETAILS,
                optionValue: {
                  companyName: item?.company_name,
                  users: item?.subuser_data ?? [],
                },
                disabled: item?.subuser_data === null,
              },
            ]}
            handleOptionClick={handleOptionClick}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          />
        </TableCell>
      </TableRow>
      {open ? (
        <>
          {(item.subscribed_products_data ?? []).map((product, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="toggle-cell"></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <ProductNameTooltip
                  theme={theme}
                  productName={product?.product_name}
                  productPrice={product?.product_price}
                  packageName={product?.product_package_name}
                />
              </TableCell>
              <TableCell>{product?.transaction_performed}</TableCell>
              <TableCell>{product?.allowed_transactions}</TableCell>
              <TableCell>{product?.product_sub_user_count}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{`${formatedDate(
                product?.subscription_start_date,
                dateFormat
              )} - ${formatedDate(
                product?.subscription_end_date,
                dateFormat
              )}`}</TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="IcView" />{' '}
                          {MenuOptions.VIEW_DETAILS}{' '}
                        </>
                      ),
                      optionkey: MenuOptions.VIEW_DETAILS,
                      optionValue: {
                        companyName: item?.company_name,
                        productName: product?.product_name,
                        users: product?.user_role_info ?? [],
                      },
                      disabled: (product?.user_role_info ?? []).length === 0,
                    },
                  ]}
                  handleOptionClick={handleOptionClick}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : null}
    </>
  )
}
