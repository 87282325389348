import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const HeroBanner = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.hero-banner': {
    '.hero-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      maxWidth: 792,
      margin: 'auto auto 40px',
      '.prd-title': {
        fontSize: 64,
        lineHeight: '80px',
        background: 'linear-gradient(to right, #0033FD 0, #00E4D0 100%)',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight
        }
      },
      '.text-justify': {
        textAlign: 'justify'
      },
      '.btn': {
        '&.btn-default': {
          fontWeight: theme.typography.fontWeightMedium
        }
      },
      [theme.breakpoints.down('md')]: {
        margin: 'auto auto 24px'
      }
    },
    '.hero-img': {
      maxHeight: 376,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      img: {
        maxWidth: '100%',
        maxHeight: 376
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 160,
        img: {
          maxHeight: 160
        }
      }
    }
  }
}))
