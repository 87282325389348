import { useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '../../../Layout/components'
import { BlogTableComponent } from '../../components'
import { BlogHeaderComponent } from '@modules/Blog/components/BlogHeader/BlogHeader'

export const BlogListing = ({}) => {
  return (
    <>
      <BlogHeaderComponent />
      <BlogTableComponent />
    </>
  )
}
