import React, { useEffect, useState } from 'react'
import { IconButton, Divider, DividerProps } from '@mui/material'
import {
  Icon,
  type ISideBarLayoutProps,
  Grid,
  Typography,
  Avatar,
  Box,
  List,
  Switch as CustomizedSwitches,
  Menu
} from '@ntpkunity/controls'
import { getInitials } from '@shared/helper'
import {
  CustomizedContainer,
  openedMixin,
  closedMixin,
  CustomizedDrawer,
  LayoutWrap
} from './layoutWithSideNavStyle'
import AppLogo from '@assets/images/unity-logo.svg'

import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES, clearLocalStorage } from '@shared/configuration'
import { HeaderOption, SidebarMenu } from '@shared/constants'
import { useStoreContext } from 'store/storeContext'
import { ADMIN_NOTIFICATION_CHANNEL } from '@shared/constants/pusher'
import { pusher } from 'store/ducks/pusher'
import { NotificationComponent } from './notifications'

export const LayoutWithSideNavComponent: React.FC<ISideBarLayoutProps> = ({
  children,
  theme
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const {
    states: { userProfile }
  } = useStoreContext()

  const [open, setOpen] = React.useState(false)
  const [selectedTab, setSelectedTab] = useState('')

  useEffect(() => {
    switch (pathname) {
      case APP_ROUTES.DASHBOARD.DASHBOARD_PAGE: {
        setSelectedTab(SidebarMenu.DASHBOARD)
        return
      }
      case APP_ROUTES.PRODUCT_MANAGEMENT.PRODUCT_LIST: {
        setSelectedTab(SidebarMenu.PRODUCT_MANAGEMENT)
        return
      }
      case APP_ROUTES.PACKAGE_MANAGEMENT.PACKAGE_LIST: {
        setSelectedTab(SidebarMenu.PACKAGE_MANAGEMENT)
        return
      }
      case APP_ROUTES.USER_MANAGEMENT.USER_LIST: {
        setSelectedTab(SidebarMenu.USER_MANAGEMENT)
        return
      }
      case APP_ROUTES.WORK_QUEUE.WORK_QUEUE_LIST: {
        setSelectedTab(SidebarMenu.WORK_QUEUE)
        return
      }
      case APP_ROUTES.BLOG.BLOG_LIST: {
        setSelectedTab(SidebarMenu.WORK_QUEUE)
      }
    }
  }, [pathname])

  const handleSelection = (value, key, test): void => {
    switch (key) {
      case HeaderOption.MY_PROFILE:
        navigate(APP_ROUTES.AUTHENTICATION.PROFILE)
        break
      case HeaderOption.LOGOUT: {
        pusher.unsubscribe(ADMIN_NOTIFICATION_CHANNEL)
        clearLocalStorage()
        navigate(APP_ROUTES.AUTHENTICATION.LOGIN)
        break
      }
    }
  }

  const sideMenuList = [
    {
      icon: <Icon name="DashboardIcon" />,
      label: 'Dashboard',
      hideLabel: false,
      hideIcon: false,
      onClick: () => {
        navigate(APP_ROUTES.DASHBOARD.DASHBOARD_PAGE)
      },
      selected: selectedTab === SidebarMenu.DASHBOARD,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined
    },
    {
      icon: <Icon name="InvenroryManagementIcon" />,
      label: 'Product Management',
      hideLabel: false,
      hideIcon: false,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      onClick: () => {
        navigate(APP_ROUTES.PRODUCT_MANAGEMENT.PRODUCT_LIST)
      },
      selected: selectedTab === SidebarMenu.PRODUCT_MANAGEMENT
    },
    {
      icon: <Icon name="UserManagementIcon" />,
      label: 'User Management',
      hideLabel: false,
      hideIcon: false,
      onClick: () => {
        navigate(APP_ROUTES.USER_MANAGEMENT.USER_LIST)
      },
      selected: selectedTab === SidebarMenu.USER_MANAGEMENT,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined
    },

    {
      icon: <Icon name="DealerConfigIcon" />,
      label: 'Package Management',
      hideLabel: false,
      hideIcon: false,
      onClick: () => {
        navigate(APP_ROUTES.PACKAGE_MANAGEMENT.PACKAGE_LIST)
      },
      selected: selectedTab === SidebarMenu.PACKAGE_MANAGEMENT,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined
    },
    {
      icon: <Icon name="InvenroryManagementIcon" />,
      label: 'Work Queue',
      hideLabel: false,
      hideIcon: false,
      onClick: () => {
        navigate(APP_ROUTES.WORK_QUEUE.WORK_QUEUE_LIST)
      },
      selected: selectedTab === SidebarMenu.WORK_QUEUE,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined
    },
    {
      icon: <Icon name="PageIcon" />,
      label: 'Blog',
      hideLabel: false,
      hideIcon: false,
      onClick: () => {
        navigate(APP_ROUTES.BLOG.BLOG_LIST)
      },
      selected: selectedTab === SidebarMenu.BLOG,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined
    }
  ]

  const MenuComp = (): JSX.Element => {
    return (
      <Menu
        theme={theme}
        disablePortal={true}
        handleOptionClick={handleSelection}
        options={[
          { optionText: 'My Profile', optionkey: 'My Profile' },
          { optionText: 'Logout', optionkey: 'Logout' }
        ]}
        render={(cb) => (
          <>
            <a
              className="header-link-item user-link"
              href="javascript:void(0)"
              onClick={cb}
            >
              {(userProfile?.first_name ?? '').length > 0
                ? (
                <Avatar theme={theme}>
                  {getInitials(userProfile?.first_name, userProfile?.last_name)}
                </Avatar>
                  )
                : (
                <Icon name="PersonIcon" />
                  )}
            </a>
          </>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    )
  }

  return (
    <LayoutWrap theme={theme} component="div">
      <Box theme={theme} component="aside" className="u-aside-wrap">
        <CustomizedDrawer
          theme={theme}
          variant="permanent"
          open={open}
          className="u-side-nav"
        >
          <div className="logo-wrap">
            <div className="logo-area">
              <img src={AppLogo} alt="" />
            </div>
            <Divider />
          </div>
          <div className="side-nav-area-wrap">
            <div className="aside-content-wrap content-top">
              <List theme={theme} options={sideMenuList} />
            </div>
            <div className="aside-content-wrap">
              <Divider />
              <Typography
                theme={theme}
                variant="caption"
                component="small"
                className="nav-group-title"
              >
                Support
              </Typography>
              <List
                theme={theme}
                options={[
                  {
                    icon: <Icon name="GetHelpIcon" />,
                    label: 'Get Help',
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () => {},
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined
                  },
                  {
                    icon: <Icon name="SubmitFeedbackIcon" />,
                    label: 'Submit Feedback',
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () => {},
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined
                  }
                ]}
              />
            </div>
          </div>
          <div className="aside-content-wrap action-content">
            <Divider />
            <div className="side-nav-actions">
              <CustomizedSwitches
                onChange={(e) => {}}
                theme={theme}
              ></CustomizedSwitches>
              <IconButton
                className="toggle-nav-btn"
                onClick={() => { setOpen(!open) }}
              >
                <Icon name="ChevronRight" />
              </IconButton>
            </div>
          </div>
        </CustomizedDrawer>
      </Box>
      <CustomizedContainer theme={theme} open={open} component="main">
        <Box theme={theme} component="header" sx={{ display: 'flex' }}>
          <Grid theme={theme} direction="row" container>
            <Grid theme={theme} item xs={6} md={6}>
              <Typography
                theme={theme}
                component="span"
                variant="body2"
                className="tenant-title"
              >
                Tenant:
              </Typography>
              <Typography
                theme={theme}
                component="span"
                variant="body2"
                className="tenant-name"
              >
                {' '}
                Unity
              </Typography>
            </Grid>

            <Grid
              theme={theme}
              item
              display="flex"
              justifyContent="right"
              alignItems={'center'}
              gap={'24px'}
              xs={6}
              md={6}
              className="header-nav-right"
            >
              <a href="#link" className="header-link-item item-icon-only">
                <Icon name="SearchIcon" />
              </a>
              <NotificationComponent theme={theme} />
              <MenuComp />
            </Grid>
            <Divider />
          </Grid>
        </Box>
        {children}
        {/* <Box component="footer">footer</Box> */}
      </CustomizedContainer>
    </LayoutWrap>
  )
}

export default LayoutWithSideNavComponent
