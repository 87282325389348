import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, useTheme, Table, TableBody } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Icon,
  Input,
  Menu,
  Switch,
  Typography
} from '@ntpkunity/controls'
import { TableWrap } from './subscribersListStyle'

export const SubscribersListComponent = ({}) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  return (
    <TableWrap theme={theme} className="main-table-wrap">
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="toggle-cell"></TableCell>
              <TableCell className="user-img-cell">
                <Icon name="UserIcon" />
              </TableCell>
              <TableCell>Subscriber Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>City/State</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell className="toggle-cell"></TableCell>
              <TableCell className="user-img-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <TableRow className="child-tr even-row">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="toggle-cell">
                <Icon
                  name="ChevronDown"
                  className="toggle-btn"
                  onClick={() => { setOpen(!open) }}
                />
              </TableCell>
              <TableCell className="user-img-cell">
                <Box theme={theme} className="user-image">
                  <img
                    src={require('../../../../shared/assets/images/table-user-img.png')}
                    alt="User Image"
                  />
                </Box>
              </TableCell>
              <TableCell>Solly Cheevers</TableCell>
              <TableCell>Sample Company Name</TableCell>
              <TableCell>solly.cheevers@sample.com</TableCell>
              <TableCell>000-000-0000</TableCell>
              <TableCell>Santa Monica, CA</TableCell>
              <TableCell>
                <span className="status-indicator ind-danger"></span>
                Payment Due
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="IcView" />{' '}
                          {'View Billing'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="SaveIcon" />{' '}
                          {'Save'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="u-multi-table-wrap">
              <TableCell colSpan={10} className="u-multi-table-container">
                <Accordion expanded={open}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                  <AccordionDetails>
                    <DataTable
                      theme={theme}
                      variant="basic"
                      theadChildren={
                        <>
                          <TableRow className="subtitle-cell">
                            <TableCell colSpan={7}>
                              <Typography
                                theme={theme}
                                variant="h3"
                                component="h3"
                              >
                                Products Subscribed (5)
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow className="thead-items-cell">
                            <TableCell className="checkbox-cell">
                              <Checkbox theme={theme} label=""></Checkbox>
                            </TableCell>
                            <TableCell>Products</TableCell>
                            <TableCell>Package</TableCell>
                            <TableCell>Allowed Transactions</TableCell>
                            <TableCell>Remaining Transactions</TableCell>
                            <TableCell>Over Transactions</TableCell>
                            <TableCell className="action-cell"></TableCell>
                          </TableRow>
                          <TableRow className="filters-row">
                            <TableCell className="checkbox-cell"></TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell className="action-cell"></TableCell>
                          </TableRow>
                        </>
                      }
                      tbodyChildren={
                        <>
                          <TableRow className="child-tr">
                            <TableCell className="checkbox-cell">
                              <Checkbox theme={theme} label=""></Checkbox>
                            </TableCell>
                            <TableCell>Sample Product 1</TableCell>
                            <TableCell>Bronze ($000.00)</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell className="action-cell fixed-cell">
                              <Menu
                                theme={theme}
                                options={[
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="IcView"
                                        />{' '}
                                        {'View Billing'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="SaveIcon"
                                        />{' '}
                                        {'Save'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="EditIcon"
                                        />{' '}
                                        {'Edit'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="DeleteIcon"
                                        />{' '}
                                        {'Delete'}{' '}
                                      </>
                                    )
                                  }
                                ]}
                                render={(cb) => (
                                  <Button
                                    defaultBtn
                                    theme={theme}
                                    iconText={<Icon name="MoreIcon" />}
                                    onClick={cb}
                                  ></Button>
                                )}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow className="child-tr">
                            <TableCell className="checkbox-cell">
                              <Checkbox theme={theme} label=""></Checkbox>
                            </TableCell>
                            <TableCell>Sample Product 1</TableCell>
                            <TableCell>Bronze ($000.00)</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell className="action-cell fixed-cell">
                              <Menu
                                theme={theme}
                                options={[
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="IcView"
                                        />{' '}
                                        {'View Billing'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="SaveIcon"
                                        />{' '}
                                        {'Save'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="EditIcon"
                                        />{' '}
                                        {'Edit'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="DeleteIcon"
                                        />{' '}
                                        {'Delete'}{' '}
                                      </>
                                    )
                                  }
                                ]}
                                render={(cb) => (
                                  <Button
                                    defaultBtn
                                    theme={theme}
                                    iconText={<Icon name="MoreIcon" />}
                                    onClick={cb}
                                  ></Button>
                                )}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </TableCell>
            </TableRow>
            <TableRow className="child-tr odd-row">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="toggle-cell">
                <Icon
                  name="ChevronDown"
                  className="toggle-btn"
                  onClick={() => { setOpen1(!open1) }}
                />
              </TableCell>
              <TableCell className="user-img-cell">
                <Box theme={theme} className="user-image">
                  <img
                    src={require('../../../../shared/assets/images/table-user-img.png')}
                    alt="User Image"
                  />
                </Box>
              </TableCell>
              <TableCell>Murvyn Bolus</TableCell>
              <TableCell>Sample Company Name</TableCell>
              <TableCell>murvyn.bolus@sample.com</TableCell>
              <TableCell>000-000-0000</TableCell>
              <TableCell>Santa Monica, CA</TableCell>
              <TableCell>
                <span className="status-indicator ind-success"></span>
                Paid
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="IcView" />{' '}
                          {'View Billing'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="SaveIcon" />{' '}
                          {'Save'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="u-multi-table-wrap">
              <TableCell colSpan={10} className="u-multi-table-container">
                <Accordion expanded={open1}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                  <AccordionDetails>
                    <DataTable
                      theme={theme}
                      variant="basic"
                      theadChildren={
                        <>
                          <TableRow className="subtitle-cell">
                            <TableCell colSpan={7}>
                              <Typography
                                theme={theme}
                                variant="h3"
                                component="h3"
                              >
                                Products Subscribed (5)
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow className="thead-items-cell">
                            <TableCell className="checkbox-cell">
                              <Checkbox theme={theme} label=""></Checkbox>
                            </TableCell>
                            <TableCell>Products</TableCell>
                            <TableCell>Package</TableCell>
                            <TableCell>Allowed Transactions</TableCell>
                            <TableCell>Remaining Transactions</TableCell>
                            <TableCell>Over Transactions</TableCell>
                            <TableCell className="action-cell"></TableCell>
                          </TableRow>
                          <TableRow className="filters-row">
                            <TableCell className="checkbox-cell"></TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box theme={theme} className="table-filter">
                                <Input
                                  theme={theme}
                                  fullWidth={true}
                                  type={''}
                                  startAdornment={''}
                                  endAdornment={''}
                                  id={''}
                                  placeholder="Search"
                                ></Input>
                                <Button
                                  defaultBtn
                                  theme={theme}
                                  iconText={<Icon name="IconFilter" />}
                                ></Button>
                              </Box>
                            </TableCell>
                            <TableCell className="action-cell"></TableCell>
                          </TableRow>
                        </>
                      }
                      tbodyChildren={
                        <>
                          <TableRow className="child-tr">
                            <TableCell className="checkbox-cell">
                              <Checkbox theme={theme} label=""></Checkbox>
                            </TableCell>
                            <TableCell>Sample Product 1</TableCell>
                            <TableCell>Bronze ($000.00)</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell className="action-cell fixed-cell">
                              <Menu
                                theme={theme}
                                options={[
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="IcView"
                                        />{' '}
                                        {'View Billing'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="SaveIcon"
                                        />{' '}
                                        {'Save'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="EditIcon"
                                        />{' '}
                                        {'Edit'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="DeleteIcon"
                                        />{' '}
                                        {'Delete'}{' '}
                                      </>
                                    )
                                  }
                                ]}
                                render={(cb) => (
                                  <Button
                                    defaultBtn
                                    theme={theme}
                                    iconText={<Icon name="MoreIcon" />}
                                    onClick={cb}
                                  ></Button>
                                )}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow className="child-tr">
                            <TableCell className="checkbox-cell">
                              <Checkbox theme={theme} label=""></Checkbox>
                            </TableCell>
                            <TableCell>Sample Product 1</TableCell>
                            <TableCell>Bronze ($000.00)</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell className="action-cell fixed-cell">
                              <Menu
                                theme={theme}
                                options={[
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="IcView"
                                        />{' '}
                                        {'View Billing'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="SaveIcon"
                                        />{' '}
                                        {'Save'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="EditIcon"
                                        />{' '}
                                        {'Edit'}{' '}
                                      </>
                                    )
                                  },
                                  {
                                    optionText: (
                                      <>
                                        {' '}
                                        <Icon
                                          className="menu-icon"
                                          name="DeleteIcon"
                                        />{' '}
                                        {'Delete'}{' '}
                                      </>
                                    )
                                  }
                                ]}
                                render={(cb) => (
                                  <Button
                                    defaultBtn
                                    theme={theme}
                                    iconText={<Icon name="MoreIcon" />}
                                    onClick={cb}
                                  ></Button>
                                )}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </TableCell>
            </TableRow>
          </>
        }
      />
    </TableWrap>
  )
}

SubscribersListComponent.propTypes = {}

SubscribersListComponent.defaultProps = {}
