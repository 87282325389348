import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'
import { Button, Box, Input, Icon } from '@ntpkunity/controls'

export const FormResetPasswordComponent = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <>
      <Box theme={theme} mb={4}>
        <Input
          fullWidth
          label={'Enter New Password'}
          type={'password'}
          startAdornment={''}
          endAdornment={<Icon name="IcView" />}
          theme={theme}
          id={'txtEmail'}
          name={'txtEmail'}
          placeholder="Type here..."
        />
        <Input
          fullWidth
          label={'Confirm Password'}
          type={'password'}
          startAdornment={''}
          endAdornment={<Icon name="IcView" />}
          theme={theme}
          id={'txtConfirPassword'}
          name={'txtConfirPassword'}
          placeholder="Type here..."
        />
      </Box>
      <Button
        id={'btnSubmit'}
        type={'submit'}
        primary
        text={'Save'}
        fullWidth
        theme={theme}
        onClick={() => {
          navigate(APP_ROUTES.AUTHENTICATION.LOGIN)
        }}
      ></Button>
    </>
  )
}

FormResetPasswordComponent.propTypes = {}

FormResetPasswordComponent.defaultProps = {}
