import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '@modules/Layout/components'
import {
  BillingDetailComponent,
  CustomerListComponent,
  EmployeeFormComponent,
  EmployeesListComponent,
  PageTitleComponent,
  SubscribersListComponent
} from '@modules/UserManagement/components'
import { DuoTab } from '@ntpkunity/controls'
import { Main, PageContent } from './userManagementStyle'

export const UserManagementPage = (props) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Main open={isOpen}>
        <PageTitleComponent
          addNewUser={() => {
            setIsOpen(true)
          }}
        />
        <PageContent theme={theme} className="page-content">
          <DuoTab
            theme={theme}
            varient="underline"
            items={[
              {
                content: <EmployeesListComponent />,
                title: 'Admin Users'
              },
              {
                content: <CustomerListComponent />,
                title: 'Customers'
              },
              {
                content: <SubscribersListComponent />,
                title: 'Subscribers'
              }
            ]}
            defaultTabIndex={0}
          />
        </PageContent>
        {isOpen && <EmployeeFormComponent />}
        {/* <BillingDetailComponent /> */}
      </Main>
    </>
  )
}
