import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Box, Typography, Input, Icon } from '@ntpkunity/controls'
import { APP_ROUTES } from '@shared/configuration'

export const FormLoginComponent = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <form>
      <Box theme={theme}>
        <Input
          fullWidth
          label={'Email'}
          type={'text'}
          startAdornment={''}
          endAdornment={''}
          theme={theme}
          id={'txtEmail'}
          name={'txtEmail'}
          placeholder="sample@sample.com"
        />
        <Input
          fullWidth
          label={'Password'}
          type={'password'}
          startAdornment={''}
          endAdornment={<Icon name="IcView" />}
          theme={theme}
          id={'txtPassword'}
          name={'txtPassword'}
          placeholder="Type here..."
        />
      </Box>
      <Box theme={theme} mt={0.5} className="fp-link" mb={4}>
        <Link
          id={'lnkForgotPassword'}
          to={APP_ROUTES.AUTHENTICATION.FORGOT_PASSWORD}
          className="decoration-none"
        >
          <Typography theme={theme} variant="caption" component="small">
            Forgot your password?
          </Typography>
        </Link>
      </Box>
      <Button
        id={'btnSubmit'}
        name={'btnSubmit'}
        onClick={() => {
          navigate(APP_ROUTES.PRODUCT_MANAGEMENT.PRODUCT_LIST)
        }}
        type={'submit'}
        primary
        text={'login'}
        fullWidth
        theme={theme}
      ></Button>
    </form>
  )
}

FormLoginComponent.propTypes = {}

FormLoginComponent.defaultProps = {}
