import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import { ContentAreaWrap } from './PricingStyle'
import DraggableAccordionCommponent from '@shared/components/DraggableAccordion/DraggableAccordionCommponent'
import { PricingPackage } from './PricingPackage'
import {
  useFieldArray,
  useFormContext,
  type UseFormReturn,
  useWatch
} from 'react-hook-form'
import { type IFormData, type IPrices } from '@shared/typings'
import { useModal } from 'react-modal-hook'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import {
  ButtonType,
  deleteConfirmationMessage,
  DialogHeading,
  IconType,
  packageLimitMessage
} from '@shared/constants'
import { pricesDefaultValues } from '@shared/constants/productConfigurations'
import { useStoreContext } from 'store/storeContext'

export enum PricingFields {
  PRICING_KEY = 'prices',
  PACKAGE_NAME = 'name',
  AMOUNT = 'amount',
  ALLOWED_TRANSACTIONS = 'allowed_transactions',
  ADDITIONAL_PRICE_PER_TRANSACTION = 'additional_price_per_trans',
  DESCRIPTION = 'description',
  STRIPE_NICK_NAME = 'stripe_nick_name',
  ICON_URL = 'icon_url',
  PRICE_DETAILS = 'price_details',
  VALIDITY_IN_DAYS = 'validity_in_days',
  IS_TAXABLE = 'is_taxable',
  IS_SUBSCRIBED = 'is_subscribed',
  PRICING_MODEL = 'pricing_model',
  STRIPE_PRICE_ID = 'stripe_price_id',
}

export const PricingComponent = ({}) => {
  const theme = useTheme()
  const removeIndexRef = useRef<number>()
  const removeMapKeyRef = useRef<string>()

  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext()
  const { fields, remove, append, move } = useFieldArray({
    name: PricingFields.PRICING_KEY,
    control
  })
  const {
    actions: { setToaster }
  } = useStoreContext()

  const iconOptionsMap = useMemo<Map<string, string>>(
    () =>
      (fields ?? []).length > 0
        ? new Map(
          fields.map((field) => [
            field.id,
            (field as unknown as IPrices).icon_url
          ])
        )
        : new Map(),
    [fields]
  )

  const [expanded, setExpanded] = useState<string | false>(
    `panel-${fields.length - 1}`
  )

  useEffect(() => {
    setExpanded(`panel-${fields.length - 1}`)
  }, [fields.length])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const [showDeletePopup, hideDeletePopup] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DELETE}
        iconName={IconType.ALERT}
        title={DialogHeading.DELETE}
        message={deleteConfirmationMessage}
        onConfirm={() => {
          remove(removeIndexRef.current)
          iconOptionsMap.delete(removeMapKeyRef.current)
          hideDeletePopup()
        }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={hideDeletePopup}
        onCloseDialog={hideDeletePopup}
        danger
      />
    ),
    [removeIndexRef.current, remove]
  )

  const deletePricingPackageItem = (index: number, key: string) => {
    removeIndexRef.current = index
    removeMapKeyRef.current = key
    showDeletePopup()
  }

  const addPricingPackage = () => {
    if (fields.length >= 4) {
      setToaster({
        isDisplay: true,
        message: packageLimitMessage,
        type: ''
      })
      return
    }
    append({ ...pricesDefaultValues })
  }

  const movePricingPackageItem = (dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex)
    setValue('makeFormDirty', false, { shouldDirty: true })
  }

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <Box theme={theme} className="accordion-panel-wrap">
        {fields.map((field, index: number) => (
          <DraggableAccordionCommponent
            key={field.id}
            index={index}
            theme={theme}
            varient="grouped"
            shouldCollapse={true}
            items={{
              content: (
                <>
                  <PricingPackage
                    key={field.id}
                    id={field.id}
                    index={index}
                    iconOptionsMap={iconOptionsMap}
                  />
                </>
              ),
              title: (
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component={'p'}
                  className="fw-sbold text-body1"
                >
                  {getValues(
                    `${PricingFields.PRICING_KEY}.${index}.${PricingFields.PACKAGE_NAME}`
                  )}
                </Typography>
              ),
              key: field.id,
              isExpanded: expanded === `panel-${index}`,
              handleChange,
              moveListItem: movePricingPackageItem,
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={
                      <Icon
                        name="DeleteIcon"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          if (
                            (
                              (field as unknown as IPrices).stripe_price_id ??
                              ''
                            ).length === 0
                          ) {
                            deletePricingPackageItem(index, field.id)
                          }
                          e.stopPropagation()
                        }}
                      />
                    }
                  />
                </>
              )
            }}
          />
        ))}
      </Box>
      <Box theme={theme} mt={3}>
        <Button
          theme={theme}
          secondary
          fullWidth
          text="Add New Package"
          onClick={addPricingPackage}
        />
      </Box>
    </ContentAreaWrap>
  )
}
