import { WorkQueueQueryTypes } from '@shared/constants'
import Http from '@shared/helper/http-api'
import {
  type IPaymentTenantDetails,
  type IWorkQueueStatusUpdate,
  type PresignedUrlsResponse
} from '@shared/typings'
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
  type UseMutationResult
} from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
export const GET_PRE_SIGNED_URLS =
  'marketplace/user-management/get-presigned-urls'
export const BANK_TRANSFER_REQUEST =
  'marketplace/admin/payment/update-request-status'
export const PAYMENT_PROOF_STATUS_UPDATE =
  'marketplace/admin/payment/payment-proof'
export const GET_QUEUE = 'marketplace/admin/payment/get-queue'

export const useUpdateStatus = (): UseMutationResult<
any,
AppError | undefined,
IWorkQueueStatusUpdate,
unknown
> => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => {
      const { requestId, queryType, data } = body
      let url = ''
      switch (queryType) {
        case WorkQueueQueryTypes.BANK_TRANSFER_REQUEST:
          url = BANK_TRANSFER_REQUEST
          break

        case WorkQueueQueryTypes.PAYMENT_PROOF_STATUS_UPDATE:
          url = PAYMENT_PROOF_STATUS_UPDATE
          break
        default:
          break
      }
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(`${url}/${requestId}`, data)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(GET_QUEUE)
      }
    }
  )
}

export const useGetPaymentMethodQueue = (): {
  data: IPaymentTenantDetails[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<IPaymentTenantDetails[]>(
    GET_QUEUE,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_QUEUE)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

export const useGetS3PreSignedUrls = (
  paymentProofKeys: string[] | null,
  shouldFetch = false
): UseQueryResult<PresignedUrlsResponse, AppError | undefined> => {
  const url = (paymentProofKeys ?? []).reduce((acc, key, index) => {
    const separator = index === 0 ? '?' : '&'
    return `${acc}${separator}keys=${key}`
  }, GET_PRE_SIGNED_URLS)

  return useQuery(
    [url],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldFetch && (paymentProofKeys ?? []).length > 0,
      staleTime: Infinity
    }
  )
}
