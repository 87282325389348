import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Button, Icon, Menu, Typography } from '@ntpkunity/controls'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'

export const BlogHeaderComponent = ({}) => {
  const theme = useTheme()
  return (
    <CustomPageHeader
      theme={theme}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Blog Management
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button
            theme={theme}
            primary
            startIcon={<Icon name="AddIcon" />}
            text="Add New Blog"
            onClick={() => {}}
          ></Button>
        </>
      }
    />
  )
}

BlogHeaderComponent.propTypes = {
  fnAddNewBlog: PropTypes.func
}

BlogHeaderComponent.defaultProps = {}
