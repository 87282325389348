import { unityTheme } from '@ntpkunity/controls'
import { type IThemeConfig } from '../configs'
import type IActions from '../configs'
import { type Dispatch, type ReducerAction } from 'react'
import { lightTheme } from '../../shared/theme'
// import { Theme } from '@shared/constants/enums'

export const UPDATE_THEME = 'UPDATE_THEME'

export const initialState: IThemeConfig = {
  theme: unityTheme
}

const reducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case UPDATE_THEME:
      return {
        theme: (action.payload as IThemeConfig).theme as any
      }
  }
  return state
}

export const updateThemeConfig = (
  data: 'default' | 'dark',
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  // if (data === Theme.DARK) {
  //   dispatch({ type: UPDATE_THEME, payload: { theme: lightTheme } })
  // }
  // if (data === Theme.DEFAULT) {
  //   dispatch({ type: UPDATE_THEME, payload: { theme: unityTheme } })
  // }
}

export default reducer
