import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Button, Icon, Menu, Typography } from '@ntpkunity/controls'
import {} from './packagePageHeaderStyle'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'

export const PackagePageHeaderComponent = ({ fnAddNewProduct }) => {
  const theme = useTheme()
  return (
    <CustomPageHeader
      theme={theme}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Package Management
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button
            theme={theme}
            primary
            startIcon={<Icon name="AddIcon" />}
            text="Add New Package"
            onClick={() => {
              fnAddNewProduct()
            }}
          ></Button>
          <Menu
            theme={theme}
            options={[{ optionText: 'Export CSV' }]}
            render={(cb) => (
              <Button
                theme={theme}
                secondary
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        </>
      }
    />
  )
}

PackagePageHeaderComponent.propTypes = {
  fnAddNewProduct: PropTypes.func
}

PackagePageHeaderComponent.defaultProps = {}
