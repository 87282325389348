import React, { type FC, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { ContentAreaWrap } from './SupportStyle'
import { FileDragDrop, FilePreview, Grid, Input } from '@ntpkunity/controls'
import { Controller, type UseFormReturn, useFormContext } from 'react-hook-form'
import {
  IAWSFileUploadData,
  type IFormData,
  type IUploadMetaData
} from '@shared/typings'
import {
  useDeleteFile,
  useUploadFile
} from '@modules/ProductConfigurations/services'
import {
  fileMetadata,
  openInNextWindow,
  S3FileKey,
  uploadFileToS3Bucket
} from '@shared/helper'
import { uploadFileMetaDataInitialValue } from '@shared/constants/productConfigurations'
import { FileDragDropWrap } from '@shared/components/FileDragDropWrap/FileDragDropWrap'

const messages = {
  name: {
    documentation_url: 'support.documentation_url',
    pr_pack_url: 'support.pr_pack_url',
    pr_pack_url_metadata: 'support.meta_data.pr_pack_url'
  },
  label: {
    documentationLink: 'API Documentation Link',
    pr_pack_url: 'PR Pack Link',
    demoVideoUrl: 'Demo Video URL',
    state: 'state',
    productDescription: 'Product Description'
  },
  placeholder: {
    typeHere: 'Type here...'
  }
}
interface ISupportComponentProps {
  filesToBeDeleted: string[]
}

export const SupportComponent: FC<ISupportComponentProps> = ({
  filesToBeDeleted
}) => {
  const theme = useTheme()
  const [, setRerender] = useState<number>(0)
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    resetField
  } = useFormContext<IFormData>()
  const { mutate: uploadFile, isLoading: uploadingFile } = useUploadFile(
    setValue,
    trigger
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const onFileRemove = (formFieldId, metadataId) => {
    deleteFile(formFieldId)
    setRerender((prevValue) => prevValue + 1)
    setValue(formFieldId, '', { shouldDirty: true })
    setValue(metadataId, uploadFileMetaDataInitialValue)
  }

  const _uploadFile = (file, formFieldId, metadataId) => {
    uploadFileToS3Bucket(
      uploadFile,
      { file, formFieldId, metadataId },
      deleteFile
    )
  }

  const onImageUpload = (e: any, formFieldId, metadataId) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      _uploadFile(e.target.files[0], formFieldId, metadataId)
    }
  }

  const onImageDrop = (e: any, formFieldId, metadataId) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      _uploadFile(e.dataTransfer.files[0], formFieldId, metadataId)
    }
  }

  const prPackUploaded: boolean =
    (getValues(messages.name.pr_pack_url as any) ?? '').length > 0 &&
    !uploadingFile

  const prPackUploadedFileMetadata: IUploadMetaData = getValues(
    messages.name.pr_pack_url_metadata as any
  )
  const fileName = prPackUploadedFileMetadata?.name
  const fileType = prPackUploadedFileMetadata?.type

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <Grid theme={theme} container item spacing={3}>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={messages.name.documentation_url as any}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={theme}
                {...field}
                fullWidth={true}
                label={messages.label.documentationLink}
                type={'text'}
                placeholder={messages.placeholder.typeHere}
                error={errors?.support?.documentation_url?.message}
                id={''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={messages.name.pr_pack_url as any}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth={true}
                label={messages.label.pr_pack_url}
                {...field}
                type={'text'}
                placeholder={messages.placeholder.typeHere}
                error={errors?.support?.pr_pack_url?.message}
                id={''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <FileDragDropWrap className="file-drag-drop-wrap">
            {!prPackUploaded
              ? (
              <Controller
                name={messages.name.pr_pack_url as any}
                control={control}
                render={({ field }) => (
                  <FileDragDrop
                    id={messages.name.pr_pack_url}
                    theme={theme}
                    imageButton={false}
                    isLoading={uploadingFile}
                    hoverLabel={
                      <>
                        Drag and drop PR pack, or{' '}
                        <span className="text-primary link">browse</span>
                      </>
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onImageUpload(
                        event,
                        messages.name.pr_pack_url,
                        messages.name.pr_pack_url_metadata
                      )
                    }}
                    onDrop={(event: React.DragEvent<HTMLElement>) => {
                      onImageDrop(
                        event,
                        messages.name.pr_pack_url,
                        messages.name.pr_pack_url_metadata
                      )
                    }}
                    error={Boolean(errors?.support?.pr_pack_url?.message)}
                  />
                )}
              />
                )
              : null}
            {!uploadingFile && (fileName ?? '').length > 0
              ? (
              <FilePreview
                files={[prPackUploadedFileMetadata]}
                preview={
                  new RegExp('^image/w+$').exec(fileType ?? '')
                    ? 'imageIcon'
                    : 'fileIcon'
                }
                viewIcon
                onRemoveFile={() => {
                  onFileRemove(
                    messages.name.pr_pack_url,
                    messages.name.pr_pack_url_metadata
                  )
                }
                }
                onClickIcon={() =>
                  openInNextWindow(getValues(messages.name.pr_pack_url as any))
                }
                theme={theme}
              />
                )
              : null}
          </FileDragDropWrap>
        </Grid>
      </Grid>
    </ContentAreaWrap>
  )
}
