import { StyledEngineProvider } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import './root.component.css'

import {
  GetUserGeneralInfo,
  NavigationComponent
} from '@modules/ApplicationSetting/components'
import BaseLayout from '@modules/Layout/components/baseLayout/BaseLayout'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import StoreProvider from 'store/storeContext'
import { marketplaceTheme } from './shared/theme'
import { ModalProvider } from 'react-modal-hook'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

export default function Root (props) {
  const queryClient = new QueryClient()

  const router = createBrowserRouter([
    { path: '*', Component: NavigationComponent }
  ])

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={marketplaceTheme}>
            <BaseLayout>
              <ModalProvider>
                <GetUserGeneralInfo>
                  <RouterProvider router={router} />
                </GetUserGeneralInfo>
              </ModalProvider>
            </BaseLayout>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </StoreProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
