import { useTheme } from '@mui/material'
import { Box, Button, Container, Grid, Typography } from '@ntpkunity/controls'
import { type IPrices } from '@shared/typings'
import { type FC } from 'react'
import { ProductPackages } from './PricingPackagesSectionStyle'
interface IPricingPackagesSectionComponent {
  prices: IPrices[]
}

export const PricingPackagesSectionComponent: FC<
IPricingPackagesSectionComponent
> = ({ prices }) => {
  const theme = useTheme()
  return (
    <ProductPackages
      id="pricing"
      data-testid="Pricing"
      theme={theme}
      className="prd-packages-wrap"
    >
      <Container theme={theme} maxWidth="lg" className="custom-container">
        <Typography
          theme={theme}
          textAlign="center"
          mb={1}
          variant="h2"
          component="h2"
          className="text-h1"
        >
          Pricing
        </Typography>
        <Typography
          theme={theme}
          textAlign="center"
          mb={{ xs: 3, md: 5 }}
          variant="body1"
          component="p"
          className="text-body2 text-muted"
        >
          We have several powerful plans to best fit your business needs. Choose
          the one which works for you!
        </Typography>
        <Grid
          theme={theme}
          container
          justifyContent={'center'}
          item
          spacing={{ xs: 2, md: 1 }}
        >
          {prices.map((pricePackage) => {
            const _priceDetails =
              (pricePackage?.price_details ?? '').length > 0
                ? pricePackage.price_details
                  .split(/\n• |• /)
                  .filter((item) => item !== '')
                : []

            return (
              <Grid
                theme={theme}
                item
                key={pricePackage.id}
                xs={12}
                md={6}
                lg={3}
              >
                <Box
                  theme={theme}
                  className="package-card"
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Box theme={theme} className="package-details" flexGrow={1}>
                    <Box
                      theme={theme}
                      className="pkg-img"
                      mb={{ xs: 2, md: 3 }}
                    >
                      <img src={pricePackage.icon_url} />
                    </Box>
                    <Box theme={theme} className="pkg-shout">
                      <Typography
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="text-h4"
                      >
                        {pricePackage.name}
                      </Typography>
                      <Typography
                        className="text-muted text-body2 prd-desc"
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                        mt={1}
                      >
                        {pricePackage.description}
                      </Typography>
                      <Typography
                        className="text-primary text-h4"
                        theme={theme}
                        variant="subtitle2"
                        component="span"
                        display={'block'}
                        mt={{ xs: 2, md: 3 }}
                      >
                        {pricePackage.amount === 0
                          ? 'Free'
                          : `$${pricePackage.amount}/Mo.`}
                      </Typography>
                    </Box>
                    <Box
                      theme={theme}
                      className="seperator"
                      mt={{ xs: 2, md: 3 }}
                      mb={{ xs: 2, md: 3 }}
                    ></Box>
                    {_priceDetails.length > 0
                      ? (
                      <Box theme={theme} className="pkg-options">
                        <>
                          <ul className="list">
                            {_priceDetails.map(
                              (detail: string, index: number) => (
                                <li key={index}>
                                  <Typography
                                    className="text-muted text-body2"
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                  >
                                    {detail}
                                  </Typography>
                                </li>
                              )
                            )}
                          </ul>
                          <Typography
                            className="text-muted"
                            theme={theme}
                            variant="caption"
                            component="small"
                            display={'block'}
                            mt={3}
                          >
                            *Prices without applicable taxes
                          </Typography>
                        </>
                      </Box>
                        )
                      : null}
                  </Box>
                  <Box theme={theme} className="package-action" mt={2}>
                    <Button
                      data-testid="add-to-cart-btn"
                      theme={theme}
                      fullWidth
                      secondary
                      text={
                        pricePackage.amount === 0
                          ? 'Start Free Trial'
                          : 'Add to Cart'
                      }
                    ></Button>
                  </Box>
                </Box>
              </Grid>
            )
          })}
          <Grid theme={theme} item xs={12}>
            <Box
              theme={theme}
              className="custom-plan-wrap"
              display={'flex'}
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              alignItems={'center'}
              mt={{ xs: 3, md: 5 }}
            >
              <Box theme={theme} className="plan-content" flexGrow={1}>
                <Typography
                  theme={theme}
                  variant="h4"
                  component="h4"
                  className="text-h4"
                >
                  Custom
                </Typography>
                <Typography
                  className="text-muted text-body2"
                  mt={1}
                  theme={theme}
                  variant="body2"
                  component="span"
                  display={'block'}
                >
                  Contact us today for a custom package designed specifically
                  for your needs.
                </Typography>
              </Box>
              <Box
                theme={theme}
                className="plan-cta"
                flexShrink={0}
                width={{ xs: '100%', md: 'auto' }}
                mt={{ xs: 2, md: 0 }}
              >
                <Button theme={theme} fullWidth secondary text={'Contact Us'} />
              </Box>
            </Box>
            <Box theme={theme} className="reach-us" mt={3} textAlign={'center'}>
              <Typography
                className="text-muted text-body2"
                mt={1}
                theme={theme}
                variant="body2"
                component="span"
                display={'block'}
              >
                Don’t see a plan that works for you?{' '}
                <a href="javascript:void(0)" className="link text-primary">
                  Reach us out
                </a>{' '}
                and we’ll work to find a solution for you.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ProductPackages>
  )
}
