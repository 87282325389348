import { useTheme } from '@mui/material'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import { type IFeature } from '@shared/typings'
import { type FC } from 'react'

interface ICol12TemplateProps {
  gridLayout: number
  className: string
  feature: IFeature
}

export const Col12Template: FC<ICol12TemplateProps> = ({
  className,
  gridLayout,
  feature
}) => {
  const theme = useTheme()

  return (
    <Grid theme={theme} item xs={12} md={gridLayout}>
      <Box theme={theme} className={`feat-item-box  ${className}`}>
        <Grid theme={theme} container item alignItems={'center'}>
          <Grid theme={theme} item xs={12} md={6}>
            <Box theme={theme} className="content-area">
              <Typography
                theme={theme}
                variant="h3"
                component="h3"
                className="text-h3"
              >
                {feature?.name}
              </Typography>
              <Typography
                theme={theme}
                className="text-muted text-body2"
                variant="body1"
                component="p"
                mt={1}
              >
                {feature?.description}
              </Typography>
            </Box>
          </Grid>
          <Grid theme={theme} item xs={12} md={6}>
            <Box theme={theme} className="img-area">
              <img src={feature?.image_url} alt="In-Life Modification" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
