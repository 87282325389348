import { type FC } from 'react'
import { type Theme } from '@mui/material'
import { Box, Tooltip } from '@ntpkunity/controls'
import { formatCurrency } from '@shared/helper'

interface IProductNameTooltipProps {
  theme: Theme
  productName: string
  packageName: string
  productPrice: number
}

export const ProductNameTooltip: FC<IProductNameTooltipProps> = ({
  theme,
  productName,
  packageName,
  productPrice
}) => (
  <Tooltip
    title={
      <>
        <Box theme={theme}>
          <Box theme={theme} display={'flex'}>
            <Box theme={theme} flexGrow={1} whiteSpace="normal">
              {packageName}
            </Box>
            <Box theme={theme} flexShrink={0} ml={3}>
              {formatCurrency(productPrice ?? 0)}
            </Box>
          </Box>
        </Box>
      </>
    }
    theme={theme}
  >
    <>{productName}</>
  </Tooltip>
)
