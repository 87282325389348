import { AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type IComment, IPostComment, ITrackCommentFilter } from '@shared/typings'
import { useMutation, useQuery, useQueryClient } from 'react-query'

const BASE_URL = process.env.BASE_URL
export const PRODUCT_COMMENTS =
  'marketplace/admin/product/fetch-product-comments'
const POST_PRODUCT_COMMENT = 'marketplace/admin/product/create-product-comment'

export const useGetComments = (
  pageNumber: number,
  pageSize: number,
  id: number,
  url: string
): any => {
  const respectiveUrl = `${url}/${id}?page_number=${pageNumber}&page_size=${pageSize}`
  return useMutation(async () => {
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.get<any>(respectiveUrl)
  })
}

export const useAddComment = (): any => {
  const queryClient = useQueryClient()

  const { data, mutate, error } = useMutation<IComment, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(POST_PRODUCT_COMMENT, body)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(PRODUCT_COMMENTS)
      }
    }
  )
  return { data, mutate, error: error?.message }
}
