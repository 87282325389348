import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Typography } from '@ntpkunity/controls'
import { AuthFooter } from './footerComponentStyle'

export const FooterComponent = ({}) => {
  const theme = useTheme()
  return (
    <AuthFooter theme={theme} className="auth-footer">
      <Typography
        theme={theme}
        variant="caption"
        component="small"
        display={'block'}
      >
        Support: 0-000-000-0000 |{' '}
        <a className="decoration-none" href="mailto:sample@sample.com">
          sample@sample.com
        </a>
      </Typography>
      <Typography
        theme={theme}
        variant="caption"
        component="small"
        display={'block'}
      >
        Weekdays 6am-6pm PST - Weekends 10am-6pm PST
      </Typography>
    </AuthFooter>
  )
}

FooterComponent.propTypes = {}

FooterComponent.defaultProps = {}
