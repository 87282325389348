import React, { type FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Box,
  Grid,
  Typography,
  LinearProgressBar,
  Container,
  Button,
  Icon
} from '@ntpkunity/controls'
import ApiDocsImage from '@shared/assets/images/sp-api-docs-img.svg'
import MarketingImage from '@shared/assets/images/sp-marketing-img.svg'
import { SupportWrap } from './SupportSectionStyle'
import { type ISupport } from '@shared/typings'
import { openInNextWindow } from '@shared/helper'
import { className } from '@shared/constants/googleAnalytics'

interface IProductSupport {
  support: ISupport
}
export const SupportSectionComponent: FC<IProductSupport> = ({ support }) => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="template-section-wrap">
      <SupportWrap
        theme={theme}
        className="support-wrap"
        pb={{ xs: 10, md: 15 }}
        pt={{ xs: 10, md: 15 }}
      >
        <Container theme={theme} className="custom-container" maxWidth={'lg'}>
          <Box
            theme={theme}
            className="section-head"
            textAlign={'center'}
            maxWidth={996}
            margin={'auto'}
          >
            <Typography
              display={'inline-block'}
              mb={{ xs: 2, md: 3 }}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              SUPPORT
            </Typography>
            <Typography
              theme={theme}
              variant="h2"
              component="h2"
              className="text-h2"
            >
              Have questions that need answers?
            </Typography>
            <Typography
              theme={theme}
              className="text-muted text-body2"
              variant="body1"
              component="p"
              mt={{ xs: 2, md: 3 }}
            >
              Appex Now is a tiny team. If you need some support for Appex Now,
              check the documentation first and then, if you don’t find what you
              need, include as much information as you can!
            </Typography>
            <Typography
              theme={theme}
              className="text-muted text-body2"
              variant="body1"
              component="p"
              mt={{ xs: 1, md: 2 }}
            >
              For any enquiries you can email us at{' '}
              <a
                className="text-primary link"
                href={`mailto:${process.env.SUPPORT_EMAIL}`}
              >
                {process.env.SUPPORT_EMAIL}
              </a>
            </Typography>
          </Box>
          <Box theme={theme} className="sec-content" mt={6}>
            <Grid
              theme={theme}
              container
              item
              spacing={3}
              alignItems="stretch"
              justifyContent={'center'}
            >
              <Grid theme={theme} item xs={12} md={6} lg={5}>
                <Box theme={theme} className="support-box api-docs">
                  <Typography
                    theme={theme}
                    variant="h3"
                    component="h3"
                    className="text-h3"
                  >
                    API Documentation
                  </Typography>
                  <Typography
                    theme={theme}
                    mt={1}
                    mb={2}
                    variant="body2"
                    component="span"
                    className="text-muted text-body2"
                    display={'block'}
                  >
                    Discover and learn how to connect and integrate with your
                    own tools or what you can get out of our documentation.
                  </Typography>
                  <Button
                    theme={theme}
                    defaultBtn
                    className={className.productDetailViewDocBtn}
                    text="View Documentation"
                    onClick={() =>
                      Boolean(support?.documentation_url) &&
                      openInNextWindow(
                        `${process.env.DOCUMENTATION}${
                          support?.documentation_url
                        }`
                      )
                    }
                    endIcon={<Icon name="IconLeftArrow" />}
                  ></Button>
                  <Box theme={theme} className="box-img" mt={5}>
                    <img src={ApiDocsImage} />
                  </Box>
                </Box>
              </Grid>
              <Grid item theme={theme} xs={12} md={6} lg={5}>
                <Box theme={theme} className="support-box">
                  <Typography
                    theme={theme}
                    variant="h3"
                    component="h3"
                    className="text-h3"
                  >
                    Marketing
                  </Typography>
                  <Typography
                    theme={theme}
                    mt={1}
                    mb={2}
                    variant="body2"
                    component="span"
                    className="text-muted text-body2"
                    display={'block'}
                  >
                    If you are a publication or want to feature this product
                    somewhere, here’s our PR pack.
                  </Typography>
                  <Button
                    theme={theme}
                    defaultBtn
                    text="Download PR Pack"
                    className={className.productDetailDownloadPRPackBtn}
                    onClick={() =>
                      Boolean(support?.pr_pack_url) &&
                      openInNextWindow(support?.pr_pack_url)
                    }
                    endIcon={<Icon name="SaveIcon" />}
                  ></Button>
                  <Box theme={theme} className="box-img" mt={5}>
                    <img src={MarketingImage} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SupportWrap>
    </Box>
  )
}

SupportSectionComponent.propTypes = {}

SupportSectionComponent.defaultProps = {}
