import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Dialog,
  Icon,
  Input,
  Typography
} from '@ntpkunity/controls'
import {} from './unpublishReasonDialogStyle'
import { DialogWrap } from '@shared/style/dialogStyle'
import { Controller, useForm } from 'react-hook-form'

export const UnpublishReasonDialogComponent = ({ cancelClicked, onSave }) => {
  const theme = useTheme()
  const formMethods = useForm({
    mode: 'all',
    defaultValues: {
      unPublishComment: ''
    }
  })

  const { handleSubmit, watch, control, reset } = formMethods

  const handleOnSave = (data, reset) => {
    onSave(data.unPublishComment, reset)
  }

  return (
    <DialogWrap theme={theme} className="dialog-wrap">
      <Dialog
        disablePortal={true}
        open
        theme={theme}
        variant="confirmation"
        size="xs"
        customFooter={
          <Box theme={theme} className="dialog-action-area">
            <form className="buttons" onSubmit={handleSubmit(handleOnSave)}>
              <>
                <Button
                  theme={theme}
                  secondary
                  text={'Cancel'}
                  onClick={() => {
                    cancelClicked()
                  }}
                ></Button>
                <Button
                  theme={theme}
                  danger
                  type="submit"
                  disabled={!watch('unPublishComment')}
                  text={'Save Changes'}
                ></Button>
              </>
            </form>
          </Box>
        }
      >
        <Box theme={theme} className="dialog-icon">
          <Icon name="DialogAlertIcon" />
        </Box>
        <Box theme={theme}>
          <Typography
            theme={theme}
            mb={2}
            className="content-title"
            variant="h2"
            component="h2"
          >
            Are you sure you want to unpublish?
          </Typography>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component="span"
          >
            Once unpublished, this product will be removed from the AppexNow.
            Please mention the reason below.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(handleOnSave)}>
          <Box theme={theme} mt={4}>
            <Controller
              name="unPublishComment"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  theme={theme}
                  fullWidth
                  type={'text'}
                  label="Reason"
                  placeholder="Sample reason text..."
                />
              )}
            />
          </Box>
        </form>
      </Dialog>
    </DialogWrap>
  )
}

UnpublishReasonDialogComponent.propTypes = {
  cancelClicked: PropTypes.func,
  saveClicked: PropTypes.func
}

UnpublishReasonDialogComponent.defaultProps = {}
