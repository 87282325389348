import { useReducer } from 'react'
import themeReducer, {
  initialState as themeInitialState,
  updateThemeConfig
} from './ducks/theme'
import storageReducer, {
  initialState as storageInitialState,
  updateLocalStorage,
  updateSessionStorage
} from './ducks/browserStorage'
import {
  type IToaster,
  type IUserProfile,
  type StoreContextType
} from './configs'
import toasterReducer, {
  setToaster,
  ToasterinitialState
} from './ducks/toaster'

import userProfileReducer, {
  initialState as userProfileInitialState,
  setUserProfile
} from './ducks/userProfile'

import cartReducer, {
  initialState as cartInitialState,
  addToCart,
  removeFromCart,
  updateCart,
  addMultipleItemsToCart
} from './ducks/cart'
import notificationsReducer, {
  initialNotificationState
} from './ducks/notifications'
import { useQueryClient } from 'react-query'

const useReducerActions = (): StoreContextType => {
  const queryClient = useQueryClient()

  const [theme, themeDispatch] = useReducer(themeReducer, themeInitialState)
  const [notificationState, notificationDispatch] = useReducer(
    notificationsReducer(queryClient),
    initialNotificationState
  )

  const [browserStorage, stroageDispatch] = useReducer(
    storageReducer,
    storageInitialState
  )

  // const [pusherChannel, channelDispatch] = useReducer(
  //   pusherReducer,
  //   PusherinitialState
  // );
  const [toaster, displayToasterDispatch] = useReducer(
    toasterReducer,
    ToasterinitialState
  )

  const [userProfile, userProfileDispatch] = useReducer(
    userProfileReducer,
    userProfileInitialState
  )
  const [cart, cartDispatch] = useReducer(cartReducer, cartInitialState)

  const actions = {
    setTheme: (data: 'default' | 'dark') => {
      updateThemeConfig(data, themeDispatch)
    },
    setLocalStorage: (data: string) => {
      updateLocalStorage(data, stroageDispatch)
    },
    setSessionStorage: (data: string) => {
      updateSessionStorage(data, stroageDispatch)
    },
    // setChannel: (data: string) => {
    //   createPusherChannel(data, channelDispatch);
    // },
    setToaster: (data: IToaster) => {
      setToaster(data, displayToasterDispatch)
    },
    setUserProfile: (data: IUserProfile) => {
      setUserProfile(data, userProfileDispatch)
    },
    // addToCart: (data: any) => {
    //   addToCart(data, cartDispatch)
    // },
    // removeFromCart: (data: any) => {
    //   removeFromCart(data, cartDispatch)
    // },
    // updateCart: (data: any) => {
    //   updateCart(data, cartDispatch)
    // },
    // addMultipleItemsToCart: (data: any) => {
    //   addMultipleItemsToCart(data, cartDispatch)
    // },
    notificationDispatch
  }

  return {
    actions,
    states: {
      theme,
      localStorage: browserStorage.localStorage,
      sessionStorage: browserStorage.sessionStorage,
      // pusherChannel,
      toaster,
      userProfile,
      // cart,
      notificationState
    }
  }
}

export default useReducerActions
