import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '../../../Layout/components'
import {
  PageHeaderComponent,
  ProductManagementTableComponent,
  UnpublishReasonDialogComponent
} from '../../components'
import {} from './productManagementStyle'
import { useUpdateProductStatus } from '@modules/ProductManagement/components/productManagementTable/ProductManagement TableService'
import { statusTask } from '@shared/constants'
import { useForm } from 'react-hook-form'

export const ProductManagementPage = ({}) => {
  const [, setPublished] = useState(true)
  const [open, setOpen] = useState<boolean>(false)
  const productIdRef = useRef<number>()
  const { mutate: updateProductStatus } = useUpdateProductStatus()

  const handleOnUnPublish = (unPublishComment, reset) => {
    setPublished(false)
    setOpen(false)
    updateProductStatus(
      {
        comments: unPublishComment,
        is_active: false,
        status_task: statusTask.UNPUBLISHED,
        productId: productIdRef.current
      },
      {
        onSuccess () {
          reset()
        }
      }
    )
  }

  return (
    <>
      <PageHeaderComponent />
      <ProductManagementTableComponent
        unpublished={(e, productId) => {
          setPublished(e)
          setOpen(!e)
          productIdRef.current = productId
          if (e) {
            updateProductStatus({
              is_active: e,
              productId
            })
          }
        }}
      />
      {open && (
        <UnpublishReasonDialogComponent
          onSave={handleOnUnPublish}
          cancelClicked={() => { setOpen(false) }}
        />
      )}
    </>
  )
}
