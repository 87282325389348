import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-table-wrap': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 333px)'
      },
      '.u-table': {
        '.u-table-head': {
          td: {
            overflow: 'visible'
          }
        }
      }
    },
    '.scroll-hide': {
      height: 97,
      '&.spr-border': {
        '&::after': {
          marginTop: 0
        }
      }
    }
  }
}))
