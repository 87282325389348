import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Button,
  Input,
  Select,
  PersistentDrawer,
  Grid
} from '@ntpkunity/controls'
import {} from './employeeFormStyle'

export const EmployeeFormComponent = ({}) => {
  const theme = useTheme()
  return (
    <PersistentDrawer
      title={'Add New User'}
      openPopUp={true}
      setPopUpState={undefined}
      theme={theme}
      customFooter={
        <Button
          theme={theme}
          primary
          type="submit"
          text="Save User"
          fullWidth
        />
      }
    >
      <form>
        <Grid theme={theme} item container spacing={2} mb={3}>
          <Grid theme={theme} item xs={6}>
            <Input
              theme={theme}
              fullWidth={true}
              label="First Name"
              type={'text'}
              placeholder="Type here..."
              startAdornment={''}
              endAdornment={''}
              id={''}
            />
          </Grid>
          <Grid theme={theme} item xs={6}>
            <Input
              theme={theme}
              fullWidth={true}
              label="Last Name"
              type={'text'}
              placeholder="Type here..."
              startAdornment={''}
              endAdornment={''}
              id={''}
            />
          </Grid>
        </Grid>
        <Input
          theme={theme}
          fullWidth={true}
          label="Email Address"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Contact Number"
          type={'text'}
          placeholder="000 000 0000"
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Role"
          sxProps={undefined}
        />
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Status"
          sxProps={undefined}
        />
      </form>
    </PersistentDrawer>
  )
}

EmployeeFormComponent.propTypes = {}

EmployeeFormComponent.defaultProps = {}
