import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Icon,
  Menu,
  Typography,
  PageHeader
} from '@ntpkunity/controls'
import {} from './pageTitleStyle'

export const PageTitleComponent = ({ addNewUser }) => {
  const theme = useTheme()
  return (
    <PageHeader
      variant="border"
      theme={theme}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            User Management
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button
            theme={theme}
            primary
            startIcon={<Icon name="AddIcon" />}
            text="Add New User"
            onClick={() => {
              addNewUser()
            }}
          ></Button>
          <Menu
            theme={theme}
            options={[{ optionText: 'Export CSV' }]}
            render={(cb) => (
              <Button
                theme={theme}
                secondary
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        </>
      }
    />
  )
}

PageTitleComponent.propTypes = {
  addNewUser: PropTypes.func
}

PageTitleComponent.defaultProps = {}
