import { useTheme } from '@mui/material'
import { Box, Container } from '@ntpkunity/controls'
import {
  ChangePasswordComponent,
  type IResponse
} from '@ntpkunity/controls-ums'
import { APP_ROUTES } from '@shared/configuration'
import { changePassword, HttpStatus } from '@shared/constants'
import { scrollOnTop } from '@shared/helper'
import { useEffect, useState } from 'react'
import { useStoreContext } from 'store/storeContext'
import { FullPageWrap } from './changePasswordStyle'
import { LayoutWithSideNavComponent } from '@modules/Layout/components'

export const ChangePassword = () => {
  const theme = useTheme()
  const [response, setResponse] = useState<IResponse>()
  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setToaster({
        isDisplay: true,
        message: changePassword,
        type: ''
      })
    } else if (response?.status === HttpStatus.Conflict) {
      setToaster({
        isDisplay: true,
        message: response?.message,
        type: 'error'
      })
    }
  }, [response])

  useEffect(() => {
    scrollOnTop()
  }, [])

  return (
    <>
      <FullPageWrap theme={theme} className="full-page-wrap">
        <ChangePasswordComponent
          theme={theme}
          setResponse={setResponse}
          next_screen_url={`${APP_ROUTES.AUTHENTICATION.PROFILE}`}
        />
      </FullPageWrap>
    </>
  )
}
