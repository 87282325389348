import { useTheme } from '@mui/material'
import { Box, Dialog, Typography, Icon, Button } from '@ntpkunity/controls'
import { DialogWrap } from '@shared/style/dialogStyle'
import { type IIconType } from '@shared/typings'
import { type FC } from 'react'

interface IConfirmationDialogProps {
  title: string
  message: string
  buttonText: string
  iconName: string
  btnClassName?: string
  onConfirm: () => void
  cancelButtonText?: string
  onCancel?: () => void
  onCloseDialog?: () => void
  danger?: boolean
}

export const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
  title,
  message,
  buttonText,
  iconName,
  btnClassName,
  onConfirm,
  cancelButtonText,
  onCancel,
  onCloseDialog,
  danger
}) => {
  const theme = useTheme()
  return (
    <DialogWrap theme={theme} className="dialog-wrap">
      <Dialog
        disablePortal={true}
        variant="confirmation"
        size="xs"
        open
        theme={theme}
        onCloseDialog={onCloseDialog}
        customFooter={
          <>
            {(cancelButtonText ?? '').length > 0
              ? (
              <Button
                theme={theme}
                className={btnClassName}
                type="submit"
                secondary
                fullWidth
                text={cancelButtonText}
                onClick={onCancel}
              ></Button>
                )
              : null}
            <Button
              theme={theme}
              className={btnClassName}
              type="submit"
              primary
              fullWidth
              danger={danger}
              text={buttonText}
              onClick={onConfirm}
            ></Button>
          </>
        }
      >
        <Box theme={theme} className="dialog-icon">
          <Icon name={iconName as IIconType} />
        </Box>
        <Box theme={theme}>
          <Typography
            theme={theme}
            mb={2}
            className="content-title"
            variant="h2"
            component="h2"
          >
            {title}
          </Typography>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component="span"
          >
            {message}
          </Typography>
        </Box>
      </Dialog>
    </DialogWrap>
  )
}
