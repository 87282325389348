import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.main-table-wrap': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 300px)'
      },
      ' .u-table': {
        '.u-table-head': {
          zIndex: 2
        },
        '.u-table-body': {
          tr: {
            '&.even-row': {
              td: {
                backgroundColor: theme.customVariables.tableStripedBg
              }
            },
            '&.odd-row': {
              td: {
                backgroundColor: theme.palette.common.white
              }
            }
          }
        },
        '.u-multi-table-wrap': {
          '.u-multi-table-container': {
            '.u-table-wrap': {
              '.u-table-container': {
                border: 'none',
                maxHeight: '100%',
                overflow: 'visible',
                '.u-table': {
                  '.u-table-head': {
                    zIndex: 1,
                    backgroundColor: theme.palette.common.white,
                    td: {
                      color: theme.palette.grey[900],
                      backgroundColor: theme.palette.common.white
                    },
                    '.thead-items-cell': {
                      td: {
                        borderTop: 'none'
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}))
