import { useState, useEffect, useRef } from 'react'

export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export const useDebouncedState = <T>(
  initialValue: T,
  delay = 500
): [T, React.Dispatch<React.SetStateAction<T>>, T] => {
  const [state, setState] = useState<T>(initialValue)
  const debouncedState = useDebounce<T>(state, delay)

  return [debouncedState, setState, state]
}

export const useEffectAfterInitialRender: typeof useEffect = (effect, deps) => {
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      return effect()
    }
  }, deps)
}
