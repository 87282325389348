import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const FileDragDropWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.file-drag-drop-wrap': {
    '.file-preview': {
      marginTop: 0
    },
    '.u-custom-file-upload': {
      marginBottom: 0
    },
    '.text-error': {
      color: theme.palette.error.main
    }
  }
}))
