import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { CounterWidget } from '@shared/components/CounterWidget/counterWidget'
import {} from './SubscriberCACStyle'

export const SubscriberCACComponent = ({}) => {
  const theme = useTheme()
  return (
    <CounterWidget
      theme={theme}
      title={'Subscriber CAC'}
      counterText={'48.592.00'}
      rankImage={true}
      rankImageUp
      rankText={'00%'}
      rankTextSuccess={true}
    />
  )
}

SubscriberCACComponent.propTypes = {}

SubscriberCACComponent.defaultProps = {}
