import {
  type IAWSFileUploadData,
  type IBackendFormData,
  type IFormData
} from '@shared/typings'
import Http from '@shared/helper/http-api'
import {
  type UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { type AppError } from '@shared/helper'
import {
  type UseFormReturn,
  type UseFormSetValue,
  type UseFormTrigger,
  useFormContext
} from 'react-hook-form'
import {
  createProductWithErrorMessage,
  createProductWithNoErrorMessage,
  liveProductSuccessMessage
} from '@shared/constants'
import { useContext } from 'react'
import { StoreContext } from 'store/storeContext'

const BASE_URL = process.env.BASE_URL
const CREATE_PRODUCT = 'marketplace/admin/product/create-product'
export const GET_PRODUCT = 'marketplace/admin/product/product-details'
const UPDATE_PRODUCT = 'marketplace/admin/product/update-product'
const UPLOAD_IMAGE = 'marketplace/admin/product/image-upload'
const DELETE_FILE = 'marketplace/admin/product/delete-file'
const BULK_DELETE_FILE = 'marketplace/admin/product/bulk-file-delete'
const FETCH_PRODUCTS = 'marketplace/admin/product/get-all-products'

interface IUpdateFormData {
  data: IFormData
  productId: string
}

export const useGetProduct = (
  productId: string
): {
  data: IFormData | undefined
  isLoading: boolean
  error: string
} => {
  const { data, error, isLoading } = useQuery<IFormData | undefined, AppError>(
    [GET_PRODUCT, +productId],
    async () => {
      const url = `${GET_PRODUCT}/${productId}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false, enabled: !!productId, staleTime: 3000 }
  )
  return { data, isLoading, error: error?.message }
}

export const useUpdateProduct = (): {
  data: IFormData | undefined
  isLoading: boolean
  mutate: UseMutateFunction<IBackendFormData, AppError, IUpdateFormData>
  error: string
} => {
  const queryClient = useQueryClient()
  const {
    actions: { setToaster }
  } = useContext(StoreContext)
  const {
    formState: { isValid }
  } = useFormContext()
  const { data, isLoading, mutate, error } = useMutation<
  IBackendFormData,
  AppError,
  IUpdateFormData
  >(
    async (body) => {
      const { data, productId } = body
      const url = `${UPDATE_PRODUCT}/${productId}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(url, data)
    },
    {
      onSuccess (data) {
        void queryClient.invalidateQueries(FETCH_PRODUCTS)
        setToaster({
          isDisplay: true,
          message: data.introduction.is_active
            ? liveProductSuccessMessage
            : isValid
              ? createProductWithNoErrorMessage
              : createProductWithErrorMessage,
          type: '',
          showIcon: true
        })
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useCreateProduct = (): {
  data: IFormData | undefined
  isLoading: boolean
  mutate: UseMutateFunction<IBackendFormData, AppError, IFormData>
  error: string
} => {
  const queryClient = useQueryClient()
  const {
    actions: { setToaster }
  } = useContext(StoreContext)
  const {
    formState: { isValid }
  } = useFormContext()
  const { data, isLoading, mutate, error } = useMutation<
  IBackendFormData,
  AppError,
  IFormData
  >(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(CREATE_PRODUCT, body)
    },
    {
      onSuccess (data) {
        void queryClient.invalidateQueries(FETCH_PRODUCTS)
        setToaster({
          isDisplay: true,
          message: data.introduction.is_active
            ? liveProductSuccessMessage
            : isValid
              ? createProductWithNoErrorMessage
              : createProductWithErrorMessage,
          type: '',
          showIcon: true
        })
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useUploadFile = (
  setValue: UseFormSetValue<IFormData>,
  trigger: UseFormTrigger<IFormData>
): {
  data: IAWSFileUploadData | undefined
  isLoading: boolean
  mutate: UseMutateFunction<IAWSFileUploadData, AppError, any, unknown>
  error: string
} => {
  let formFieldId
  let metadataId
  let metadata

  const { data, isLoading, mutate, error } = useMutation<
  IAWSFileUploadData,
  AppError,
  any
  >(
    async (body) => {
      formFieldId = body.formFieldId
      metadataId = body.metadataId
      metadata = body.metadata

      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(UPLOAD_IMAGE, body.files)
    },
    {
      onSuccess (data: IAWSFileUploadData) {
        setValue(formFieldId, data.Location, { shouldDirty: true })
        trigger(formFieldId)
        setValue(metadataId, metadata)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useDeleteFile = (): {
  data: any
  isLoading: boolean
  mutate: UseMutateFunction<any, AppError, string, unknown>
  error: string
} => {
  const { data, isLoading, mutate, error } = useMutation<any, AppError, string>(
    async (key) => {
      const url = `${DELETE_FILE}/${key}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.delete<any>(url)
    },
    { retry: 5 }
  )
  return { data, isLoading, mutate, error: error?.message }
}

export const useBulkDeleteFiles = (): {
  data: any
  isLoading: boolean
  mutate: UseMutateFunction<any, AppError, { keys: string[] }, unknown>
  error: string
} => {
  const { data, isLoading, mutate, error } = useMutation<
  any,
  AppError,
  { keys: string[] }
  >(async (body) => {
    const url = `${BULK_DELETE_FILE}`
    const apiService = Http.createConnection({
      baseUrl: BASE_URL,
      withAuthentication: true
    })
    return await apiService.post<any>(url, body)
  })
  return { data, isLoading, mutate, error: error?.message }
}
