import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const AuthLayout = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.auth-layout': {
    display: 'flex',
    alignItems: 'stretch',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    '.branding-area': {
      maxWidth: 348,
      width: '100%',
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '.main-content-section': {
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        marginLeft: 24
      }
    },
    '.content-area': {
      maxWidth: 520,
      margin: 'auto',
      height: 'calc(100vh - 24px)',
      paddingTop: 48,
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        maxWidth: 'calc(100% - 70px)'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 48px)'
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedEnd': {
          svg: {
            path: {
              stroke: theme.palette.grey[300]
            }
          }
        }
      }
    },
    '.decoration-none': {
      textDecoration: 'none'
    },
    '.fp-link': {
      a: {
        color: 'rgba(0,0,0,0.6)',
        paddingLeft: 15
      }
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.back-btn': {
      '.btn': {
        '&.btn-default': {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[600],
            '&:focus': {
              color: theme.palette.grey[600]
            }
          },
          '&:focus': {
            color: theme.palette.grey[600]
          }
        }
      }
    }
  }
}))
