export const invalidCredentials = 'Username or password is not valid'
export const userDisabled =
  'Your account has been blocked. Please contact your administrator!'
export const changePassword = 'Password updated successfully!'
export const errorTitle = 'Link Expired!'
export const expiredResetLinkSubTitle =
  'This reset password link has been expired. Please click the button below to regenerate the link.'
export const expiredResetLinkButtonText = 'Send Reset Link'
export const resetPasswordEmailSent =
  'Password reset link has been sent successfully!'
export const verificationEmailSent = 'Verification email has been sent'
export const forgotPasswordErrorMessage = "This email address doesn't exist."
export const networkError = "User doesn't exist."
export const howToConnectExcessMsg = 'You can add upto 5 steps only'
export const createProductSuccessMessage = 'Data has been saved successfully!'
export const deleteConfirmationMessage =
  'Once deleted, you cannot undo this action.'
export const leavePageAlertTitle = 'Do you want to leave this page?'
export const leavePageMessage =
  'You have unsaved changes. Would you like to save them before exiting?'
export const createProductWithNoErrorMessage =
  'Product has been saved in draft state!'
export const createProductWithErrorMessage =
  'Product has been saved in draft state as mandatory information is missing'
export const liveProductSuccessMessage =
  'Product has been updated successfully!'
export const commentLimitMessage = 'Maximum 500 characters are allowed'
export const mandatoryProductName =
  'We are unable to save your changes unless you name the product.'
export const draftProductToolTipMessage =
  'Please fill in the mandatory information'
export const packageLimitMessage = ' You can add upto 4 packages only​'
export const paymentMethodApprove =
  'Once approved, you cannot undo this action.'
export const paymentMethodDecline =
  'Once declined, you cannot undo this action.'
export const publishMessage =
  'Once published, the blog will be visible on AppexNow.'
