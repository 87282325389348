import { type IPusherChannel } from '../configs'
import type IActions from '../configs'
import { useEffect, useRef, type Dispatch, type ReducerAction } from 'react'
import Pusher, { type Channel } from 'pusher-js'

export const CREATE_USER_CHANNEL = 'CREATE_USER_CHANNEL'

export const pusher = new Pusher(process.env.PUSHER_APP_KEY ?? '', {
  cluster: process.env.PUSHER_APP_CLUSTER
})

export const usePusher = (
  channelName,
  eventName,
  callback,
  pusherInstance = pusher
): Pusher | null => {
  const channelRef = useRef<Channel | null>(null)

  useEffect(() => {
    const channel = pusherInstance.subscribe(channelName)
    channelRef.current = channel

    channel.bind(eventName, callback)

    return () => {
      if (channelRef.current) {
        channelRef.current.unbind(eventName)
        pusherInstance.unsubscribe(channelName)
      }
    }
  }, [channelName, eventName, callback, pusherInstance])

  return pusherInstance
}

export const PusherinitialState: IPusherChannel = {
  userEmail: '',
  pusherChannel: {} as any
}

const reducer = (state: IPusherChannel, action: IActions): IPusherChannel => {
  switch (action.type) {
    case CREATE_USER_CHANNEL:
      return {
        ...state,
        userEmail: action.payload as string,
        pusherChannel: pusher.subscribe(action.payload as string)
      }
  }
  return state
}

export const createPusherChannel = (
  data: string,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  dispatch({ type: CREATE_USER_CHANNEL, payload: data })
}

export default reducer
