import { Routes, Route, useLocation } from 'react-router-dom'
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ResetPasswordLink,
  EmailLinkExpired,
  ChangePassword
} from '@modules/Authentication/pages'
import { PackageManagementPage } from '@modules/PackageManagement/pages'
import { ProductManagementPage } from '@modules/ProductManagement/pages'
import { UserManagementPage } from '@modules/UserManagement/pages'
import { APP_ROUTES, tabName } from '@shared/configuration'
import { ProtectedComponent as Protected } from '../protected/protectedComponent'
import { DashboardLayoutPage } from '@modules/Dashboard/pages'
import { ProductConfigurationsPage } from '@modules/ProductConfigurations/pages'
import { useEffect } from 'react'
import { PreviewTemplatesPage } from '@modules/PreviewTemplates/pages'
import { setFavicon } from 'favicon'
import { WorkQueuePage } from '@modules/WorkQueue/pages'
import { BlogListing } from '@modules/Blog/pages/List/blogListing'

export const NavigationComponent = ({}) => {
  const { pathname } = useLocation()
  setFavicon()
  useEffect(() => {
    document.title = tabName[pathname]
  }, [pathname])

  return (
    <Routes>
      <Route path={APP_ROUTES.AUTHENTICATION.LOGIN} element={<LoginPage />} />
      <Route
        path={APP_ROUTES.AUTHENTICATION.RESEND_PASSWORD_LINK}
        element={<ResetPasswordLink />}
      />
      <Route
        path={APP_ROUTES.AUTHENTICATION.EMAIL_LINK_EXPIRED}
        element={<EmailLinkExpired />}
      />
      <Route
        path={APP_ROUTES.AUTHENTICATION.FORGOT_PASSWORD}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={APP_ROUTES.AUTHENTICATION.RESET_PASSWORD}
        element={<ResetPasswordPage />}
      />
      <Route
        path={APP_ROUTES.PREVIEW.PREVIEW_PAGE}
        element={
          <Protected>
            <PreviewTemplatesPage />
          </Protected>
        }
      />

      <Route element={<Protected />}>
        <Route
          path={APP_ROUTES.AUTHENTICATION.PROFILE}
          element={<ChangePassword />}
        />
        <Route
          path={APP_ROUTES.PRODUCT_MANAGEMENT.PRODUCT_LIST}
          element={<ProductManagementPage />}
        />
        <Route
          path={APP_ROUTES.PRODUCT_CONFIGURATION.PRODUCT_TABS}
          element={<ProductConfigurationsPage />}
        />
        <Route
          path={APP_ROUTES.PACKAGE_MANAGEMENT.PACKAGE_LIST}
          element={<PackageManagementPage />}
        />
        <Route
          path={APP_ROUTES.USER_MANAGEMENT.USER_LIST}
          element={<UserManagementPage />}
        />
        <Route
          path={APP_ROUTES.DASHBOARD.DASHBOARD_PAGE}
          element={<DashboardLayoutPage />}
        />
        <Route
          path={APP_ROUTES.WORK_QUEUE.WORK_QUEUE_LIST}
          element={<WorkQueuePage />}
        />
        <Route path={APP_ROUTES.BLOG.BLOG_LIST} element={<BlogListing />} />
      </Route>

      <Route path="*" element={<LoginPage />} />
    </Routes>
  )
}
