import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ProductFeaturesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-features-wrap': {
    '.section-head': {
      '.badge': {
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        padding: '8px 16px',
        borderRadius: 24,
        fontWeight: theme.customVariables.fontWeightSemiBold,
        letterSpacing: '0.5px'
      }
    },
    '.feat-item-box': {
      backgroundColor: '#F5F5F7',
      borderRadius: 16,
      border: '2px solid #F5F5F7',
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        borderWidth: 1
      },
      '.content-area': {
        padding: 80,
        [theme.breakpoints.down('md')]: {
          padding: 32
        }
      },
      '&.text-first': {
        '.content-area': {
          padding: '80px 80px 40px',
          [theme.breakpoints.down('md')]: {
            padding: 32
          }
        }
      },

      '&.text-last': {
        display: 'flex',
        flexDirection: 'Column',
        '.content-area': {
          padding: '40px 80px 80px',
          order: 2,
          [theme.breakpoints.down('md')]: {
            padding: 32
          }
        }
      },
      '&.text-right': {
        '.MuiGrid-item': {
          '&:first-child': {
            order: 2
          }
        }
      },
      '.img-area': {
        maxHeight: 368,
        overflow: 'hidden',
        textAlign: 'center',
        img: {
          maxHeight: 368,
          maxWidth: '100%'
        }
      }
    }
  }
}))
