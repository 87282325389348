import { TableCell, TableRow, useTheme, TablePagination } from '@mui/material'
import {
  Box,
  Button,
  DataTable,
  Dialog,
  Icon,
  Input,
  Tooltip
} from '@ntpkunity/controls'
import { TableWrap } from './UsersDialogStyle'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import { DialogWrap } from '@shared/style/dialogStyle'
import { useEffect, useState, type FC } from 'react'
import {
  useEffectAfterInitialRender,
  useDebouncedState
} from '@shared/helper/hooks'
import ChevronDown from 'shared/assets/images/chevron-down'
import { type IItem } from '@shared/typings'
import { Status } from '@shared/constants'
interface IUserDialogProps {
  item: IItem
  onCloseDialog: () => void
}
interface IDebouncedState {
  name: string
  email: string
  role: string
  status: string
}

const initialState: IDebouncedState = {
  name: '',
  email: '',
  role: '',
  status: ''
}

const rowsPerPageOptions = [10, 25, 50, 100, 200]

export const UsersDialogComponent: FC<IUserDialogProps> = ({
  item,
  onCloseDialog
}) => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])
  const [users, setUsers] = useState(item?.users ?? [])
  useEffect(() => { setUsers(item?.users ?? []) }, [item])

  const isPopUpOpenedForProduct = !!item?.productName ?? false

  const [debouncedState, setState, state] = useDebouncedState(initialState, 0)
  const { name, email, role, status } = state

  useEffectAfterInitialRender(() => {
    handleFilter()
  }, [debouncedState])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const filterByName = (users) => {
    return (users ?? []).filter((user) =>
      (isPopUpOpenedForProduct
        ? user.product_user_name
        : user.sub_user_full_name
      )
        ?.toLowerCase()
        ?.includes(name?.toLowerCase())
    )
  }

  const filterByEmail = (users) => {
    return (users ?? []).filter((user) =>
      (isPopUpOpenedForProduct ? user.email : user.sub_user_email)
        ?.toLowerCase()
        ?.includes(email?.toLowerCase())
    )
  }

  const filterByRole = (users) => {
    return (users ?? []).filter((user) =>
      (isPopUpOpenedForProduct ? user.role : user.sub_user_roles)
        ?.toLowerCase()
        ?.includes(role?.toLowerCase())
    )
  }

  const filterByStatus = (users) => {
    if (status?.trim()?.length === 0) {
      return users
    }
    return (users ?? []).filter((user) => {
      if (
        Status.IN_ACTIVE.toLowerCase().startsWith(
          status
            .toLowerCase()
            .slice(0, Math.max(Status.IN_ACTIVE.length - 1, 1))
        )
      ) {
        return isPopUpOpenedForProduct ? !user?.user_status : !user?.is_active
      } else if (
        Status.ACTIVE.toLowerCase().startsWith(
          status.toLowerCase().slice(0, Math.max(Status.ACTIVE.length - 1, 1))
        )
      ) {
        return isPopUpOpenedForProduct ? user?.user_status : user?.is_active
      }
    })
  }

  const handleFilter = () => {
    let filteredUsers = [...(item?.users ?? [])]
    filteredUsers = filterByName(filteredUsers)
    filteredUsers = filterByEmail(filteredUsers)
    filteredUsers = filterByRole(filteredUsers)
    filteredUsers = filterByStatus(filteredUsers)

    setUsers(filteredUsers)
    setPage(0)
  }
  return (
    <DialogWrap className="dialog-wrap" theme={theme}>
      <Dialog
        theme={theme}
        disablePortal={true}
        onCloseDialog={onCloseDialog}
        noFooter
        size="lg"
        open
        title={
          isPopUpOpenedForProduct
            ? `${item?.companyName} / ${item?.productName}`
            : `${item?.companyName}`
        }
      >
        <DataTableWrap theme={theme} className="data-table-wrap">
          <TableWrap theme={theme} className="main-table-wrap">
            <span className="scroll-hide spr-border"></span>
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow>
                    <TableCell>First/Last Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                  <TableRow className="filters-row">
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          type={''}
                          id={''}
                          placeholder="Search..."
                          value={name}
                          onChange={(e) => { setState({ ...state, name: e.trimStart() }) }
                          }
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          type={''}
                          id={''}
                          placeholder="Search..."
                          value={email}
                          onChange={(e) => { setState({ ...state, email: e.trimStart() }) }
                          }
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          type={''}
                          id={''}
                          placeholder="Search..."
                          value={role}
                          onChange={(e) => { setState({ ...state, role: e.trimStart() }) }
                          }
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          type={''}
                          id={''}
                          placeholder="Search..."
                          value={status}
                          onChange={(e) => { setState({ ...state, status: e.trimStart() }) }
                          }
                        ></Input>
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {isPopUpOpenedForProduct
                              ? user?.product_user_name
                              : user?.sub_user_full_name}
                          </TableCell>
                          <TableCell className="email-cell">
                            <Box
                              theme={theme}
                              className="email-cell-wrap"
                              display="flex"
                              alignItems="center"
                            >
                              <Tooltip
                                title={
                                  user?.is_verified_email
                                    ? 'Verified'
                                    : 'Not Verified'
                                }
                                theme={theme}
                                disablePortal={false}
                              >
                                <>
                                  <span className="icon-element">
                                    <Icon
                                      name={
                                        user?.is_verified_email
                                          ? 'GreenCheckCircleIcon'
                                          : 'NotVerifiedIcon'
                                      }
                                    />
                                  </span>
                                </>
                              </Tooltip>
                              <Tooltip
                                title={
                                  isPopUpOpenedForProduct
                                    ? user?.email
                                    : user?.sub_user_email
                                }
                                theme={theme}
                                disablePortal={false}
                              >
                                <Box theme={theme} className="text-overflow">
                                  {isPopUpOpenedForProduct
                                    ? user?.email
                                    : user?.sub_user_email}
                                </Box>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell className="product-name">
                            <Tooltip
                              title={
                                isPopUpOpenedForProduct
                                  ? user.role
                                  : user?.sub_user_roles
                              }
                              theme={theme}
                              disablePortal={false}
                            >
                              {isPopUpOpenedForProduct
                                ? user.role
                                : user?.sub_user_roles}
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <span
                              className={`status-indicator ${
                                (
                                  isPopUpOpenedForProduct
                                    ? user?.user_status
                                    : user?.is_active
                                )
                                  ? 'ind-primary'
                                  : 'ind-danger'
                              }`}
                            ></span>
                            {(
                              isPopUpOpenedForProduct
                                ? user?.user_status
                                : user?.is_active
                            )
                              ? Status.ACTIVE
                              : Status.IN_ACTIVE}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </>
              }
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                IconComponent: ChevronDown
              }}
            />
          </TableWrap>
        </DataTableWrap>
      </Dialog>
    </DialogWrap>
  )
}
