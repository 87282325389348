import { Navigate, Outlet } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'
import { loggedIn } from '@shared/helper'
import { LayoutWithSideNavComponent } from '@modules/Layout/components'
import { useTheme } from '@mui/material'
import { type FC, type ReactElement } from 'react'

export interface IProtectedComponentProps {
  children?: ReactElement
}

export const ProtectedComponent: FC<IProtectedComponentProps> = ({
  children
}): ReactElement => {
  const theme = useTheme()

  const isLoggedIn = loggedIn()
  if (!isLoggedIn) {
    return <Navigate to={APP_ROUTES.AUTHENTICATION.LOGIN} replace />
  }
  return children || (
    <LayoutWithSideNavComponent theme={theme}>
      <Outlet />
    </LayoutWithSideNavComponent>
  )
}
