import { useTheme } from '@mui/material'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import DraggableAccordionCommponent from '@shared/components/DraggableAccordion/DraggableAccordionCommponent'
import {
  ButtonType,
  DialogHeading,
  IconType,
  deleteConfirmationMessage
} from '@shared/constants'
import { featureDefaultValues } from '@shared/constants/productConfigurations'
import { S3FileKey } from '@shared/helper'
import { ContentAreaWrap } from '@shared/style/contentStyle'
import { type IFormData } from '@shared/typings'
import { useEffect, useState, type FC } from 'react'
import { type UseFormReturn, useFieldArray, useFormContext } from 'react-hook-form'
import { useModal } from 'react-modal-hook'
import { Feature } from './Feature'
import { PanelWrap } from './FeaturesStyle'
interface IFeaturesComponentProps {
  filesToBeDeleted: string[]
}

export const FeaturesComponent: FC<IFeaturesComponentProps> = ({
  filesToBeDeleted
}) => {
  const theme = useTheme()

  const [selectedElement, setSelectedElement] = useState<number>()
  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext()
  const { fields, append, move, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'features' // unique name for your Field Array
  })
  const [expanded, setExpanded] = useState<string | false>(
    `panel-${fields.length - 1}`
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const [show, hide] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DELETE}
        iconName={IconType.ALERT}
        title={DialogHeading.DELETE}
        message={deleteConfirmationMessage}
        onConfirm={() => {
          deleteFile(`features.${selectedElement}.image_url`)
          remove(selectedElement)
          hide()
        }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={hide}
        onCloseDialog={hide}
        danger
      />
    ),
    [selectedElement, remove]
  )

  useEffect(() => {
    setExpanded(`panel-${fields.length - 1}`)
  }, [fields.length])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const moveFeatureListItem = (dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex)
    setValue('makeFormDirty', false, { shouldDirty: true })
  }

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <PanelWrap theme={theme} className="accordion-panels-wrap">
        {fields.map((field, index: number) => (
          <DraggableAccordionCommponent
            key={field.id}
            index={index}
            theme={theme}
            varient="grouped"
            shouldCollapse={true}
            items={{
              content: (
                <>
                  <Feature
                    key={field.id}
                    id={field.id}
                    index={index}
                    filesToBeDeleted={filesToBeDeleted}
                  />
                </>
              ),
              title: (
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component={'p'}
                  className="fw-sbold text-body1"
                >
                  {getValues(`features.${index}.name`)}
                </Typography>
              ),
              key: field.id,
              isExpanded: expanded === `panel-${index}`,
              handleChange,
              moveListItem: moveFeatureListItem,
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={
                      <Icon
                        name="DeleteIcon"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          setSelectedElement(index)
                          show()
                          e.stopPropagation()
                        }}
                      />
                    }
                  />
                </>
              )
            }}
          />
        ))}
      </PanelWrap>
      <Box theme={theme} mt={3}>
        <Button
          fullWidth
          theme={theme}
          secondary
          text="Add New Feature"
          onClick={() => { append(featureDefaultValues) }}
        />
      </Box>
    </ContentAreaWrap>
  )
}
