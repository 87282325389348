import axios from 'axios'
import {
  errorStatusCodes,
  ACCESS_TOKEN_KEY,
  networkError
} from '@shared/constants'
import { AppError } from './errors'
import { LocalStorage } from '@shared/configuration'

interface Setting {
  baseUrl?: string
  withAuthentication?: boolean
}

class Http {
  _localStorageService: LocalStorage
  _baseUrl: string
  _token: string | null
  constructor (settings?: Setting) {
    this._localStorageService = new LocalStorage()
    this._baseUrl = settings?.baseUrl ?? process.env.API_GATEWAY ?? ''
    this._token =
      settings?.withAuthentication
        ? `Bearer ${
            this._localStorageService.getItemByKey(ACCESS_TOKEN_KEY) as string
          }`
        : null
  }

  static createConnection (settings?: Setting): Http {
    return new Http(settings)
  }

  public async get<T>(url: string, params?: any, options?: any): Promise<T> {
    const response = axios.get<T>(`${this._baseUrl}/${url}`, {
      headers: {
        Authorization: this._token ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          errorStatusCodes.includes(status) ? message : networkError,
          error.code,
          error.response.status,
          { params, options, error }
        )
      })
  }

  public getPaginated (url: string, params?: any, options?: any): any {
    try {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        'NOT_IMPLEMENTED'
      )
    } catch (error) {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { params, options }
      )
    }
  }

  public async post<T>(url: string, body?: any, options?: any): Promise<T> {
    const response = axios.post<T>(`${this._baseUrl}/${url}`, body, {
      headers: {
        Authorization: this._token ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp.data
      })
      .catch((error) => {
        const { data, status } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          errorStatusCodes.includes(status) ? data?.message : error.message,
          error.code,
          error.response.status,
          { body, options }
        )
      })
  }

  public async put<T>(url: string, body?: any, options?: any): Promise<T> {
    const response = axios.put<T>(`${this._baseUrl}/${url}`, body, {
      headers: {
        Authorization: this._token ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          errorStatusCodes.includes(status) ? message : networkError,
          error.code,
          error.response.status,
          { body, options }
        )
      })
  }

  public async patch<T>(url: string, body?: any, options?: any): Promise<T> {
    const response = axios.patch<T>(`${this._baseUrl}/${url}`, body, {
      headers: {
        Authorization: this._token ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          errorStatusCodes.includes(status) ? message : networkError,
          error.code,
          error.response.status,
          { body, options }
        )
      })
  }

  public async delete<T>(url: string, params?: any, options?: any): Promise<T> {
    const response = axios.delete<T>(`${this._baseUrl}/${url}`, {
      headers: {
        Authorization: this._token ?? ''
      }
    })
    return await response
      .then((resp) => {
        return resp.data
      })
      .catch((error) => {
        const {
          data: {
            data: { message }
          },
          status
        } = error.response
        throw new AppError(
          `Http.post(${this._baseUrl}/${url})`,
          errorStatusCodes.includes(status) ? message : networkError,
          error.code,
          error.response.status,
          { params, options }
        )
      })
  }
}
export default Http
