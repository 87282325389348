import { type AppError, formatedDate } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type ISubscriberRetention } from '@shared/typings'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL
const SUBSCRIBER_RETENTION = 'marketplace/admin/dashboard/subscriber-retention'
export const useGetSubscriberRetention = (
  startDate: Date,
  endDate: Date
): {
  data: ISubscriberRetention[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const STARTDATE = formatedDate(startDate, 'YYYY-MM-DD')
  const ENDDATE = formatedDate(endDate, 'YYYY-MM-DD')
  let url = `${SUBSCRIBER_RETENTION}`
  if (STARTDATE.length > 0 && ENDDATE.length > 0) {
    url += `?start_date=${STARTDATE}&end_date=${ENDDATE}`
  }
  const { data, error, isLoading } = useQuery(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}
