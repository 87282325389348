import * as React from 'react'
import { type IPageHeaderProps } from './pageHeader.types'
import clsx from 'clsx'
import { Box, Icon, Typography } from '@ntpkunity/controls'
import { PageHeaderWrap } from './pageHeaderStyle'
import { IconButton } from '@mui/material'

export const CustomPageHeader: React.FC<IPageHeaderProps> = ({
  title,
  counterText,
  captionText,
  actionArea,
  theme,
  variant,
  onBackBtn
}) => {
  return (
    <>
      <PageHeaderWrap
        theme={theme}
        display={'flex'}
        className={clsx({
          'page-header': true,
          'border-bottom': variant === 'border'
        })}
      >
        <Box theme={theme} className="title-wrap">
          {onBackBtn != null && (
            <IconButton
              className="back-btn"
              aria-label="back"
              onClick={onBackBtn}
            >
              <Icon name="IconLeftArrow" />
            </IconButton>
          )}
          {title}
          {counterText && (
            <Typography
              theme={theme}
              variant="subtitle1"
              component="p"
              className="counter"
            >
              {counterText}
            </Typography>
          )}
          {captionText && (
            <Typography
              theme={theme}
              variant="body2"
              component="span"
              className="caption"
            >
              {captionText}
            </Typography>
          )}
        </Box>
        <Box
          theme={theme}
          className="action-area"
          display={'flex'}
          alignItems={'center'}
        >
          {actionArea}
        </Box>
      </PageHeaderWrap>
    </>
  )
}
