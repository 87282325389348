import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import LOGIN_IMAGE from '@assets/images/login-img.jpg'
const BrandingImg = `url(${LOGIN_IMAGE})`

export const BrandingImage = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.branding-img': {
    backgroundImage: BrandingImg,
    height: 'calc(100% - 48px)',
    position: 'fixed',
    left: 24,
    top: 24,
    width: 348,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderRadius: 16,
    backgroundSize: 'cover'
  }
}))
