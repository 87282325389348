import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import UNITY_LOGO from '@assets/images/unity-logo.svg'

export const LogoComponent = ({}) => {
  return (
    <Box theme={useTheme()} className="logo-area">
      <img src={UNITY_LOGO} alt="Unity" />
    </Box>
  )
}

LogoComponent.propTypes = {}

LogoComponent.defaultProps = {}
