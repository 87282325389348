const LOCAL_STORAGE_ITEM_KEY = 'mp-console'

export class LocalStorage {
  getItemByKey (key: string) {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY))
    if (data) return data[key]
    return null
  }

  setItembyKey (key: string, value: any) {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY))
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      data
        ? JSON.stringify({ ...data, [key]: value })
        : JSON.stringify({ [key]: value })
    )
  }

  removeItembyKey (key: string) {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY))
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      JSON.parse({ ...data, [key]: undefined })
    )
  }

  clearStorage () {
    localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY)
  }
}

const _localStorageService: LocalStorage = new LocalStorage()
export const getItem = (key: string) => _localStorageService.getItemByKey(key)
export const setItem = (key: string, value: any) => { _localStorageService.setItembyKey(key, value) }
export const clearLocalStorage = () => { _localStorageService.clearStorage() }
export const PRODUCT_PRICING_ID = 'product_pricing_id'
