import React, { type FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Container, Box, Grid, Typography } from '@ntpkunity/controls'
import FeatImageOne from '@shared/assets/images/flex-feat-initiation.png'
import FeatImageTwo from '@shared/assets/images/flex-feature-contract-booking.svg'
import FeatImageThree from '@shared/assets/images/flex-feat-in-life-modification.svg'
import FeatImageFour from '@shared/assets/images/flex-feature-early-termination.svg'
import FeatImageFive from '@shared/assets/images/flex-feature-end-of-term.svg'
import { ProductFeaturesWrap } from './FeatureSectionStyle'
import { type IFeature } from '@shared/typings'
import { Col6Template } from './Col6Template'
import { Col12Template } from './Col12Template'
import { gridLayoutFormat } from '@shared/constants'
import { splitFeatureVariant } from '@shared/helper'

interface IProductFeature {
  features: IFeature[]
}
export const FeatureSectionComponent: FC<IProductFeature> = ({ features }) => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="template-section-wrap">
      <Container theme={theme} maxWidth="lg" className="custom-container">
        <ProductFeaturesWrap theme={theme} className="product-features-wrap">
          <Box theme={theme} className="section-head" textAlign={'center'}>
            <Typography
              display={'inline-block'}
              mb={{ xs: 2, md: 3 }}
              theme={theme}
              className="badge text-primary text-uppercase"
              variant="caption"
              component="small"
            >
              PRODUCT FEATURES
            </Typography>
            <Typography
              theme={theme}
              variant="h2"
              component="h2"
              className="text-h2"
            >
              Our Features at a Glance!
            </Typography>
            <Typography
              theme={theme}
              className="text-muted text-body2"
              variant="body1"
              component="p"
              mt={{ xs: 2, md: 1 }}
            >
              Discover the features that make our product the ideal solution for
              your business needs.
            </Typography>
          </Box>
          <Box
            theme={theme}
            className="feature-items-wrap"
            mt={{ xs: 5, md: 8 }}
          >
            <Grid theme={theme} item container spacing={3}>
              {(features ?? []).length > 0
                ? features?.map((feature: IFeature, index: number) => {
                  const [className, gridLayout] = splitFeatureVariant(
                    feature.feature_variant
                  )

                  return gridLayout === gridLayoutFormat.COL_6
                    ? (
                      <Col6Template
                        key={index}
                        className={className}
                        gridLayout={gridLayout}
                        feature={feature}
                      />
                      )
                    : (
                      <Col12Template
                        key={index}
                        className={className}
                        gridLayout={gridLayout}
                        feature={feature}
                      />
                      )
                })
                : null}
            </Grid>
          </Box>
        </ProductFeaturesWrap>
      </Container>
    </Box>
  )
}

FeatureSectionComponent.propTypes = {}

FeatureSectionComponent.defaultProps = {}
