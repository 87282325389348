import { DeleteConfirmationPopupComponent } from '@modules/ProductConfigurations/ProductConfigurationsModule'
import { useTheme } from '@mui/material'
import { Box, Button, Grid, Icon, Input } from '@ntpkunity/controls'
import { type IFormData } from '@shared/typings'
import { type FC } from 'react'
import {
  Controller,
  type UseFieldArrayRemove,
  useFormContext,
  type UseFormReturn
} from 'react-hook-form'
import { useModal } from 'react-modal-hook'
import { HowToConnectFields, messages } from './HowToConnectComponent'

interface IProductUsageComponentProps {
  index: number
  remove: UseFieldArrayRemove
}
export const ProductUsageComponent: FC<IProductUsageComponentProps> = ({
  index,
  remove
}) => {
  const theme = useTheme()
  const {
    control,
    formState: { errors }
  } = useFormContext<IFormData>()

  const onConfirm = () => {
    remove(index)
    hideDeletePopup()
  }

  const [showDeletePopup, hideDeletePopup] = useModal(
    () => (
      <DeleteConfirmationPopupComponent
        hide={hideDeletePopup}
        onConfirm={onConfirm}
      />
    ),
    [index]
  )

  const deleteProductUsageStep = () => { showDeletePopup() }

  return (
    <Box theme={theme} className="steps" mt={3}>
      <Grid theme={theme} container item spacing={3}>
        <Grid theme={theme} item xs={12} md={3}>
          <Controller
            name={`${HowToConnectFields.PRODUCT_USAGE_STEPS}.${index}.step_headline`}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                theme={theme}
                fullWidth={true}
                type={'text'}
                label={`Step-${index + 1} Headline`}
                placeholder={messages.placeholder.stepHeadline}
                error={
                  errors?.how_to_connect?.product_usage_steps?.[index]
                    ?.step_headline?.message
                }
                id={''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={8.35}>
          <Controller
            name={`${HowToConnectFields.PRODUCT_USAGE_STEPS}.${index}.step_description`}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                theme={theme}
                fullWidth={true}
                type={'text'}
                label={`Step-${index + 1} Description`}
                placeholder={messages.placeholder.stepDescription}
                error={
                  errors?.how_to_connect?.product_usage_steps?.[index]
                    ?.step_description?.message
                }
                id={''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={0.65}>
          <Button
            theme={theme}
            secondary
            onClick={deleteProductUsageStep}
            iconText={<Icon name="DeleteIcon" />}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
