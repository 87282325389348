import { NotificationFilterBy } from '@shared/constants'
import { loggedIn, type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import {
  type INotificationsResponse,
  type INotificationsBackend
} from '@shared/typings'
import {
  useMutation,
  useQuery,
  type UseQueryResult,
  type UseMutationResult,
  useInfiniteQuery,
  type UseInfiniteQueryResult
} from 'react-query'
import { type StoreContextType } from 'store/configs'
import { ACTION_TYPE } from 'store/ducks/notifications'
import { useStoreContext } from 'store/storeContext'

const BASE_URL = process.env.BASE_URL
export const NOTIFICATIONS = 'marketplace/admin/notifications'
export const LIMIT = 15

interface IGetNotificationsOptions {
  filterBy?: string
  pageParam?: number
  limit?: number
}

export const useMarkNotificationRead = (): UseMutationResult<
any,
AppError | undefined,
INotificationsBackend,
unknown
> => {
  const {
    actions: { notificationDispatch }
  }: StoreContextType = useStoreContext()

  return useMutation(
    async (notificationData) => {
      const url = `${NOTIFICATIONS}/${notificationData.id}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(url, {})
    },
    {
      onError: (_, variables, context) => {
        notificationDispatch({
          type: ACTION_TYPE.ERROR_READ_NOTIFICATION,
          payload: {
            notificationData: variables
          }
        })
      }
    }
  )
}

export const useGetAllNotifications = (
  filterBy = NotificationFilterBy.ALL
): UseInfiniteQueryResult<INotificationsResponse, AppError | undefined> => {
  return useInfiniteQuery(
    [NOTIFICATIONS, filterBy],
    async ({ pageParam = 0 }: IGetNotificationsOptions) => {
      const url = `${NOTIFICATIONS}?page=${pageParam}&limit=${LIMIT}&filter_by=${filterBy}`
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get(url)
    },
    {
      getNextPageParam: (
        lastPage: INotificationsResponse,
        allPages: INotificationsResponse[]
      ) => lastPage.next_page ?? undefined,
      staleTime: Infinity,
      enabled: loggedIn()
    }
  )
}
