import React, { type FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {} from './TotalSignUpStyle'
import { GraphWidget } from '@shared/components/GraphWidget/graphWidget'
import { Box, Typography } from '@ntpkunity/controls'
import { useGetTotalSignUpDashboard } from './TotalSignUpService'
import { start } from 'single-spa'
import { NoData } from '@shared/components/NoData/noData'

interface TotalSignUpComponentProps {
  startDate: Date
  endDate: Date
}

export const TotalSignUpComponent: FC<TotalSignUpComponentProps> = ({
  startDate,
  endDate
}) => {
  const theme = useTheme()
  const { data } = useGetTotalSignUpDashboard(startDate, endDate)

  return (
    <GraphWidget theme={theme}>
      <Typography
        theme={theme}
        variant="h3"
        component="h3"
        className="fw-medium"
      >
        {'Total Sign Ups'}
      </Typography>
      {data
        ? (
        <Box theme={theme} className="graph-widget-container">
          <iframe
            width="100%"
            height="480"
            src={data ?? ''}
            title="Total Sign Ups"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
          )
        : (
        <NoData />
          )}
    </GraphWidget>
  )
}

TotalSignUpComponent.propTypes = {}

TotalSignUpComponent.defaultProps = {}
