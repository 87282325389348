import React from 'react'
import { Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { NoDataWrap } from './noDataStyle'
import NoDataImage from '@shared/assets/images/no-data-img.svg'

export const NoData: React.FC = ({}) => {
  const theme = useTheme()
  return (
    <NoDataWrap theme={theme} className="no-data-wrap">
      <img src={NoDataImage} alt="No Results" />
    </NoDataWrap>
  )
}
