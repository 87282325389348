import React from 'react'
import { Box, Typography } from '@ntpkunity/controls'
import { type ICounterWidgetProps } from './counterWidget.types'
import ArrowUp from '../../assets/images/ic-arrow-up-green.svg'
import ArrowDown from '../../assets/images/ic-arrow-down-red.svg'
import { WidgetCardWrap } from './counterWidgetStyle'
import clsx from 'clsx'
import { convertToDecimal } from '@shared/helper'

export const CounterWidget: React.FC<ICounterWidgetProps> = ({
  title,
  counterText,
  theme,
  rankImage,
  rankText,
  rankTextSuccess,
  rankTextDanger,
  rankImageUp,
  rankImageDown
}) => {
  return (
    <WidgetCardWrap theme={theme} className="widget-card-wrap">
      <Typography
        theme={theme}
        variant="body1"
        component="p"
        className="text-muted"
      >
        {title}
      </Typography>
      <Typography
        theme={theme}
        variant="h2"
        component="h2"
        className="value fw-sbold"
      >
        {counterText}
      </Typography>
      <Box
        theme={theme}
        className="icon-text"
        display={'flex'}
        alignItems={'center'}
      >
        {rankImage && (
          <Box theme={theme} className="icon" mr={0.5} flexShrink={0}>
            {rankImageUp && <img src={ArrowUp} alt="" />}
            {rankImageDown && <img src={ArrowDown} alt="" />}
          </Box>
        )}
        {rankText && (
          <Typography
            theme={theme}
            variant="body1"
            component="p"
            className={clsx({
              'text-success': rankTextSuccess,
              'text-danger': rankTextDanger
            })}
            flexGrow={1}
          >
            {rankText}
          </Typography>
        )}
      </Box>
    </WidgetCardWrap>
  )
}
