import Http from '@shared/helper/http-api'
import { type IBlog, type IComment } from '@shared/typings'
import {
  useQuery,
  type UseQueryResult,
  useMutation,
  useQueryClient
} from 'react-query'
import { type AppError } from 'single-spa'

const BASE_URL = process.env.BASE_URL
const BLOGS = 'signup/get-all-blogs'
const UPDATE_BLOG_STATUS = 'marketplace/blog/update-blog-status'
const POST_BLOG_COMMENT = 'marketplace/blog/create-blog-comment'
export const FETCH_BLOG_COMMENTS = 'marketplace/blog/fetch-blog-comments'

export const useGetAllBlogs = (
  isLatest = false
): UseQueryResult<IBlog[], AppError | undefined> => {
  const url = `${BLOGS}?is_latest=${isLatest}`
  return useQuery(
    [BLOGS],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<IBlog[]>(url)
    },
    { refetchOnWindowFocus: false }
  )
}

export const useUpdateBlogStatus = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any, any, any>(
    async (body) => {
      const { blog_id } = body
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(
        `${UPDATE_BLOG_STATUS}/${blog_id as number}`,
        body
      )
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(BLOGS)
      }
    }
  )
}

export const useAddComment = (): any => {
  const queryClient = useQueryClient()

  return useMutation<IComment, any>(
    async (body) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.post<any>(POST_BLOG_COMMENT, body)
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(FETCH_BLOG_COMMENTS)
      }
    }
  )
}
