import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Dialog,
  Grid,
  Icon,
  Input,
  Menu,
  Typography
} from '@ntpkunity/controls'
import { InvoiceCard, TableWrap } from './billingDetailStyle'

export const BillingDetailComponent = ({}) => {
  const theme = useTheme()
  return (
    <>
      <Dialog
        theme={theme}
        size="xl"
        noFooter={true}
        onCloseDialog={() => {}}
        title="Lorna Dupoy"
        open
      >
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12} sm={6}>
            <InvoiceCard
              theme={theme}
              className="invoice-card"
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent="center"
            >
              <Typography
                className="text-muted"
                theme={theme}
                variant="body2"
                component="span"
              >
                Next invoice issue date
              </Typography>
              <Typography theme={theme} variant="h3" component="h3">
                Dec 25, 2022
              </Typography>
            </InvoiceCard>
          </Grid>
          <Grid theme={theme} item xs={12} sm={6}>
            <InvoiceCard
              theme={theme}
              className="invoice-card"
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent="center"
            >
              <Typography
                className="text-muted"
                theme={theme}
                variant="body2"
                component="span"
              >
                Invoice Total
              </Typography>
              <Typography theme={theme} variant="h3" component="h3">
                $ 000.00
              </Typography>
            </InvoiceCard>
          </Grid>
        </Grid>
        <TableWrap theme={theme} className="table-wrap" mt={4}>
          <DataTable
            theme={theme}
            variant="basic"
            theadChildren={
              <>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox theme={theme} label=""></Checkbox>
                  </TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell className="cell-md">Product Description</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Invoice Total</TableCell>
                  <TableCell className="action-cell"></TableCell>
                </TableRow>
                <TableRow className="filters-row">
                  <TableCell className="checkbox-cell"></TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={''}
                        startAdornment={''}
                        endAdornment={''}
                        id={''}
                        placeholder="Search"
                      ></Input>
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell className="cell-md">
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={''}
                        startAdornment={''}
                        endAdornment={''}
                        id={''}
                        placeholder="Search"
                      ></Input>
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={''}
                        startAdornment={''}
                        endAdornment={''}
                        id={''}
                        placeholder="Search"
                      ></Input>
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={''}
                        startAdornment={''}
                        endAdornment={''}
                        id={''}
                        placeholder="Search"
                      ></Input>
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell className="action-cell"></TableCell>
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox theme={theme} label=""></Checkbox>
                  </TableCell>
                  <TableCell>Nov 25, 2022</TableCell>
                  <TableCell className="cell-md">
                    Sample Product 1 (Bronze), Sample Product 2 (Free Trial),
                    Sample Product...
                  </TableCell>
                  <TableCell>
                    <span className="status-indicator ind-danger"></span>
                    Payment Due
                  </TableCell>
                  <TableCell className="text-right">$ 000.00</TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        { optionText: 'Action 1' },
                        { optionText: 'Action 2' },
                        { optionText: 'Action 3' }
                      ]}
                      render={(cb) => (
                        <Button
                          onClick={cb}
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="MoreIcon" />}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox theme={theme} label=""></Checkbox>
                  </TableCell>
                  <TableCell>Oct 25, 2022</TableCell>
                  <TableCell className="cell-md">
                    Sample Product 1 (Bronze), Sample Product 2 (Free Trial),
                    Sample Product...
                  </TableCell>
                  <TableCell>
                    <span className="status-indicator ind-success"></span>
                    Paid
                  </TableCell>
                  <TableCell className="text-right">$ 000.00</TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        { optionText: 'Action 1' },
                        { optionText: 'Action 2' },
                        { optionText: 'Action 3' }
                      ]}
                      render={(cb) => (
                        <Button
                          theme={theme}
                          onClick={cb}
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            }
          />
        </TableWrap>
      </Dialog>
    </>
  )
}

BillingDetailComponent.propTypes = {}

BillingDetailComponent.defaultProps = {}
