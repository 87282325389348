import React, { FC, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ChevronDown from 'shared/assets/images/chevron-down'
import { TableCell, TablePagination, TableRow, useTheme } from '@mui/material'
import { Box, Button, DataTable, Icon, Input } from '@ntpkunity/controls'
import { TableWrap } from './BillingDetailsStyle'
import { type IBillingDetailsFilter, ITrackUserFilter } from '@shared/typings'
import { useGetBillingDetails } from './BillingDetailsService'
import { SortingOrder, dateFormat, formatDate } from '@shared/constants'
import { formatedDate } from '@shared/helper/methods'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import { start } from 'single-spa'
import { useDebouncedState } from '@shared/helper/hooks'
import DateRange from '@shared/components/DateRange'
import { type DateObject } from 'react-multi-date-picker'

import { stat } from 'fs'
import { TableSkeleton } from '@shared/components/TableSkeleton/TableSkeleton'
interface IDebouncedState {
  tenant_name: string
  product: string
  payment_type: string
  billing_cycle_end_date: DateObject | null
}
const initialState: IDebouncedState = {
  product: '',
  tenant_name: '',
  payment_type: '',
  billing_cycle_end_date: null
}
export const BillingDetailsTableComponent = () => {
  const theme = useTheme()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  )
  const { data: userTracking, isLoading: userTrackingLoading } =
    useGetBillingDetails(columnFilters)

  const trackuserfilter: IBillingDetailsFilter = userTracking

  const [filteredData, setFilteredData] = useState<IBillingDetailsFilter>()
  const [order, setOrder] = useState(SortingOrder.ASC)

  const [debouncedState, setState, state] = useDebouncedState(initialState)
  const { payment_type, tenant_name, product, billing_cycle_end_date } = state
  useEffect(() => { handleFilter() }, [debouncedState])

  useEffect(() => {
    setFilteredData(userTracking)
  }, [userTracking])

  const sorting = (sortingdata): void => {
    if (order === SortingOrder.ASC) {
      const sortedData = [...filteredData.result].sort((a, b) =>
        compareValues(a[sortingdata], b[sortingdata])
      )

      setFilteredData((prevState) => ({
        ...prevState,
        result: sortedData
      }))

      setOrder(SortingOrder.DSC)
    } else if (order === SortingOrder.DSC) {
      const sortedData = [...filteredData.result].sort((a, b) =>
        compareValues(b[sortingdata], a[sortingdata])
      )

      setFilteredData((prevState) => ({
        ...prevState,
        result: sortedData
      }))

      setOrder(SortingOrder.ASC)
    }
  }

  const compareValues = (a, b) => {
    if (a === undefined || a === null) {
      return -1
    }
    if (b === undefined || b === null) {
      return 1
    }

    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b)
    } else if (typeof a === 'number' && typeof b === 'number') {
      return a - b
    } else if (a instanceof Date && b instanceof Date) {
      const dateA = new Date(a)
      const dateB = new Date(b)
      return dateA.getTime() - dateB.getTime()
    } else {
      return 0
    }
  }

  const setQueryString = (pgNo, pgSize) => {
    let query_string = `page_number=${pgNo}&page_size=${pgSize}`
    if (tenant_name.trimStart() != null && tenant_name.trimStart() != '') {
      query_string = query_string.concat(`&company_name=${tenant_name}`)
    }
    if (payment_type.trimStart() != null && payment_type.trimStart() != '') {
      query_string = query_string.concat(`&payment_type=${payment_type}`)
    }
    if (product.trimStart() != null && product.trimStart() != '') {
      query_string = query_string.concat(`&product_name=${product}`)
    }
    if (billing_cycle_end_date?.isValid) {
      query_string = query_string.concat(
        `&billing_date=${formatedDate(
          billing_cycle_end_date?.toDate(),
          formatDate
        )}`
      )
    }
    return query_string
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }
  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
    setFilteredData(userTracking)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }

  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <TableWrap theme={theme} className="main-table-wrap">
        <span className="scroll-hide spr-border"></span>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell>
                  Tenant Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        sorting('tenant_name')
                      }}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Product Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        sorting('product_names')
                      }}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Payment Type
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        sorting('payment_type')
                      }}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Billing Date
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {
                        sorting('billing_cycle_end_date')
                      }}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={tenant_name?.trimStart()}
                      onChange={(e) => {
                        setState({
                          ...state,
                          tenant_name: e
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={product?.trimStart()}
                      onChange={(e) => {
                        setState({
                          ...state,
                          product: e
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={payment_type?.trimStart()}
                      onChange={(e) => {
                        setState({
                          ...state,
                          payment_type: e
                        })
                      }}
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <DateRange
                      id="billing_cycle_end_date"
                      theme={theme}
                      value={billing_cycle_end_date}
                      placeholder={dateFormat}
                      onChange={(value) => {
                        setState({
                          ...state,
                          billing_cycle_end_date: value
                        })
                      }}
                      format={dateFormat}
                      //   maxDate={new Date()}
                      calendarPosition="right-end"
                      endAdornment={
                        <Icon name="CalendarIcon" className="calendar-icon" />
                      }
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {userTrackingLoading
                ? (
                <>
                  <TableSkeleton theme={theme} columns={4} rows={6} />
                </>
                  )
                : (
                    filteredData?.result?.map((item, index) => {
                      const format = item?.billing_cycle_end_date
                        ? formatedDate(item.billing_cycle_end_date, dateFormat)
                        : 'N/A'

                      return (
                    <TableRow key={index} className="child-tr">
                      <TableCell>{item?.tenant_name}</TableCell>
                      <TableCell>{item?.product_names}</TableCell>
                      <TableCell>{item?.payment_type}</TableCell>
                      <TableCell>{format}</TableCell>
                    </TableRow>
                      )
                    })
                  )}
            </>
          }
        />
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={trackuserfilter ? trackuserfilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown
          }}
        />
      </TableWrap>
    </DataTableWrap>
  )
}

BillingDetailsTableComponent.propTypes = {}

BillingDetailsTableComponent.defaultProps = {}
