import React, { useEffect, useState, type FC } from 'react'
import { TablePagination, useTheme } from '@mui/material'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Icon,
  Input,
  Pagination,
  Typography
} from '@ntpkunity/controls'
import {} from './CommentsPopupStyle'
import { DialogWrap } from '@shared/style/dialogStyle'
import { type IComment, type ITrackCommentFilter } from '@shared/typings'
import ChevronDown from '@shared/assets/images/chevron-down'
import {
  Controller,
  UseFormReturn,
  useForm,
  useFormContext
} from 'react-hook-form'
import {
  commentLimitMessage,
  commentsDateFormat,
  dateFormat,
  statusTask
} from '@shared/constants'
import { formatedDate, getInitials } from '@shared/helper'
import { useGetComments } from './CommentsPopUpService'

interface ICommentsPopupComponentProps {
  id: number
  hide: () => void
  onSave: (data: string, reset: () => void, getComments: () => void) => void
  url: string
}

const messages = {
  placeholder: {
    comment: 'Type your comment here...'
  },
  name: {
    comment: 'comment'
  }
}

export const CommentsPopupComponent: FC<ICommentsPopupComponentProps> = ({
  hide,
  id,
  onSave,
  url
}) => {
  const theme = useTheme()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm<IComment>({
    mode: 'all'
  })

  const [comments, setComments] = useState<ITrackCommentFilter | undefined>()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const { mutate: fetchComments, data } = useGetComments(
    pageNumber,
    pageSize,
    id,
    url
  )

  const getComments = () => {
    fetchComments({ pageNumber, pageSize, id, url })
  }

  useEffect(() => {
    getComments()
  }, [])

  useEffect(() => {
    setComments(data)
  }, [data])
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
  }

  const handleOnSave = (form) => {
    onSave(form.comment, reset, getComments)
  }

  const maxCharLimit = 500
  const charCount = (watch('comment') ?? '').length

  return (
    <DialogWrap theme={theme} className="dialog-wrap comments-dialog">
      <Dialog
        disablePortal={true}
        open
        title="Comments"
        theme={theme}
        size="lg"
        onCloseDialog={hide}
        customFooter={
          <>
            {comments?.result && comments?.result.length > 0
              ? (
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={comments ? comments?.total_results : -1}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  IconComponent: ChevronDown
                }}
              />
                )
              : null}
          </>
        }
      >
        <Box theme={theme} className="dialog-content">
          <form
            onSubmit={handleSubmit(handleOnSave)}
            className="post-comment-input-wrap"
          >
            <Box theme={theme} className="post-comment-form-wrap">
              <Controller
                name="comment"
                control={control}
                rules={{
                  maxLength: {
                    value: 500,
                    message: commentLimitMessage
                  }
                }}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(
                          e.replace(/\+|-/gi, '').slice(0, maxCharLimit)
                        )
                      }}
                      theme={theme}
                      fullWidth
                      type={'text'}
                      placeholder={messages?.placeholder?.comment}
                    />

                    <Button
                      theme={theme}
                      primary
                      text={'Post Comment'}
                      type={'submit'}
                      disabled={!watch('comment')}
                    />
                  </>
                )}
              />
            </Box>

            <Typography
              mt={{ md: 1, xs: 2 }}
              theme={theme}
              variant="caption"
              component="small"
              display={'block'}
              className={`${
                errors?.comment?.type === 'maxLength' ? 'text-error' : ''
              }`}
            >
              {`${charCount}/${maxCharLimit} characters`}
            </Typography>
            <Typography
              mt={{ md: 1, xs: 2 }}
              theme={theme}
              variant="caption"
              component="small"
              display={'block'}
              className="text-error"
            >
              {errors?.comment?.message?.toString()}
            </Typography>
          </form>

          <Box theme={theme} className="comment-items-wrap" mt={3}>
            {comments?.result?.map((comment: IComment, index: number) => {
              const format = formatedDate(
                comment?.created_at,
                commentsDateFormat
              )

              return (
                <Box theme={theme} className="comment-item" mt={1} key={index}>
                  <Box
                    theme={theme}
                    className="comments-head"
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      theme={theme}
                      className="user-info-wrap"
                      flexGrow={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Avatar theme={theme}>
                        {getInitials(comment?.first_name, comment?.last_name)}
                      </Avatar>
                      <Box theme={theme} flexGrow={1}>
                        <Typography
                          theme={theme}
                          variant="body2"
                          className="fw-sbold"
                          component="span"
                          display={'block'}
                        >
                          {comment?.first_name + ' ' + comment?.last_name}
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          className="text-muted"
                          component="small"
                          display={'block'}
                        >
                          {format}.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      theme={theme}
                      className="action-area"
                      flexShrink={0}
                      display={'flex'}
                      alignItems={'center'}
                      gap={2}
                    >
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        className={
                          comment.status_task == statusTask.GENERAL ||
                          comment.status === statusTask.GENERAL
                            ? 'category-tag tag-primary fw-medium'
                            : 'category-tag tag-danger fw-medium'
                        }
                      >
                        {comment?.status_task || comment?.status}
                      </Typography>
                    </Box>
                  </Box>
                  <Box theme={theme} className="comments-body" mt={2}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="span"
                      display={'block'}
                      className="text-muted"
                    >
                      {comment?.comment || comment.content}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Dialog>
    </DialogWrap>
  )
}

CommentsPopupComponent.propTypes = {}

CommentsPopupComponent.defaultProps = {}
