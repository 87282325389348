import { APP_ROUTES } from '@shared/configuration'

export const expiredLinkTypes = {
  verfication: 'verfication',
  password: 'password'
}

export const errorStatusCodes = [401, 400, 403, 404, 409, 500, 501, 502]

export const filterKeys = {
  full_name: 'First/Last Name',
  email: 'Email Address',
  country: 'Country',
  company_name: 'Company Name',
  status: 'Status',
  product_name: 'Product Name',
  allowed_transactions: 'Allowed Transactions',
  transaction_performed: 'Performed Transactions',
  created_at: '',
  api_key_type: 'Key Type'
}
export const dateFormat = 'DD/MM/YYYY'
export const formatDate = 'YYYY-MM-DD'
export const commentsDateFormat = 'MMM DD, YYYY'
export const ProductKeys = {
  product_name: 'Product Name',
  state: 'State',
  status: 'Status'
}

export const subscriberStatus = {
  active: 'Active',
  expired: 'Expired'
}

export const NotificationFilterBy = {
  ALL: 'all',
  UNREAD: 'unread',
  MENTIONS: 'mentions',
  ORDERS: 'orders'
}

export const NOTIFICATION_EVENT_TYPE = {
  BankTransferRequest: APP_ROUTES.WORK_QUEUE.WORK_QUEUE_LIST,
  BankTransferUpload: APP_ROUTES.WORK_QUEUE.WORK_QUEUE_LIST,
  BankTransferPayment: APP_ROUTES.WORK_QUEUE.WORK_QUEUE_LIST
}
