import { useTheme } from '@mui/material'
import { ContentAreaWrap } from '@shared/style/contentStyle'
import {
  Box,
  Grid,
  Input,
  Textarea,
  Button,
  Typography,
  Icon,
  Accordion
} from '@ntpkunity/controls'
import {
  useFieldArray,
  useFormContext,
  type UseFormReturn,
  useWatch
} from 'react-hook-form'
import { type IFormData } from '@shared/typings'
import { useEffect, useRef, useState } from 'react'
import { faqsDefaultValues } from '@shared/constants/productConfigurations'
import DraggableAccordionCommponent from '@shared/components/DraggableAccordion/DraggableAccordionCommponent'
import { Faq } from './Faq'
import { useModal } from 'react-modal-hook'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import {
  ButtonType,
  deleteConfirmationMessage,
  DialogHeading,
  IconType
} from '@shared/constants'

export enum FaqsFields {
  FAQS_KEY = 'faqs',
  QUESTION = 'question',
  ANSWER = 'answer',
}

export const FaqsComponent = ({}) => {
  const theme = useTheme()
  const removeIndexRef = useRef<number>()

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    clearErrors
  } = useFormContext()
  const { fields, remove, append, move } = useFieldArray({
    name: FaqsFields.FAQS_KEY,
    control
  })

  const [expanded, setExpanded] = useState<string | false>(
    `panel-${fields.length - 1}`
  )

  const [showDeletePopup, hideDeletePopup] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DELETE}
        iconName={IconType.ALERT}
        title={DialogHeading.DELETE}
        message={deleteConfirmationMessage}
        onConfirm={() => {
          remove(removeIndexRef.current)
          hideDeletePopup()
        }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={hideDeletePopup}
        onCloseDialog={hideDeletePopup}
        danger
      />
    ),
    [removeIndexRef.current, remove]
  )

  const deleteFaqItem = (index: number) => {
    removeIndexRef.current = index
    showDeletePopup()
  }

  const addFaq = () => {
    append({ ...faqsDefaultValues })
  }

  useEffect(() => {
    setExpanded(`panel-${fields.length - 1}`)
  }, [fields.length])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const moveFaqListItem = (dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex)
    setValue('makeFormDirty', false, { shouldDirty: true })
  }

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <Box theme={theme} className="accordion-panel-wrap">
        {fields.map((field, index: number) => (
          <DraggableAccordionCommponent
            key={field.id}
            index={index}
            theme={theme}
            varient="grouped"
            shouldCollapse={true}
            items={{
              content: (
                <>
                  <Faq key={field.id} index={index} />
                </>
              ),
              title: (
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component={'p'}
                  className="fw-sbold text-body1"
                >
                  {getValues(
                    `${FaqsFields.FAQS_KEY}.${index}.${FaqsFields.QUESTION}`
                  )}
                </Typography>
              ),
              key: field.id,
              isExpanded: expanded === `panel-${index}`,
              handleChange,
              moveListItem: moveFaqListItem,
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={
                      <Icon
                        name="DeleteIcon"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          deleteFaqItem(index)
                          e.stopPropagation()
                        }}
                      />
                    }
                  />
                </>
              )
            }}
          />
        ))}
      </Box>
      <Box theme={theme} mt={3}>
        <Button
          fullWidth
          theme={theme}
          secondary
          text="Add New FAQ"
          onClick={addFaq}
        />
      </Box>
    </ContentAreaWrap>
  )
}
