import { type AppError, loggedIn } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type IUserProfile } from '@shared/typings'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL
export const GET_USER_PROFILE = 'ums/user-profile'

export const useGetProfile = (): {
  data: IUserProfile
  error: AppError | unknown
  isLoading: boolean
  isError: boolean
} => {
  const isLoggedIn = loggedIn()
  const { data, error, isLoading, isError } = useQuery(
    GET_USER_PROFILE,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(GET_USER_PROFILE)
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        isLoggedIn &&
        window.location.pathname.split('/admin/')[1] !== 'preview'
    }
  )
  return { data, error, isLoading, isError }
}
