import * as React from 'react'
import DatePicker from 'react-multi-date-picker'
import { type IDateRange } from './DateRange.types'
import { FormHelperText, InputLabel, styled } from '@mui/material'
import clsx from 'clsx'

const DateRangeUi = styled(
  'div',
  {}
)<Partial<IDateRange>>(({ theme }) => ({
  '&.date-range': {
    position: 'relative',
    width: '100%',
    '.rmdp-container ': {
      position: 'relative',
      width: '100%',
      input: {
        height: 36,
        color: 'rgba(0,0,0,0.87)',
        padding: '0px 36px 0px 16px',
        borderRadius: 4,
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
          borderColor: theme.palette.primary.main
        },
        '&:focus': {
          borderColor: theme.palette.primary.main,
          outline: 0,
          borderWidth: 2
        },
        '&:focus-visible': {
          borderColor: theme.palette.primary.main,
          outline: 0,
          borderWidth: 2
        },
        '&::placeholder': {
          opacity: 0
        }
      },
      '.rmdp-wrapper': {
        boxShadow: '0px 32px 64px rgba(0, 0, 0, 0.16)',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        overflow: 'hidden',
        borderRadius: 16,
        border: 'none',
        padding: '16px 40px 8px 40px',
        '.rmdp-calendar': {
          width: 336,
          '.rmdp-header': {
            '.rmdp-arrow-container': {
              width: 48,
              height: 48,
              display: 'flex',
              alignItems: 'center',
              '&.rmdp-left, &.rmdp-right': {
                '.rmdp-arrow': {
                  border: 'solid' + theme.palette.grey[300],
                  borderWidth: '0 3px 3px 0',
                  marginTop: 0
                }
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 36, 156, 0.05)',
                boxShadow: 'none'
              }
            },
            '.rmdp-header-values': {
              fontSize: theme.typography.h4.fontSize,
              fontWeight: theme.customVariables.fontWeightSemiBold,
              fontFamily: theme.typography.fontFamily
            }
          },
          '.rmdp-day-picker': {
            width: 336,
            '.rmdp-week': {
              marginBottom: 8,
              '.rmdp-week-day': {
                fontSize: theme.typography.subtitle2.fontSize,
                fontWeight: theme.typography.fontWeightRegular,
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.grey[700],
                width: 48,
                height: 48
              },
              '.rmdp-day': {
                width: 48,
                height: 48,
                boxShadow: 'none',
                '&.rmdp-range': {
                  backgroundColor: 'rgba(0, 36, 156, 0.05)',
                  '.sd': {
                    color: theme.palette.common.black
                  },
                  '&.start, &.end': {
                    '&:before': {
                      content: '""',
                      width: 48,
                      height: 48,
                      display: 'flex',
                      borderRadius: 50,
                      backgroundColor: theme.palette.primary.main
                    },
                    '.sd': {
                      color: theme.palette.common.white
                    }
                  }
                },
                '.sd': {
                  fontSize: theme.typography.subtitle2.fontSize,
                  fontWeight: theme.typography.fontWeightRegular,
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.grey[700],
                  '&:hover': {
                    backgroundColor: 'rgba(0, 36, 156, 0.05)'
                  }
                },
                '&.rmdp-range-hover': {
                  backgroundColor: 'rgba(0, 36, 156, 0.05)'
                },
                '&:last-child': {
                  borderBottomRightRadius: '50%',
                  borderTopRightRadius: '50%'
                },
                '&:first-child': {
                  borderBottomLeftRadius: '50%',
                  borderTopLeftRadius: '50%'
                },
                '&.rmdp-today': {
                  span: {
                    backgroundColor: 'transparent',
                    border: '1px solid' + theme.palette.primary.main,
                    borderRadius: 50,
                    color: theme.palette.primary.main,
                    fontWeight: theme.customVariables.fontWeightSemiBold
                  }
                }
              }
            }
          },
          '.rmdp-month-picker, .rmdp-year-picker': {
            width: 340,
            '.rmdp-ym': {
              marginBottom: 0,
              '.rmdp-day': {
                height: 48,
                span: {
                  fontSize: theme.typography.subtitle2.fontSize,
                  fontWeight: theme.typography.fontWeightRegular,
                  fontFamily: theme.typography.fontFamily,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 36, 156, 0.05)',
                    color: theme.palette.common.black
                  }
                },
                '&.rmdp-today': {
                  span: {
                    backgroundColor: 'transparent',
                    border: '1px solid' + theme.palette.primary.main,
                    borderRadius: 8,
                    color: theme.palette.common.black,
                    fontWeight: theme.customVariables.fontWeightSemiBold
                  },
                  '&.rmdp-selected': {
                    span: {
                      color: theme.palette.common.white
                    }
                  }
                },
                '&.rmdp-selected': {
                  span: {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 8,
                    '&:hover': {
                      color: theme.palette.common.white
                    }
                  }
                }
              }
            }
          }
        }
      },
      '.ep-arrow': {
        '+': {
          div: {
            position: 'fixed !important'
          }
        }
      }
    },
    '.calendar-icon': {
      position: 'absolute',
      right: '8px',
      top: 7,
      userSelect: 'none',
      pointerEvents: 'none'
    },
    '.u-input-label': {
      color: 'rgba(0,0,0,0.87)',
      fontFamily: theme.typography.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
      transform: 'translate(12px, -35px) scale(1)',
      padding: '0 5px 0 5px',
      backgroundColor: theme.palette.common.white,
      userSelect: 'none',
      pointerEvents: 'none',
      display: 'inline-block',
      maxWidth: 'calc(100% - 32px)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      position: 'absolute',
      top: 48,
      left: 0
    },
    '.u-help-text': {
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight
    },
    '.u-error-text': {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight
    },
    '&.focused': {
      '.u-input-label': {
        color: theme.palette.text.secondary,
        transform: 'translate(12px, -58px) scale(0.85)'
      },
      input: {
        '&::placeholder': {
          opacity: 0.26
        }
      }
    },
    '&.filled': {
      '.u-input-label': {
        color: theme.palette.text.secondary,
        transform: 'translate(12px, -58px) scale(0.85)'
      }
    },
    '&.error': {
      input: {
        borderColor: theme.palette.error.main
      },
      '.u-input-label': {
        color: theme.palette.error.main
      },
      '&:hover': {
        borderColor: theme.palette.error.main
      },
      '&:focus': {
        borderColor: theme.palette.error.main
      },
      '&:focus-visible': {
        borderColor: theme.palette.error.main
      }
    }
  }
}))

const DateRange: React.FC<IDateRange> = ({
  onClose,
  value,
  minDate,
  maxDate,
  onFocus,
  onBlur,
  onChange,
  id,
  range = false,
  rangeHover = false,
  placeholder,
  theme,
  endAdornment,
  label,
  helperText,
  error,
  dateSeparator,
  format,
  calendarPosition = 'auto'
}) => {
  const dateRangePickerRef: any = React.useRef<HTMLDivElement>(null)
  const handleClick = (event: any, className: string): any => {
    event.currentTarget.classList.add(className)
  }
  React.useEffect(() => {
    if (value !== undefined) {
      dateRangePickerRef.current.classList.add('filled')
    }
  }, [value])
  const handleRemoveCssClass = (event: any, className: string): any => {
    event.currentTarget.classList.remove(className)
  }
  const handleChange = (event: any): any => {
    if (event && isEveryElementNull(event)) {
      dateRangePickerRef.current?.classList.remove('filled')
    } else {
      dateRangePickerRef.current?.classList.add('filled')
    }
  }
  const isEveryElementNull = (array: any): boolean => {
    if (!Array.isArray(array)) {
      return false
    }
    return array.every((element: any) => element === null)
  }

  return (
    <DateRangeUi
      theme={theme}
      className={clsx({
        'date-range': true,
        icon: endAdornment
      })}
      onFocus={(e: any) => handleClick(e, 'focused')}
      onBlur={(e: any) => handleRemoveCssClass(e, 'focused')}
      ref={dateRangePickerRef}
    >
      <DatePicker
        onClose={onClose}
        onChange={(e) => {
          handleChange(e)
          onChange(e)
        }}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        format={format}
        range={range}
        rangeHover={rangeHover}
        id={id}
        placeholder={placeholder}
        dateSeparator={dateSeparator}
        hideOnScroll
        calendarPosition={calendarPosition}
      />
      {endAdornment}
      {label && (
        <InputLabel className="u-input-label" htmlFor={id}>
          {label}
        </InputLabel>
      )}
      {helperText && (
        <FormHelperText className="u-help-text" id={id}>
          {helperText}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText className="u-error-text" id={id}>
          {error}
        </FormHelperText>
      )}
    </DateRangeUi>
  )
}
export default DateRange
