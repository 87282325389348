import { useTheme } from '@mui/material'
import { useUploadFile } from '@modules/ProductConfigurations/services'
import {
  FileDragDrop,
  FilePreview,
  Grid,
  Input,
  Typography,
} from '@ntpkunity/controls'
import { FileDragDropWrap } from '@shared/components/FileDragDropWrap/FileDragDropWrap'
import { uploadFileMetaDataInitialValue } from '@shared/constants/productConfigurations'
import {
  openInNextWindow,
  S3FileKey,
  uploadFileToS3Bucket,
} from '@shared/helper'
import { type IUploadMetaData } from '@shared/typings'
import { type FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface ILogosProps {
  index: number
  id: string
  filesToBeDeleted: string[]
}

const messages = {
  label: {
    featureHeadline: 'Customer Name',
  },
  placeholder: {
    typeHere: 'Type Here...',
  },
}

const maxCharLimit = 300

export const CustomerLogoStrips: FC<ILogosProps> = ({
  id,
  index,
  filesToBeDeleted,
}) => {
  const theme = useTheme()
  const [, setRerender] = useState<number>(0)
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useFormContext()
  const { mutate: uploadFile, isLoading: uploadingFile } = useUploadFile(
    setValue as any,
    trigger as any
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const onFileRemove = (formFieldId, metadataId) => {
    deleteFile(formFieldId)
    setRerender((prevValue) => prevValue + 1)
    setValue(formFieldId, '', { shouldDirty: true })
    setValue(metadataId, uploadFileMetaDataInitialValue)
  }

  const _uploadFile = (file, formFieldId, metadataId) => {
    uploadFileToS3Bucket(
      uploadFile,
      { file, formFieldId, metadataId },
      deleteFile
    )
  }

  const onImageUpload = (e: any, formFieldId, metadataId) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      if (e?.target?.files[0]?.type.startsWith('image/')) {
        _uploadFile(e.target.files[0], formFieldId, metadataId)
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.',
        })
      }
    }
  }

  const onImageDrop = (e: any, formFieldId, metadataId) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      if (e?.dataTransfer?.files[0]?.type.startsWith('image/')) {
        _uploadFile(e.dataTransfer.files[0], formFieldId, metadataId)
        clearErrors(formFieldId)
      } else {
        setError(formFieldId, {
          type: 'checkFileType',
          message: 'Only image files are allowed.',
        })
      }
    }
  }

  const imageUrlUpload: boolean =
    (getValues(`customers_logos.${index}.customer_logo_url`) ?? '').length >
      0 && !uploadingFile

  const uploadedImageFileMetadata: IUploadMetaData = getValues(
    `customers_logos.${index}.meta_data.image_url`
  )
  const fileName = uploadedImageFileMetadata?.name

  return (
    <Grid theme={theme} container item spacing={3}>
      <Controller
        name={`customers_logos.${index}.meta_data`}
        control={control}
        render={({ field }) => <> </>}
      />

      <Grid theme={theme} item xs={12}>
        <Controller
          name={`customers_logos.${index}.customer_name` as any}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              type={'text'}
              {...field}
              onChange={(e) => {
                field.onChange(e.slice(0, maxCharLimit))
              }}
              label={messages.label.featureHeadline}
              placeholder={messages.placeholder.typeHere}
              error={errors?.customers_logos?.[index]?.customer_name?.message}
            />
          )}
        />
      </Grid>

      <Grid theme={theme} item xs={12}>
        <FileDragDropWrap className="file-drag-drop-wrap">
          {!imageUrlUpload ? (
            <>
              <Controller
                name={`customers_logos.${index}.customer_logo_url` as any}
                control={control}
                render={({ field }) => (
                  <FileDragDrop
                    {...field}
                    id={`customers_logos.${index}.customer_logo_url`}
                    theme={theme}
                    imageButton={false}
                    isLoading={uploadingFile}
                    accept={'image/*'}
                    hoverLabel={
                      <>
                        Drag and drop logo image, or{' '}
                        <span className="text-primary link">browse</span>
                      </>
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onImageUpload(
                        event,
                        `customers_logos.${index}.customer_logo_url`,
                        `customers_logos.${index}.meta_data.image_url`
                      )
                      // field.onChange(event)
                    }}
                    onDrop={(event: React.DragEvent<HTMLElement>) => {
                      onImageDrop(
                        event,
                        `customers_logos.${index}.customer_logo_url`,
                        `customers_logos.${index}.meta_data.image_url`
                      )
                      // field.onChange(event.dataTransfer.files[0])
                    }}
                    error={Boolean(
                      errors?.customers_logos?.[index]?.customer_logo_url
                        ?.message
                    )}
                  />
                )}
              />
              {errors?.customers_logos?.[index]?.customer_logo_url?.message
                ?.length > 0 &&
              errors?.customers_logos?.[index]?.customer_logo_url?.type ===
                'checkFileType' ? (
                <Typography
                  mt={{ md: 1, xs: 2 }}
                  theme={theme}
                  variant="caption"
                  component="small"
                  display={'block'}
                  className={`${
                    errors?.customers_logos?.[index]?.customer_logo_url
                      ?.type === 'checkFileType'
                      ? 'text-error'
                      : ''
                  }`}
                >
                  {errors?.customers_logos?.[index]?.customer_logo_url?.message}
                </Typography>
              ) : null}
            </>
          ) : (
            <FilePreview
              files={[
                (fileName ?? '').length > 0
                  ? uploadedImageFileMetadata
                  : uploadFileMetaDataInitialValue,
              ]}
              preview={'imageIcon'}
              viewIcon
              onRemoveFile={() => {
                onFileRemove(
                  `customers_logos.${index}.customer_logo_url`,
                  `customers_logos.${index}.meta_data.image_url`
                )
              }}
              onClickIcon={() =>
                openInNextWindow(
                  getValues(`customers_logos.${index}.customer_logo_url`)
                )
              }
              theme={theme}
            />
          )}
        </FileDragDropWrap>
      </Grid>
    </Grid>
  )
}
