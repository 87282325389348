import { useTheme } from '@mui/material'
import { LayoutWithSideNavComponent } from '../../../Layout/components'
import {} from './WorkQueueStyle'
import {
  WorkQueuePageHeaderComponent,
  WorkQueueTableComponent
} from '@modules/WorkQueue/components'

export const WorkQueuePage = () => {
  const theme = useTheme()

  return (
    <>
      <WorkQueuePageHeaderComponent />
      <WorkQueueTableComponent />
    </>
  )
}
