import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const DialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-wrap': {
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.fw-sbold': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.u-dialog': {
      '.u-dialog-action': {
        '.dialog-action-area': {
          width: '100%',
          '.buttons': {
            display: 'flex',
            width: '100%',
            gap: 16,
            '.MuiButton-root': {
              '&:not(:first-of-type)': {
                width: 'auto',
                flexGrow: 1,
                flexShrink: 0,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              },
              '&:last-of-type': {
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              }
            },
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            }
          }
        }
      },
      '&.u-dialog-xs': {
        '.MuiDialog-paper': {
          maxWidth: 480
        }
      },
      '&.u-dialog-lg': {
        '.MuiDialog-paper': {
          maxWidth: 1200
        }
      }
    },
    '&.comments-dialog': {
      '.u-dialog': {
        '.u-dialog-content': {
          overflowY: 'hidden',
          padding: 0,
          '.dialog-content': {
            '.post-comment-input-wrap': {
              padding: '40px 60px 0 60px',
              [theme.breakpoints.down('sm')]: {
                padding: '32px 24px 0 24px'
              }
            },
            '.comment-items-wrap': {
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: '41vh',
              padding: '0 52px 0 60px',
              scrollbarWidth: 'thin',
              scrollbarGutter: 'stable',
              '&::-webkit-scrollbar': {
                width: 7,
                height: 7
              },
              '&::-webkit-scrollbar-track': {
                padding: '0 1px',
                backgroundColor: '#f0f0f0'
              },
              '&::-webkit-scrollbar-thumb': {
                transition: 'all 0.3s',
                backgroundColor: '#cdcdcd'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#a6a6a6'
              },
              [theme.breakpoints.down('sm')]: {
                padding: '0px 24px 0 24px',
                maxHeight: '29vh'
              }
            }
          }
        },
        '.u-dialog-action': {
          padding: '8px 60px 60px',
          justifyContent: 'flex-end',
          [theme.breakpoints.down('sm')]: {
            padding: '8px 24px 32px'
          }
        }
      },
      '.text-error': {
        color: theme.palette.error.main
      }
    },
    '.dialog-content': {
      '.post-comment-form-wrap': {
        '.u-form-group': {
          margin: '0 0 16px 0',
          '.u-form-control': {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            '.MuiInputBase-input': {
              '&::placeholder': {
                opacity: 0.26
              }
            }
          },
          [theme.breakpoints.up('md')]: {
            margin: '0 24px 0 0',
            flexGrow: 1,
            '.u-form-control': {
              backgroundColor: 'transparent',
              height: 'auto',
              '.MuiInputBase-input': {
                padding: 0
              },
              '&.Mui-focused': {
                fieldset: {
                  borderColor: 'transparent'
                }
              },
              '&:hover': {
                fieldset: {
                  borderColor: 'transparent'
                }
              },
              fieldset: {
                borderColor: 'transparent'
              }
            }
          }
        },
        '.MuiButton-root': {
          width: '100%'
        },
        [theme.breakpoints.up('md')]: {
          border: '1px solid rgba(0, 0, 0, 0.2)',
          borderRadius: 12,
          padding: '12px 12px 12px 24px',
          display: 'flex',
          alignItems: 'center',
          '.MuiButton-root': {
            flexShrink: 0,
            width: 'auto'
          }
        }
      },
      '.comment-item': {
        border: '1px solid' + theme.palette.grey[100],
        padding: 24,
        borderRadius: theme.shape.borderRadius,
        '.comments-body': {
          '.MuiTypography-root': {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        }
      },
      '.user-info-wrap': {
        '.u-avator': {
          backgroundColor: theme.palette.grey[300],
          marginRight: 16
        }
      },
      '.action-area': {
        '.category-tag': {
          backgroundColor: theme.palette.grey[50],
          padding: '6px 16px',
          textAlign: 'center',
          borderRadius: 24,
          '&.tag-primary': {
            backgroundColor: 'rgba(0, 51, 253, 0.1)',
            color: theme.palette.primary.main
          },
          '&.tag-danger': {
            backgroundColor: 'rgba(255, 51, 51, 0.1)',
            color: theme.palette.error.main
          }
        },
        '.btn': {
          '&.with-icon': {
            '&.icon-only': {
              padding: 0
            }
          },
          '&.del-btn': {
            '&:hover': {
              svg: {
                path: {
                  stroke: theme.palette.error.main
                }
              }
            }
          }
        }
      }
    }
  },
  '.MuiTablePagination-root': {
    marginTop: '-4px',

    paddingTop: '4px',
    borderColor: theme.palette.grey[100],
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    color: theme.palette.grey[600],
    fontSize: theme.typography.htmlFontSize,
    fontFamily: theme.typography.fontFamily
  },
  '.MuiTablePagination-selectIcon': {
    top: 'calc(35% - 0.5em)',
    left: 24
  },
  '.MuiTablePagination-select:focus': {
    backgroundColor: 'transparent'
  }
}))
