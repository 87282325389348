import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Icon,
  Input,
  Menu,
  Switch
} from '@ntpkunity/controls'
import { TableWrap } from './employeesListStyle'

export const EmployeesListComponent = ({}) => {
  const theme = useTheme()
  return (
    <TableWrap theme={theme} className="main-table-wrap">
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="user-img-cell">
                <Icon name="UserIcon" />
              </TableCell>
              <TableCell>First/Last Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell className="user-img-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={''}
                    startAdornment={''}
                    endAdornment={''}
                    id={''}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <TableRow className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="user-img-cell">
                <Box theme={theme} className="user-image">
                  <img
                    src={require('../../../../shared/assets/images/table-user-img.png')}
                    alt="User Image"
                  />
                </Box>
              </TableCell>
              <TableCell>Solly Cheevers</TableCell>
              <TableCell>solly.cheevers@sample.com</TableCell>
              <TableCell>000-000-0000</TableCell>
              <TableCell>Sample Role</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={'basic'}
                  label="Active"
                  switchEnabled={true}
                ></Switch>
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell className="checkbox-cell">
                <Checkbox theme={theme} label=""></Checkbox>
              </TableCell>
              <TableCell className="user-img-cell">
                <Box theme={theme} className="user-image">
                  <img
                    src={require('../../../../shared/assets/images/table-user-img.png')}
                    alt="User Image"
                  />
                </Box>
              </TableCell>
              <TableCell>Murvyn Bolus</TableCell>
              <TableCell>murvyn.bolus@sample.com</TableCell>
              <TableCell>000-000-0000</TableCell>
              <TableCell>Sample Role</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={'basic'}
                  label="Inactive"
                  switchEnabled={false}
                ></Switch>
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="EditIcon" />{' '}
                          {'Edit'}{' '}
                        </>
                      )
                    },
                    {
                      optionText: (
                        <>
                          {' '}
                          <Icon className="menu-icon" name="DeleteIcon" />{' '}
                          {'Delete'}{' '}
                        </>
                      )
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        }
      />
    </TableWrap>
  )
}

EmployeesListComponent.propTypes = {}

EmployeesListComponent.defaultProps = {}
