import { type Theme } from '@mui/material'
import { Box, Icon, Menu, Notification } from '@ntpkunity/controls'
import {
  NotificationFilterBy,
  NOTIFICATION_EVENT_TYPE
} from '@shared/constants'
import {
  ADMIN_NOTIFICATION_CHANNEL,
  ADMIN_NOTIFICATION_EVENT
} from '@shared/constants/pusher'
import { type INotificationsBackend } from '@shared/typings'
import { useCallback, useEffect, type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { type StoreContextType } from 'store/configs'
import { ACTION_TYPE } from 'store/ducks/notifications'
import { usePusher } from 'store/ducks/pusher'
import { useStoreContext } from 'store/storeContext'
import { CustomizedNotifications } from './NotificationComponentStyle'
import {
  LIMIT,
  useGetAllNotifications,
  useMarkNotificationRead
} from './services/NotificationService'
import { useInView } from 'react-intersection-observer'

export interface INotificationComponentProps {
  theme: Theme
}

const THRESHOLD = LIMIT - 10 // 5 -> WHEN 10TH RECORD IS VISIBLE
const ALTERNATE_THRESHOLD = LIMIT - 2 // 13 -> WHEN 2ND RECORD IS VISIBLE

export const NotificationComponent: FC<INotificationComponentProps> = ({
  theme
}) => {
  const navigate = useNavigate()
  const [allNotificationsRef, allNotificationsTargetRefInView] = useInView()
  const [unreadNotificationsRef, unreadNotificationsTargetRefInView] =
    useInView()

  const {
    states: { notificationState },
    actions: { notificationDispatch }
  }: StoreContextType = useStoreContext()

  const {
    totalUnreadMessages,
    notifications,
    unreadNotifications,
    mentionNotifications,
    orderNotifications
  } = notificationState

  const {
    data: allNotificationsData,
    isFetchedAfterMount: allNotificationsFetchedAfterMount,
    isFetching: isFetchingAllNotifications,
    isFetchingNextPage: isFetchingNextPageAllNotifications,
    fetchNextPage: fetchNextPageAllNotifications,
    hasNextPage: hasNextPageAllNotifications
  } = useGetAllNotifications(NotificationFilterBy.ALL)

  const {
    data: unreadNotificationsData,
    isFetchedAfterMount: unreadNotificationsFetchedAfterMount,
    isFetching: isFetchingUnreadNotifications,
    isFetchingNextPage: isFetchingNextPageUnreadNotifications,
    fetchNextPage: fetchNextPageUnreadNotifications,
    hasNextPage: hasNextPageUnreadNotifications
  } = useGetAllNotifications(NotificationFilterBy.UNREAD)

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationRead()

  const handleNotificationClick = (
    notification: INotificationsBackend
  ): void => {
    const { is_read: isRead } = notification
    if (!isRead) {
      void markNotificationAsRead(notification)
    }
    notificationDispatch({
      type: ACTION_TYPE.READ_NOTIFICATION,
      payload: {
        notification,
        navigate: (cta: string | undefined) => {
          if ((cta ?? '').length > 0) {
            navigate(cta ?? '')
          }
        }
      }
    })
  }

  const handlePusherNotification = useCallback(
    (data: INotificationsBackend) => {
      notificationDispatch({
        type: ACTION_TYPE.REFETCH_NOTIFICATIONS
      })
    },
    []
  )

  usePusher(
    ADMIN_NOTIFICATION_CHANNEL,
    `${ADMIN_NOTIFICATION_EVENT}`,
    handlePusherNotification
  )

  useEffect(() => {
    if (allNotificationsTargetRefInView) {
      fetchNextPageAllNotifications()
    }
  }, [allNotificationsTargetRefInView])

  useEffect(() => {
    if (unreadNotificationsTargetRefInView) {
      fetchNextPageUnreadNotifications()
    }
  }, [unreadNotificationsTargetRefInView])

  useEffect(() => {
    if (unreadNotificationsData && unreadNotificationsFetchedAfterMount) {
      const notifications = unreadNotificationsData.pages.flatMap(
        (page, pageIndex) => {
          return page.notifications.map((notification, notificationIndex) => {
            const totalRecordsSoFar =
              unreadNotificationsData.pages.length * LIMIT
            const notificationOverallIndex =
              notificationIndex + pageIndex * LIMIT
            const indexAtApiToHit = totalRecordsSoFar - THRESHOLD
            const alternateIndexAtApiToHit =
              totalRecordsSoFar - ALTERNATE_THRESHOLD

            return {
              ...notification,
              action: NOTIFICATION_EVENT_TYPE[notification.event_type] ?? null,
              ref:
                Math.floor(indexAtApiToHit) === notificationOverallIndex ||
                Math.floor(alternateIndexAtApiToHit) ===
                  notificationOverallIndex
                  ? unreadNotificationsRef
                  : null
            }
          })
        }
      )

      const totalUnreadMessages =
        (unreadNotificationsData?.pages.length ?? 0) > 0
          ? unreadNotificationsData.pages[
            unreadNotificationsData.pages.length - 1
          ].total_unread
          : 0

      notificationDispatch({
        type: ACTION_TYPE.SET_UNREAD_NOTIFICATIONS,
        payload: {
          notifications,
          totalUnreadMessages,
          handleClick: handleNotificationClick
        }
      })
    }
  }, [unreadNotificationsData])

  useEffect(() => {
    if (allNotificationsData && allNotificationsFetchedAfterMount) {
      const notifications = allNotificationsData.pages.flatMap(
        (page, pageIndex) => {
          return page.notifications.map((notification, notificationIndex) => {
            const totalRecordsSoFar = allNotificationsData.pages.length * LIMIT
            const notificationOverallIndex =
              notificationIndex + pageIndex * LIMIT
            const indexAtApiToHit = totalRecordsSoFar - THRESHOLD
            const alternateIndexAtApiToHit =
              totalRecordsSoFar - ALTERNATE_THRESHOLD

            return {
              ...notification,
              action: NOTIFICATION_EVENT_TYPE[notification.event_type] ?? null,
              ref:
                Math.floor(indexAtApiToHit) === notificationOverallIndex ||
                Math.floor(alternateIndexAtApiToHit) ===
                  notificationOverallIndex
                  ? allNotificationsRef
                  : null
            }
          })
        }
      )

      const totalUnreadMessages =
        (allNotificationsData?.pages.length ?? 0) > 0
          ? allNotificationsData.pages[allNotificationsData.pages.length - 1]
            .total_unread
          : 0

      notificationDispatch({
        type: ACTION_TYPE.SET_ALL_NOTIFICATIONS,
        payload: {
          notifications,
          totalUnreadMessages,
          handleClick: handleNotificationClick
        }
      })
    }
  }, [allNotificationsData])

  return (
    <CustomizedNotifications theme={theme} className="notifications">
      <Menu
        theme={theme}
        disablePortal={true}
        customChildren={
          <>
            <Box
              theme={theme}
              className="notification-dropdown-wrap"
              maxWidth={560}
            >
              <Notification
                title="Notifications"
                theme={theme}
                tabbedNotifications={true}
                items={notifications}
                notificationsLoading={
                  hasNextPageAllNotifications &&
                  isFetchingNextPageAllNotifications
                }
                unreadItems={unreadNotifications}
                unreadNotificationsLoading={
                  hasNextPageUnreadNotifications &&
                  isFetchingNextPageUnreadNotifications
                }
                mentionsItems={mentionNotifications}
                mentionsNotificationsLoading={false}
                ordersItems={orderNotifications}
                ordersNotificationsLoading={false}
              />
            </Box>
          </>
        }
        options={[]}
        render={(cb) => (
          <>
            <a
              className="nav-link link-notification"
              href="javascript:void(0)"
              onClick={cb}
            >
              <Icon name="BellIcon" />
              {totalUnreadMessages > 0 ? (
                <span className="cart-counter">
                  {/* {totalUnreadMessages > 99 ? '99+' : totalUnreadMessages} */}
                </span>
              ) : null}
            </a>
          </>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </CustomizedNotifications>
  )
}
