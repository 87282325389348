import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import ArrowUp from '../../../../shared/assets/images/ic-arrow-up-green.svg'
import ArrowDown from '../../../../shared/assets/images/ic-arrow-down-red.svg'
import { CounterWidget } from '@shared/components/CounterWidget/counterWidget'
import {} from './ChurnedSubscribersStyle'

export const ChurnedSubscribersComponent = ({}) => {
  const theme = useTheme()
  return (
    <CounterWidget
      theme={theme}
      title={'Churned Subscribers'}
      counterText={'16%'}
      rankImage={true}
      rankImageDown
      rankText={'00%'}
      rankTextDanger={true}
    />
  )
}

ChurnedSubscribersComponent.propTypes = {}

ChurnedSubscribersComponent.defaultProps = {}
