import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { MainContent } from './PreviewTemplatesStyle'
import {
  FaqsSectionComponent,
  FeatureSectionComponent,
  HowToConnectSectionComponent,
  IntroductionSectionComponent,
  PricingPackagesSectionComponent,
  SupportSectionComponent,
  TestimonialSectionComponent
} from '@modules/PreviewTemplates/components'
import { useGetProduct } from '@modules/ProductConfigurations/services'
import { useSearchParams } from 'react-router-dom'
import { setFavicon } from 'favicon'

export const PreviewTemplatesPage = ({}) => {
  const theme = useTheme()
  setFavicon()
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId')
  const productName = searchParams.get('productName')
  document.title = productName
  const { data } = useGetProduct(productId)
  return (
    <MainContent theme={theme} className="main-content">
      <Box theme={theme} pb={{ xs: 10, md: 15 }} pt={{ xs: 10, md: 15 }}>
        <IntroductionSectionComponent introduction={data?.introduction} />
      </Box>
      {data?.prices?.length > 0
        ? (
        <Box theme={theme} pb={{ xs: 10, md: 15 }} pt={{ xs: 10, md: 15 }}>
          <PricingPackagesSectionComponent prices={data?.prices} />
        </Box>
          )
        : null}
      {data?.features?.length > 0
        ? (
        <Box theme={theme} pb={{ xs: 10, md: 15 }} pt={{ xs: 10, md: 15 }}>
          <FeatureSectionComponent features={data?.features} />
        </Box>
          )
        : null}
      <Box theme={theme}>
        <HowToConnectSectionComponent
          onboarding={data?.how_to_connect}
          name={data?.introduction?.name ?? ''}
        />
      </Box>
      <Box theme={theme} pb={{ xs: 10, md: 15 }} pt={{ xs: 10, md: 15 }}>
        <SupportSectionComponent support={data?.support} />
      </Box>
      {data?.testimonials?.length > 0
        ? (
        <Box theme={theme} pb={{ xs: 10, md: 15 }} pt={{ xs: 10, md: 15 }}>
          <TestimonialSectionComponent testimonial={data?.testimonials} />
        </Box>
          )
        : null}
      {data?.faqs?.length > 0
        ? (
        <Box theme={theme} pb={{ xs: 10, md: 15 }} pt={{ xs: 10, md: 15 }}>
          <FaqsSectionComponent faqs={data?.faqs} />
        </Box>
          )
        : null}
    </MainContent>
  )
}
