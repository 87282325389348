import { Box } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material'

export const CustomizedNotifications = styled(Box)(({ theme }) => ({
  '&.notifications': {
    '.nav-link': {
      '&.link-notification': {
        position: 'relative',
        display: 'block',
        marginTop: '4px',
        '.cart-counter': {
          fontFamily: theme.typography.fontFamily,
          width: 8,
          height: 8,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 10,
          lineHeight: '9px',
          fontWeight: theme.typography.fontWeightBold,
          position: 'absolute',
          top: -2,
          right: 2,
          boxShadow: '0px 0px 0px 1px #fff'
        }
      }
    },
    '.MuiMenu-paper': {
      minWidth: 320,
      maxHeight: '486px !important'
    }
  }
}))
