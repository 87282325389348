import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const WidgetCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.widget-card-wrap': {
    height: '100%',
    '.widget-title': {
      fontSize: '20px',
      lineHeight: '32px'
    },
    '.graph-widget-container': {
      backgroundColor: theme.palette.grey[50],
      minHeight: 320,
      borderRadius: theme.shape.borderRadius
    }
  }
}))
