import { useTheme } from '@mui/material'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import {
  useFieldArray,
  useFormContext,
  type UseFormReturn,
  useWatch
} from 'react-hook-form'
import { type IFormData } from '@shared/typings'
import { Testimonial } from './Testimonial'
import { testimonialsInitialValue } from '@shared/constants/productConfigurations'
import { useModal } from 'react-modal-hook'
import { type FC, useEffect, useMemo, useRef, useState } from 'react'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import {
  ButtonType,
  deleteConfirmationMessage,
  DialogHeading,
  IconType
} from '@shared/constants'
import DraggableAccordionCommponent from '@shared/components/DraggableAccordion/DraggableAccordionCommponent'
import { useDeleteFile } from '@modules/ProductConfigurations/services'
import { S3FileKey } from '@shared/helper'
import { ContentAreaWrap } from '@shared/style/contentStyle'

export enum TestimonialFields {
  TESTIMONIAL_KEY = 'testimonials',
  COMPANY_NAME = 'company_name',
  DESCRIPTION = 'description',
  PERSON_NAME = 'person_name',
  PERSON_DESIGNATION = 'person_designation',
  PROFILE_PICTURE = 'profile_picture',
  COMPANY_LOGO = 'company_logo',
  SORT_ORDER = 'sort_order',
  METADATA = 'meta_data',
  PROFILE_PICTURE_METADATA = 'meta_data.profile_picture',
  COMPANY_LOGO__METADATA = 'meta_data.company_logo',
}
interface ITestimonialsComponentProps {
  filesToBeDeleted: string[]
}

export const TestimonialsComponent: FC<ITestimonialsComponentProps> = ({
  filesToBeDeleted
}) => {
  const theme = useTheme()
  const removeIndexRef = useRef<number>()

  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext()
  const { fields, remove, append, move } = useFieldArray({
    name: TestimonialFields.TESTIMONIAL_KEY,
    control
  })
  const [expanded, setExpanded] = useState<string | false>(
    `panel-${fields.length - 1}`
  )

  const deleteFile = (formFieldId) => {
    const uploadedFileKey = S3FileKey(getValues(formFieldId))
    if ((uploadedFileKey ?? '').length > 0) {
      filesToBeDeleted.push(uploadedFileKey)
    }
  }

  const [showDeletePopup, hideDeletePopup] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DELETE}
        iconName={IconType.ALERT}
        title={DialogHeading.DELETE}
        message={deleteConfirmationMessage}
        onConfirm={() => {
          deleteFile(
            `${TestimonialFields.TESTIMONIAL_KEY}.${removeIndexRef.current}.${TestimonialFields.PROFILE_PICTURE}`
          )
          deleteFile(
            `${TestimonialFields.TESTIMONIAL_KEY}.${removeIndexRef.current}.${TestimonialFields.COMPANY_LOGO}`
          )
          remove(removeIndexRef.current)
          hideDeletePopup()
        }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={hideDeletePopup}
        onCloseDialog={hideDeletePopup}
        danger
      />
    ),
    [removeIndexRef.current, remove]
  )

  const deleteTestimonialItem = (index: number) => {
    removeIndexRef.current = index
    showDeletePopup()
  }

  const addTestimonial = () => {
    append({ ...testimonialsInitialValue })
  }

  useEffect(() => {
    setExpanded(`panel-${fields.length - 1}`)
  }, [fields.length])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const moveTestimonialListItem = (dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex)
    setValue('makeFormDirty', false, { shouldDirty: true })
  }

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <Box theme={theme} className="accordion-panel-wrap">
        {fields.map((field, index: number) => (
          <DraggableAccordionCommponent
            key={field.id}
            index={index}
            theme={theme}
            varient="grouped"
            shouldCollapse={true}
            items={{
              content: (
                <>
                  <Testimonial
                    key={field.id}
                    id={field.id}
                    index={index}
                    filesToBeDeleted={filesToBeDeleted}
                  />
                </>
              ),
              title: (
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component={'p'}
                  className="fw-sbold text-body1"
                >
                  {getValues(
                    `${TestimonialFields.TESTIMONIAL_KEY}.${index}.${TestimonialFields.PERSON_NAME}`
                  )}
                </Typography>
              ),
              key: field.id,
              isExpanded: expanded === `panel-${index}`,
              handleChange,
              moveListItem: moveTestimonialListItem,
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={
                      <Icon
                        name="DeleteIcon"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          deleteTestimonialItem(index)
                          e.stopPropagation()
                        }}
                      />
                    }
                  />
                </>
              )
            }}
          />
        ))}
      </Box>
      <Box theme={theme} mt={3}>
        <Button
          theme={theme}
          secondary
          fullWidth
          text="Add New Testimonial"
          onClick={addTestimonial}
        />
      </Box>
    </ContentAreaWrap>
  )
}
