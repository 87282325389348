import { formatedDate } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { useQuery } from 'react-query'

const BASE_URL = process.env.BASE_URL

const TOTAL_SIGNUP_DASHBOARD = 'marketplace/admin/dashboard/total-signups'
export const useGetTotalSignUpDashboard = (
  startDate: Date,
  endDate: Date
): any => {
  const STARTDATE = formatedDate(startDate, 'YYYY-MM-DD')
  const ENDDATE = formatedDate(endDate, 'YYYY-MM-DD')
  let url = `${TOTAL_SIGNUP_DASHBOARD}`
  if (STARTDATE.length > 0 && ENDDATE.length > 0) {
    url += `?start_date=${STARTDATE}&end_date=${ENDDATE}`
  }

  const { data, error, isLoading, isError, isFetching } = useQuery(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: STARTDATE.length > 0 && ENDDATE.length > 0
    }
  )
  return { data, error, isLoading, isError, isFetching }
}
