import { type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type IBillingDetailsFilter } from '@shared/typings'
import { useQuery } from 'react-query'
const BASE_URL = process.env.BASE_URL
const BILLING_DETAILS = 'marketplace/admin/dashboard/billing-details?'

export const useGetBillingDetails = (
  columnFilters: string
): {
  data: IBillingDetailsFilter | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  let url = BILLING_DETAILS
  if ((columnFilters ?? '').length > 0) {
    url += columnFilters
  }
  const { data, error, isLoading } = useQuery(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    {
      refetchOnWindowFocus: false,
      enabled: !columnFilters.includes('Invalid Date')
    }
  )
  return { data, error, isLoading }
}
