import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import Basic from '@shared/assets/images/basic-pkg-img.svg'
import Starter from '@shared/assets/images/starter-pkg-img.svg'
import Standard from '@shared/assets/images/standard-pkg-img.svg'
import Premium from '@shared/assets/images/premium-pkg-img.svg'

export const ContentAreaWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.content-area-wrap': {
    '.package-img-wrap': {
      '.custom-radio-wrap': {
        width: '100%',
        '.MuiFormGroup-row': {
          gap: 8,
          '.u-custom-control-label': {
            marginRight: 0,
            marginBottom: 0,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            color: theme.palette.grey[600],
            backgroundColor: 'transparent',
            lineHeight: 'normal',
            flexBasis: '48px',
            maxWidth: '48px',
            height: 48,
            borderRadius: 8,
            '.custom-radio': {
              marginRight: 0,
              position: 'relative',
              svg: {
                display: 'none'
              },
              '&::before': {
                display: 'block',
                content: '" "',
                width: 32,
                height: 32,
                backgroundPosition: 'center center',
                backgroundSize: 32,
                zIndex: 1,
                position: 'relative',
                backgroundRepeat: 'no-repeat'
              },
              '&.Mui-checked': {
                color: theme.palette.grey[900]
              }
            },
            '&:nth-child(1)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${Starter})`
                }
              }
            },
            '&:nth-child(2)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${Basic})`
                }
              }
            },
            '&:nth-child(3)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${Standard})`
                }
              }
            },
            '&:nth-child(4)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${Premium})`
                }
              }
            },
            '&.selected': {
              borderColor: theme.palette.primary.main,
              opacity: 1
            },
            '&.Mui-disabled': {
              opacity: 0.2
            },
            [theme.breakpoints.down('sm')]: {
              flexShrink: 0
            }
          }
        },
        '.u-error-text': {
          marginTop: 8
        }
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedStart': {
          '.endornment': {
            color: theme.palette.text.secondary
          },
          '~': {
            '.u-input-label': {
              transform: 'translate(14px, 12px) scale(1)'
            }
          }
        },
        '&.MuiInputBase-adornedEnd': {
          '.endornment': {
            color: theme.palette.text.secondary
          },
          '.end-endornment': {
            opacity: 0
          },
          '.MuiInputBase-input': {
            padding: '12px 8px 12px 16px'
          }
        }
      },
      '.adornment-focus': {
        '.u-form-control': {
          '&.MuiInputBase-adornedEnd': {
            '.MuiInputBase-input': {
              '&:not(:placeholder-shown)': {
                '+': {
                  '.end-endornment': {
                    opacity: 1
                  }
                }
              }
            },
            '&.Mui-focused': {
              '.end-endornment': {
                opacity: 1
              }
            }
          }
        }
      }
    },
    '.dual-endornament': {
      '.u-form-group': {
        '.u-form-control': {
          '&.MuiInputBase-adornedEnd': {
            '.MuiInputBase-input': {
              padding: '12px 8px 12px 0px'
            }
          }
        }
      }
    }
  }
}))
