import React from 'react'
import { Typography } from '@ntpkunity/controls'
import { type IListWidgetProps } from './listWidget.types'
import { WidgetCardWrap } from './listWidgetStyle'

export const ListWidget: React.FC<IListWidgetProps> = ({
  widgetTitle,
  children,
  theme
}) => {
  return (
    <WidgetCardWrap theme={theme} className="widget-card-wrap">
      <Typography
        theme={theme}
        variant="h3"
        component="h3"
        className="widget-title fw-sbold"
        mb={3}
      >
        {widgetTitle}
      </Typography>
      {children}
    </WidgetCardWrap>
  )
}
