import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { BrandingImage } from './brandingStyle'

export const BrandingComponent = ({}) => {
  return (
    <BrandingImage theme={useTheme()} className="branding-img"></BrandingImage>
  )
}

BrandingComponent.propTypes = {}

BrandingComponent.defaultProps = {}
