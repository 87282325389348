import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  Button,
  FileDragDrop,
  Input,
  Select,
  PersistentDrawer
} from '@ntpkunity/controls'

export const AddNewProdcutDrawerComponent = ({ fnCloseDrawer }) => {
  const theme = useTheme()
  return (
    <PersistentDrawer
      title={'Add New Option'}
      openPopUp={true}
      setPopUpState={undefined}
      theme={theme}
      customFooter={
        <Button
          theme={theme}
          primary
          type="submit"
          text="Save Product"
          fullWidth
        />
      }
    >
      <form>
        <FileDragDrop
          theme={theme}
          imageButton={false}
          accept={'image/*'}
          hoverLabel="Drag and drop product image, or browse"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {}}
          onDrop={(event: React.DragEvent<HTMLElement>) => {}}
        ></FileDragDrop>
        <Input
          theme={theme}
          fullWidth={true}
          label="Product Name"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Product Initials"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Product Description"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Input
          theme={theme}
          fullWidth={true}
          label="Product Number"
          type={'text'}
          placeholder="Type here..."
          startAdornment={''}
          endAdornment={''}
          id={''}
        />
        <Select
          theme={theme}
          items={[
            { text: 'Option 1', value: '1' },
            { text: 'Option 2', value: '2' }
          ]}
          label="Status"
          sxProps={undefined}
        />
      </form>
    </PersistentDrawer>
  )
}

AddNewProdcutDrawerComponent.propTypes = {
  fnCloseDrawer: PropTypes.func
}

AddNewProdcutDrawerComponent.defaultProps = {}
