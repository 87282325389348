import { useTheme } from '@mui/material'
import { Box, Grid, Input, Button, Icon } from '@ntpkunity/controls'
import { ContentAreaWrap } from './HowToConnectStyle'
import {
  Controller,
  type FieldArrayWithId,
  useFieldArray,
  useFormContext,
  type UseFormReturn
} from 'react-hook-form'
import { type IFormData, IProductUsageSteps } from '@shared/typings'
import { ProductUsageComponent } from './ProductUsageComponent'
import { productUsageStepInitialValue } from '@shared/constants/productConfigurations'
import { useStoreContext } from 'store/storeContext'
import { howToConnectExcessMsg } from '@shared/constants'

export enum HowToConnectFields {
  KEY = 'how_to_connect',
  MAIN_TEXT = 'how_to_connect.main_text',
  VIDEO_URL = 'how_to_connect.video_url',
  SUB_TEXT = 'how_to_connect.sub_text',
  PRODUCT_USAGE_STEPS = 'how_to_connect.product_usage_steps',
}

export const messages = {
  label: {
    mainText: 'Headline',
    videoUrl: 'Video Link',
    subText: 'Sub-Headline'
  },
  placeholder: {
    mainText: 'Sample Headline',
    videoUrl: 'www.sample.com',
    subText: 'Sample text...',
    stepHeadline: 'Sample Headline',
    stepDescription: 'Sample description text...'
  }
}

export const HowToConnectComponent = ({}) => {
  const theme = useTheme()

  const {
    actions: { setToaster }
  } = useStoreContext()

  const {
    control,
    formState: { errors }
  } = useFormContext<IFormData>()
  const { fields, remove, append } = useFieldArray({
    name: HowToConnectFields.PRODUCT_USAGE_STEPS,
    control
  })

  const addProductUsageStep = () => {
    if (fields.length > 4) {
      setToaster({
        isDisplay: true,
        message: howToConnectExcessMsg,
        type: ''
      })
    } else {
      append({ ...productUsageStepInitialValue })
    }
  }

  return (
    <ContentAreaWrap theme={theme} className="content-area-wrap">
      <Grid theme={theme} container item spacing={3}>
        <Grid theme={theme} item xs={12} md={6}>
          <Controller
            name={HowToConnectFields.MAIN_TEXT}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                theme={theme}
                fullWidth={true}
                type={'text'}
                label={messages.label.mainText}
                placeholder={messages.placeholder.mainText}
                error={errors?.how_to_connect?.main_text?.message}
                id={''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={6}>
          <Controller
            name={HowToConnectFields.VIDEO_URL}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                theme={theme}
                fullWidth={true}
                type={'text'}
                label={messages.label.videoUrl}
                placeholder={messages.placeholder.videoUrl}
                error={errors?.how_to_connect?.video_url?.message}
                id={''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={HowToConnectFields.SUB_TEXT}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                theme={theme}
                fullWidth={true}
                type={'text'}
                label={messages.label.subText}
                placeholder={messages.placeholder.subText}
                error={errors?.how_to_connect?.sub_text?.message}
                id={''}
              />
            )}
          />
        </Grid>
      </Grid>
      {fields.map(
        (
          field: FieldArrayWithId<
          IFormData,
          HowToConnectFields.PRODUCT_USAGE_STEPS,
          'id'
          >,
          index: number
        ) => (
          <ProductUsageComponent key={field.id} index={index} remove={remove} />
        )
      )}
      <Box theme={theme} mt={3}>
        <Button
          theme={theme}
          secondary
          fullWidth
          text="Add New Step"
          onClick={addProductUsageStep}
        />
      </Box>
    </ContentAreaWrap>
  )
}
