import {
  GET_PRODUCT,
  useBulkDeleteFiles,
  useCreateProduct,
  useUpdateProduct
} from '@modules/ProductConfigurations/services'
import { useTheme } from '@mui/material'
import { Button, Icon, Typography } from '@ntpkunity/controls'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { APP_ROUTES } from '@shared/configuration'
import {
  ButtonType,
  createProductWithErrorMessage,
  createProductWithNoErrorMessage,
  IconType,
  leavePageAlertTitle,
  leavePageMessage,
  liveProductSuccessMessage,
  mandatoryProductName
} from '@shared/constants'
import { S3FileKey, openInNextWindow } from '@shared/helper'
import { type IBackendFormData, type IFormData } from '@shared/typings'
import { useCallback, useEffect, useRef, useState, type FC } from 'react'
import { useFormContext, type UseFormReturn, useWatch } from 'react-hook-form'
import { useModal } from 'react-modal-hook'
import { useQueryClient } from 'react-query'
import {
  unstable_useBlocker as useBlocker,
  useBeforeUnload,
  useNavigate,
  useSearchParams,
  type unstable_Blocker as Blocker,
  createSearchParams
} from 'react-router-dom'
import { useStoreContext } from 'store/storeContext'
import { } from './productConfigPageHeaderStyle'

const mapIDs = (object1, object2) => {
  const keysToMap = [
    'introduction',
    'prices',
    'features',
    'how_to_connect',
    'faqs',
    'testimonials',
    'customers_logos'
  ]

  keysToMap.forEach((key) => {
    if (Array.isArray(object1[key])) {
      object1[key].forEach((item, index) => {
        item.id = object2[key][index]?.id ?? null
        item.product_id = object2[key][index]?.product_id ?? null
        key === 'prices'
          ? (item.stripe_price_id = object2[key][index]?.stripe_price_id)
          : null
      })
    } else if (object1[key] && object2[key]) {
      object1[key].id = object2[key]?.id ?? null
      key !== 'introduction'
        ? (object1[key].product_id = object2[key]?.product_id ?? null)
        : null
    }
  })

  return object1
}
interface IProductConfigPageHeaderComponentProps {
  filesToBeDeleted: string[]
  filesToBeDeletedRef: React.MutableRefObject<string[]>
}

export const ProductConfigPageHeaderComponent: FC<
IProductConfigPageHeaderComponentProps
> = ({ filesToBeDeleted, filesToBeDeletedRef }) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const productId = searchParams.get('productId')

  const [triggered, setTriggered] = useState<boolean>()

  const {
    trigger,
    formState: { isDirty, errors, isValidating, isValid },
    reset,
    getValues
  } = useFormContext<IFormData>()
  const productName = useWatch({ name: 'introduction.name' })

  const { mutate: createProduct, isLoading: creatingProduct } =
    useCreateProduct()
  const { mutate: updateProduct, isLoading: updatingProduct } =
    useUpdateProduct()
  const { mutate: deleteFiles } = useBulkDeleteFiles()

  const isLoading = creatingProduct || updatingProduct

  const {
    actions: { setToaster }
  } = useStoreContext()

  useEffect(() => {
    if (triggered && !isValidating) {
      onSubmit()
    }
  }, [triggered, isValidating])

  const onSubmit = () => {
    const dataWithErrors = getValues()
    const data = getValues()
    const { meta_data: introductionMetadata, ...introduction } =
      data.introduction
    const { meta_data: supportMetadata, ...support } = data.support
    const metadata = { ...introductionMetadata, ...supportMetadata }
    data.introduction = { ...introduction, ...support, meta_data: metadata }
    delete data.support
    delete data.makeFormDirty

    data.prices = data.prices
      ?.filter((_, index) => !errors.prices?.[index])
      ?.map((pricingPackage, index) => ({
        ...pricingPackage,
        package_order: index
      }))

    data.features = data.features
      ?.filter((_, index) => !errors.features?.[index])
      ?.map((feature, index) => ({ ...feature, sort_order: index }))

    data.faqs = data.faqs
      ?.filter((_, index) => !errors.faqs?.[index])
      ?.map((faq, index) => ({ ...faq, sort_order: index }))

    data.testimonials = data.testimonials
      ?.filter((_, index) => !errors.testimonials?.[index])
      ?.map((testimonial, index) => ({ ...testimonial, sort_order: index }))

    data.customers_logos = data.customers_logos
      ?.filter((_, index) => !errors.customers_logos?.[index])
      ?.map((customers_logo, index) => ({
        ...customers_logo,
        sort_order: index
      }))

    if ((productId ?? '').length > 0) {
      updateProduct(
        { data, productId },
        {
          onSuccess(data: IBackendFormData) {
            (async () => {
              const _data = mapIDs(dataWithErrors, data)
              queryClient.setQueryData(
                [GET_PRODUCT, data?.introduction?.id],
                _data
              )
              reset(_data)
              await trigger()
              setToaster({
                isDisplay: true,
                message: data.introduction.is_active
                  ? liveProductSuccessMessage
                  : isValid
                    ? createProductWithNoErrorMessage
                    : createProductWithErrorMessage,
                type: '',
                showIcon: true
              })
              filesToBeDeleted?.length > 0
                ? deleteFiles(
                  { keys: [...new Set(filesToBeDeleted)] },
                  {
                    onSuccess() {
                      filesToBeDeletedRef.current = []
                    }
                  }
                )
                : null
            })()
          },
          onError(error) {
            setToaster({
              isDisplay: true,
              message: error?.message,
              type: 'error'
            })
          },
          onSettled(data) {
            data?.introduction?.id
              ? setSearchParams({
                productId: (data?.introduction?.id).toString()
              })
              : null
          }
        }
      )
    } else if (data.introduction.name) {
      createProduct(data, {
        onSuccess(data: IBackendFormData) {
          (async () => {
            const _data = mapIDs(dataWithErrors, data)
            queryClient.setQueryData(
              [GET_PRODUCT, data?.introduction?.id],
              _data
            )
            reset(_data)
            await trigger()
            setToaster({
              isDisplay: true,
              message: data.introduction.is_active
                ? liveProductSuccessMessage
                : isValid
                  ? createProductWithNoErrorMessage
                  : createProductWithErrorMessage,
              type: '',
              showIcon: true
            })
            filesToBeDeleted?.length > 0
              ? deleteFiles(
                { keys: [...new Set(filesToBeDeleted)] },
                {
                  onSuccess() {
                    filesToBeDeletedRef.current = []
                  }
                }
              )
              : null
          })()
        },
        onError(error) {
          setToaster({
            isDisplay: true,
            message: error?.message,
            type: 'error'
          })
        },
        onSettled(data) {
          data?.introduction?.id
            ? setSearchParams({
              productId: (data?.introduction?.id).toString()
            })
            : null
        }
      })
    }
    setTriggered(false)
  }

  const blocker: Blocker = useBlocker(isDirty)

  const onCancelConfirm = () => {
    blocker.proceed?.()
    hideBackPopup()
  }

  const onCloseDialog = () => {
    blocker.reset?.()
    hideBackPopup()
  }

  const [showBackPopup, hideBackPopup] = useModal(
    () => (
      <ConfirmationDialog
        danger
        buttonText={ButtonType.LEAVE}
        iconName={IconType.ALERT}
        title={leavePageAlertTitle}
        message={productName ? leavePageMessage : mandatoryProductName}
        onConfirm={onCancelConfirm}
        cancelButtonText={productName ? ButtonType.SAVE : ButtonType.STAY}
        onCancel={productName ? confirm : hideBackPopup}
        onCloseDialog={onCloseDialog}
      />
    ),
    [blocker]
  )

  const confirm = async () => {
    await trigger()
    setTriggered(true)
    blocker.proceed?.()
    hideBackPopup()
  }

  const beforeOnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
        return (event.returnValue = '')
      } else {
        return null
      }
    },
    [isDirty]
  )

  useBeforeUnload(beforeOnload, { capture: true })

  useEffect(() => {
    // Reset the   if the user cleans the form
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset()
    } else if (blocker.state === 'blocked') {
      showBackPopup()
    }
  }, [blocker, isDirty])

  const handleClick = () => {
    const productId = { productId: getValues('introduction.id').toString() }
    const productName = {
      productName: getValues('introduction.name').toString()
    }
    const searchParams = createSearchParams({ ...productId, ...productName })
    const url = `${APP_ROUTES.PREVIEW.PREVIEW_PAGE}?${searchParams}`
    openInNextWindow(url)
  }

  return (
    <CustomPageHeader
      theme={theme}
      variant="border"
      onBackBtn={() => { navigate(APP_ROUTES.PRODUCT_MANAGEMENT.PRODUCT_LIST) }}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            {productName || 'Product Name'}
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button
            data-testid="product-header-cancel-btn"
            secondary
            theme={theme}
            disabled={!getValues('introduction.id')}
            iconText={<Icon name="IcView" onClick={handleClick} />}
          />
          {productName && isDirty && (
            <>
              <Button
                data-testid="product-header-save-btn"
                primary
                theme={theme}
                text="Save Changes"
                type="button"
                disabled={isLoading}
                startIcon={
                  isLoading && <img src={DisableLoader} alt="Loader" />
                }
                onClick={async () => {
                  await trigger()
                  setTriggered(true)
                }}
              />
            </>
          )}
        </>
      }
    />
  )
}
