import { type AppError } from '@shared/helper'
import Http from '@shared/helper/http-api'
import { type IFetchProducts, type IUpdateProducts } from '@shared/typings'
import { useMutation, useQuery, useQueryClient } from 'react-query'
const BASE_URL = process.env.BASE_URL
const FETCH_PRODUCTS = 'marketplace/admin/product/get-all-products'
const UPDATE_PRODUCT_STATUS = 'marketplace/admin/product/update-product-status'

export const useGetProducts = (): {
  data: IFetchProducts[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery(
    FETCH_PRODUCTS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(FETCH_PRODUCTS)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

export const useUpdateProductStatus = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<
  any,
  any,
  IUpdateProducts
  >(
    async (body) => {
      const { productId, comments, status_task, is_active } = body
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.put<any>(
        `${UPDATE_PRODUCT_STATUS}/${productId}`,
        { comments, status_task, is_active }
      )
    },
    {
      onSuccess () {
        void queryClient.invalidateQueries(FETCH_PRODUCTS)
      }
    }
  )
  return { data, isLoading, mutate, error: error?.message }
}
