import { TableCell, TableRow, useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  DataTable,
  Icon,
  Input,
  Menu
} from '@ntpkunity/controls'
import Switch from '@shared/components/Switch/Switch'
import { TableWrap } from './blogTableStyle'
import { DataTableWrap } from '@shared/style/dataTableStyle'
import { TableSkeleton } from '@shared/components/TableSkeleton/TableSkeleton'
import {
  useAddComment,
  useGetAllBlogs,
  useUpdateBlogStatus
} from '@modules/Blog/services/blogService'
import { formatedDate, openInNextWindow } from '@shared/helper'
import {
  BlogCommentType,
  ButtonType,
  DialogHeading,
  EllipsisOptions,
  IconType,
  dateFormat,
  publishMessage,
  statusTask
} from '@shared/constants'
import { useEffect, useRef, useState } from 'react'
import {
  CommentsPopupComponent,
  UnpublishReasonDialogComponent
} from '@modules/ProductManagement/components'
import { useModal } from 'react-modal-hook'
import { type IBlog, type IFetchProducts } from '@shared/typings'
import { ConfirmationDialog } from '@shared/components/Dialog/Confirmation'
import { FETCH_BLOG_COMMENTS } from '../../services/blogService'

export const BlogTableComponent = () => {
  const theme = useTheme()
  const [filteredBlogs, setFilteredBlogs] = useState([])
  const blogIdRef = useRef<number>()
  const { data: blogs, isLoading } = useGetAllBlogs()
  const { mutate: addBlogComment } = useAddComment()
  const { mutate: updateBlogStatus } = useUpdateBlogStatus()

  const [searchQueries, setSearchQueries] = useState({
    title: '',
    author: '',
    created_at: '',
    status: ''
  })

  useEffect(() => {
    if (blogs?.length > 0) {
      setFilteredBlogs(blogs)
    }
  }, [blogs])

  const getData = (column: string, value: string): IBlog[] => {
    return blogs?.filter((blog) =>
      blog[column]?.toLowerCase().includes(value?.toLowerCase())
    )
  }

  const getByDate = (column: string, value: string): IBlog[] => {
    return blogs?.filter((blog) =>
      formatedDate(blog[column], dateFormat)?.includes(value)
    )
  }

  const getByStatus = (value: string): IBlog[] => {
    if (
      'unpublished'.startsWith(
        value.toLowerCase().slice(0, Math.max('unpublished'.length - 1, 1))
      )
    ) {
      return blogs?.filter((x) => !x.is_active)
    } else if (
      'published'.startsWith(
        value.toLowerCase().slice(0, Math.max('published'.length - 1, 1))
      )
    ) {
      return blogs?.filter((x) => x.is_active)
    }
  }

  const handleSearchInputChange = (column) => (value) => {
    setSearchQueries({
      ...searchQueries,
      [column]: value
    })
    let tempBlogs = [...filteredBlogs]
    if (value?.trim() === '') {
      setFilteredBlogs(blogs)
    } else {
      if (column === 'status') {
        tempBlogs = getByStatus(value)
      } else if (column === 'created_at') {
        tempBlogs = getByDate(column, value)
      } else {
        tempBlogs = getData(column, value)
      }

      tempBlogs &&
        setFilteredBlogs(
          tempBlogs.length > 0 ? tempBlogs : value ? [] : filteredBlogs
        )
    }
  }

  const handleOnUnPublish = (unPublishComment, reset) => {
    const data = {
      is_active: false,
      content: unPublishComment,
      status: statusTask.UNPUBLISHED,
      blog_id: blogIdRef.current,
      comment_type: BlogCommentType.PRIVATE
    }
    updateBlogStatus(data, {
      onSuccess () {
        hideUnPublishDialog()
        reset()
      }
    })
  }

  const [showUnPublishDialog, hideUnPublishDialog] = useModal(
    () => (
      <UnpublishReasonDialogComponent
        onSave={handleOnUnPublish}
        cancelClicked={hideUnPublishDialog}
      />
    ),
    [blogIdRef]
  )

  const handleOnPublish = () => {
    const data = {
      is_active: true
    }
    updateBlogStatus(data, {
      onSuccess () {
        hidePublishDialog()
      }
    })
  }

  const [showPublishDialog, hidePublishDialog] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.PUBLISH}
        iconName={IconType.ALERT}
        title={DialogHeading.PUBLISH}
        message={publishMessage}
        onConfirm={() => {
          handleOnPublish()
        }}
        cancelButtonText={ButtonType.CANCEL}
        onCancel={hidePublishDialog}
        onCloseDialog={hidePublishDialog}
      />
    ),
    []
  )

  const [showCommentsPopup, hideCommentsPopup] = useModal(
    () => (
      <CommentsPopupComponent
        hide={hideCommentsPopup}
        onSave={onSave}
        id={blogIdRef.current}
        url={FETCH_BLOG_COMMENTS}
      />
    ),
    [blogIdRef]
  )

  const onSave = (comment, reset, getComments) => {
    const data = {
      content: comment,
      status: statusTask.GENERAL,
      blog_id: blogIdRef.current,
      comment_type: BlogCommentType.PRIVATE
    }
    addBlogComment(data, {
      onSuccess () {
        reset()
        getComments()
      }
    })
  }

  const handleOptionClick = (
    _event,
    key: string,
    value: IFetchProducts
  ): void => {
    switch (key) {
      case EllipsisOptions.VIEW_PAGE: {
        break
      }
      case EllipsisOptions.EDIT: {
        break
      }
      case EllipsisOptions.ADD_COMMENT: {
        blogIdRef.current = value.id
        showCommentsPopup()
        break
      }
    }
  }

  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <TableWrap theme={theme} className="main-table-wrap">
        <span className="scroll-hide spr-border"></span>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell className="checkbox-cell">
                  <Checkbox theme={theme} label={''} />
                </TableCell>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell>
                  Title
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Author Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Date
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Comments
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Status
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      iconText={<Icon name="ArrowUpDown" />}
                    />
                  </span>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell"></TableCell>
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={searchQueries.title}
                      onChange={handleSearchInputChange('title')}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={searchQueries.author}
                      onChange={handleSearchInputChange('author')}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={searchQueries.created_at}
                      onChange={handleSearchInputChange('created_at')}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      id={''}
                      placeholder="Search..."
                      value={searchQueries.status}
                      onChange={handleSearchInputChange('status')}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {isLoading
                ? (
                <TableSkeleton theme={theme} columns={12} rows={4} />
                  )
                : (
                    filteredBlogs?.map((blog, index) => (
                  <TableRow className="child-tr" key={`blog-${index}`}>
                    <TableCell className="checkbox-cell">
                      <Checkbox theme={theme} label={''} />
                    </TableCell>
                    <TableCell className="img-cell">
                      <img
                        className="prd-img"
                        src={blog.image}
                        alt="blog Image"
                      />
                    </TableCell>
                    <TableCell>{blog.title}</TableCell>
                    <TableCell>{blog.author}</TableCell>
                    <TableCell>
                      {formatedDate(blog.created_at, dateFormat)}
                    </TableCell>
                    <TableCell>
                      <a
                        href="javascript:void(0)"
                        className="text-primary link"
                        onClick={() => {
                          blogIdRef.current = blog.id
                          showCommentsPopup()
                        }}
                      >
                        View Comments
                      </a>
                    </TableCell>
                    <TableCell>
                      <Switch
                        theme={theme}
                        varient={'basic'}
                        label={blog?.is_active ? 'Published' : 'Unpublished'}
                        switchEnabled={blog?.is_active}
                        onChange={(e) => {
                          blogIdRef.current = blog.id
                          if (e.target.checked) {
                            showPublishDialog()
                          } else {
                            showUnPublishDialog()
                          }
                        }}
                        id={blog?.title}
                      ></Switch>
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: (
                              <>
                                {' '}
                                <Icon
                                  className="menu-icon"
                                  name="IcView"
                                />{' '}
                                {'View Page'}{' '}
                              </>
                            ),
                            optionkey: EllipsisOptions.VIEW_PAGE,
                            optionValue: blog
                          },
                          {
                            optionText: (
                              <>
                                {' '}
                                <Icon
                                  className="menu-icon"
                                  name="EditIcon"
                                />{' '}
                                {'Edit'}{' '}
                              </>
                            ),
                            optionkey: EllipsisOptions.EDIT,
                            optionValue: blog
                          },
                          {
                            optionText: (
                              <>
                                {' '}
                                <Icon
                                  className="menu-icon"
                                  name="AddIcon"
                                />{' '}
                                {'Add Comment'}{' '}
                              </>
                            ),
                            optionkey: EllipsisOptions.ADD_COMMENT,
                            optionValue: blog
                          }
                        ]}
                        handleOptionClick={handleOptionClick}
                        render={(cb) => (
                          <Button
                            defaultBtn
                            theme={theme}
                            iconText={<Icon name="MoreIcon" />}
                            onClick={cb}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                    ))
                  )}
            </>
          }
        />
      </TableWrap>
    </DataTableWrap>
  )
}
