import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { Button, Icon, Typography } from '@ntpkunity/controls'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@shared/configuration'

export const PageHeaderComponent = ({ fnAddNewProduct, fnCloseDrawer }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <CustomPageHeader
      theme={theme}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Product Management
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button
            theme={theme}
            primary
            startIcon={<Icon name="AddIcon" />}
            text="Add New Product"
            onClick={() => { navigate(APP_ROUTES.PRODUCT_CONFIGURATION.PRODUCT_TABS) }
            }
          ></Button>
        </>
      }
    />
  )
}

PageHeaderComponent.propTypes = {
  fnAddNewProduct: PropTypes.func,
  fnCloseDrawer: PropTypes.func
}

PageHeaderComponent.defaultProps = {}
