import { type Theme } from '@mui/system'
import { type Channel } from 'pusher-js'
import {
  type Action,
  initialNotificationState,
  type INotificationState
} from './ducks/notifications'

export default interface IActions {
  payload: any
  type: string
}

export interface IThemeConfig {
  theme: Theme
}

export interface IPusherChannel {
  userEmail: string
  pusherChannel: Channel
}

export interface IToaster {
  isDisplay: boolean
  type: string
  message: string
  showIcon?: boolean
  duration?: number
}

export interface IBrowserStorage {
  localStorage: any
  sessionStorage: any
}

export interface IUserProfile {
  signup_steps?: string | null
  created_at?: string | null
  state?: string | null
  state_id?: string | null
  updated_at?: string | null
  zip_code?: string | null
  state_county?: string | null
  id?: number | null
  send_newsletter?: boolean | null
  address_line_1?: string | null
  user_id?: number | null
  industry_types_id?: number | null
  address_line_2?: string | null
  company_name?: string | null
  phone?: string | null
  first_name?: string | null
  last_name?: string | null
  other_industry_type?: string | null
  designation?: string | null
  country_id?: string | null
  city?: string | null
  is_deleted?: boolean | null
  is_active?: boolean | null
  email?: string | null
  user_name?: string | null
  password?: string | null
  stripe_customer_id?: string | null
  master_user_id?: number | null
  account_type?: string | null
  is_signup_complete?: boolean | null
  has_agreed_to_terms?: boolean | null
  county_id?: string | null
  business_contact_number?: string | null
  industry_id?: string | null
  contact_number?: string | null
}
// TODO: add relevant types
export interface StoreContextType {
  actions: {
    setTheme: (data: 'default' | 'dark') => unknown
    setSessionStorage: (data: string) => void
    setLocalStorage: (data: string) => void
    // setChannel: (data: string) => void;
    setToaster: (data: IToaster) => void
    setUserProfile: (data: IUserProfile) => void
    // addToCart: (data: any) => void
    // removeFromCart: (id: number) => void
    // updateCart: (id: number) => void
    // addMultipleItemsToCart: (data: any) => void
    notificationDispatch: React.Dispatch<Action>
  }
  states: {
    theme: IThemeConfig | null
    localStorage: any
    sessionStorage: any
    // pusherChannel: IPusherChannel;
    toaster: IToaster | null
    userProfile: IUserProfile | null
    // cart: ICart[] | null | { cart: ICart[] }
    notificationState: INotificationState
  }
}

// TODO: add relevant default values

export const defaultStoreValues = {
  states: {
    theme: null,
    sessionStorage: null,
    localStorage: null,
    pusherChannel: null,
    toaster: null,
    userProfile: null,
    cart: null,
    notificationState: initialNotificationState
  },

  actions: {
    setTheme: (): void => undefined,
    setSessionStorage: (data: any): void => undefined,
    setLocalStorage: (data: any): void => undefined,
    setChannel: (data: string): void => undefined,
    setToaster: (data: IToaster) => undefined,
    setUserProfile: (data: IUserProfile) => undefined,
    addToCart: (data: any): void => undefined,
    removeFromCart: (id: number): void => undefined,
    updateCart: (id: number): void => undefined,
    addMultipleItemsToCart: (data: any): void => undefined,
    notificationDispatch: (data: Action): void => undefined
  }
}
