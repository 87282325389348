import { useGetProfile } from '@modules/ApplicationSetting/services'
import { useTheme } from '@mui/material'
import { CircleLoader } from '@ntpkunity/controls'
import { useEffect } from 'react'
import { useStoreContext } from 'store/storeContext'
import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const FullPageLoader = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.full-page-loader': {
    height: 'calc(100vh - 240px)'
  }
}))

export const GetUserGeneralInfo = ({ children }) => {
  const theme = useTheme()

  const {
    actions: { setUserProfile }
  } = useStoreContext()

  const { data: userProfile, isLoading: userProfileLoading } = useGetProfile()

  useEffect(() => {
    setUserProfile({
      ...userProfile
    })
  }, [userProfile])

  return (
    <>
      {userProfileLoading
        ? (
        <FullPageLoader theme={theme} className="full-page-loader">
          <CircleLoader theme={theme} />
        </FullPageLoader>
          )
        : (
            children
          )}
    </>
  )
}
