import { createTheme } from '@mui/material'
import { unityTheme } from '@ntpkunity/controls'

export const lightTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette
  }
})

export const marketplaceTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: '#0033FD' }
  }
})
