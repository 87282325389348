import { styled } from '@mui/system'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import LayoutTextLast from '@shared/assets/images/layout-text-last.svg'
import LayoutTextRight from '@shared/assets/images/layout-text-right.svg'
import LayoutTextFirst from '@shared/assets/images/layout-text-first.svg'
import LayoutTextLeft from '@shared/assets/images/layout-text-left.svg'

export const PanelWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.accordion-panels-wrap': {
    '.u-custom-file-upload': {
      marginBottom: 0
    },
    '.layout-selection-wrap': {
      '.custom-radio-wrap': {
        width: '100%',
        '.MuiFormGroup-row': {
          gap: 8,
          '.u-custom-control-label': {
            marginRight: 0,
            marginBottom: 0,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            color: theme.palette.grey[600],
            backgroundColor: 'transparent',
            lineHeight: 'normal',
            flexBasis: '48px',
            maxWidth: 80,
            width: '100%',
            height: 48,
            borderRadius: 8,
            opacity: 0.2,
            '.custom-radio': {
              marginRight: 0,
              position: 'relative',
              svg: {
                display: 'none'
              },
              '&::before': {
                display: 'block',
                content: '" "',
                width: 32,
                height: 32,
                backgroundPosition: 'center center',
                backgroundSize: 32,
                zIndex: 1,
                position: 'relative',
                backgroundRepeat: 'no-repeat'
              },
              '&.Mui-checked': {
                color: theme.palette.grey[900]
              }
            },
            '&:nth-child(1)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${LayoutTextLast})`
                }
              }
            },
            '&:nth-child(2)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${LayoutTextRight})`,
                  width: 64,
                  backgroundSize: 64
                }
              }
            },
            '&:nth-child(3)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${LayoutTextFirst})`
                }
              }
            },
            '&:nth-child(4)': {
              '.custom-radio': {
                '&::before': {
                  backgroundImage: `url(${LayoutTextLeft})`,
                  width: 64,
                  backgroundSize: 64
                }
              }
            },
            '&.selected': {
              borderColor: 'rgba(0, 0, 0, 0.2)',
              opacity: 1
            },
            [theme.breakpoints.down('sm')]: {
              flexShrink: 0
            }
          }
        }
      }
    }
  }
}))
