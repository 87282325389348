import { type ITopRevenueProducts, type IProduct } from '@shared/typings'
import Http from '@shared/helper/http-api'
import { useQuery } from 'react-query'
import { type AppError } from 'single-spa'
import { formatedDate } from '@shared/helper'

const BASE_URL = process.env.BASE_URL

export const PRODUCTS = 'signup/products/available'
export const REVENUE =
  'marketplace/admin/dashboard/revenue-split-by-subscribed-plan'
export const useGetProducts = (): {
  data: IProduct[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const { data, error, isLoading } = useQuery<IProduct[]>(
    PRODUCTS,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(PRODUCTS)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error, isLoading }
}

export const useGetRevenueByPlan = (
  productId: number,
  startDate: Date,
  endDate: Date
): {
  data: ITopRevenueProducts[] | undefined
  error: AppError | unknown
  isLoading: boolean
} => {
  const STARTDATE = formatedDate(startDate, 'YYYY-MM-DD')
  const ENDDATE = formatedDate(endDate, 'YYYY-MM-DD')
  let url = `${REVENUE}`
  if (STARTDATE.length > 0 && ENDDATE.length > 0) {
    url += `/${productId}?start_date=${STARTDATE}&end_date=${ENDDATE}`
  }
  const { data, error, isLoading } = useQuery<ITopRevenueProducts[]>(
    url,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
        withAuthentication: true
      })
      return await apiService.get<any>(url)
    },
    { refetchOnWindowFocus: false, enabled: !!productId }
  )
  return { data, error, isLoading }
}
