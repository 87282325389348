import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const SupportWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.support-wrap': {
    '.section-head': {
      '.badge': {
        backgroundColor: 'rgba(0, 51, 253, 0.1)',
        padding: '8px 16px',
        borderRadius: 24,
        fontWeight: theme.customVariables.fontWeightSemiBold,
        letterSpacing: '0.5px'
      }
    },
    '.support-box': {
      backgroundColor: '#F5F5F7',
      height: '100%',
      borderRadius: 16,
      padding: 40,
      [theme.breakpoints.down('md')]: {
        padding: 24
      },
      '.box-img': {
        maxHeight: 401,
        textAlign: 'center',
        img: {
          maxHeight: 401,
          maxWidth: '100%'
        }
      },
      '.btn-default': {
        color: theme.palette.primary.main,
        '&.with-icon': {
          '&:hover': {
            color: theme.palette.primary.main
          },
          '&:focus': {
            color: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.primary.main
            }
          }
        }
      },
      '&.api-docs': {
        '.btn-default': {
          '.MuiButton-endIcon': {
            transform: 'rotate(180deg)',
            marginRight: 0
          }
        }
      }
    }
  }
}))
