import { type Theme, TableCell, TableRow } from '@mui/material'
import { Skeleton } from '@ntpkunity/controls'
import { type FC } from 'react'

interface ITableSkeletonProps {
  theme: Theme
  columns: number
  rows?: number
}

export const TableSkeleton: FC<ITableSkeletonProps> = ({
  theme,
  columns,
  rows = 1
}) => (
  <>
    {Array(rows)
      .fill(0)
      .map((_, index) => (
        <TableRow key={index} className="child-tr">
          <TableCell colSpan={columns}>
            <Skeleton width={'100%'} height={'100%'} theme={theme} />
          </TableCell>
        </TableRow>
      ))}
  </>
)
