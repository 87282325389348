import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const ExpireLinkWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.expire-link-wrap': {
    '.control-wrap': {
      textAlign: 'left',
      alignItems: 'flex-start'
    }
  }
}))
