import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import {
  BrandingComponent,
  FooterComponent,
  LogoComponent
} from '../../components'
import { Box } from '@ntpkunity/controls'
import { AuthLayout } from './AuthLayoutStyle'

export const AuthLayoutComponent = ({ children }) => {
  const theme = useTheme()
  return (
    <AuthLayout theme={theme} className="auth-layout">
      <Box theme={theme} className="branding-area">
        <BrandingComponent />
      </Box>
      <Box theme={theme} className="main-content-section">
        <Box
          theme={theme}
          className="content-area"
          flexDirection={'column'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <LogoComponent />
          <Box theme={theme} className="auth-form">
            {children}
          </Box>
          <FooterComponent />
        </Box>
      </Box>
    </AuthLayout>
  )
}

AuthLayoutComponent.propTypes = {}

AuthLayoutComponent.defaultProps = {}
