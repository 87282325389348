import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const TestimonialWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.testimonial-wrap': {
    '.badge': {
      backgroundColor: 'rgba(0, 51, 253, 0.1)',
      padding: '8px 16px',
      borderRadius: 24,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      letterSpacing: '0.5px'
    },
    '.section-title': {
      position: 'relative',
      '.text-h1': {
        position: 'relative',
        zIndex: 1
      },
      '.quote-icon': {
        maxHeight: 329,
        marginTop: -55,
        zIndex: -1,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          maxHeight: 165,
          position: 'absolute',
          left: 0,
          right: 0,
          marginTop: -66
        },
        img: {
          maxHeight: 329,
          [theme.breakpoints.down('md')]: {
            maxHeight: 165
          }
        }
      }
    },
    '.user-info': {
      '.seperator': {
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      }
    },
    '.testimonial-image': {
      maxHeight: 41,
      img: {
        maxHeight: 40
      }
    },
    '.testimonial-slider': {
      position: 'relative',
      marginTop: -10,
      marginLeft: -10,
      '.carousel-container': {
        padding: 0
      },
      '.carousel-item': {
        paddingTop: 10,
        paddingLeft: 10
      },
      '.user-img': {
        position: 'relative',
        width: 65,
        height: 65,
        display: 'block',
        borderRadius: '50%',
        boxShadow: '0 0 0 4px' + theme.palette.common.white,
        img: {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          objectFit: 'cover',
          objectPosition: 'center center'
        },
        '.MuiCircularProgress-root': {
          width: '82px !important',
          height: '82px !important',
          position: 'absolute',
          left: -8.5,
          top: -9.45,
          color: theme.palette.primary.main,
          svg: {
            circle: {
              strokeWidth: 2,
              transition:
                'stroke-dashoffset 400ms cubic-bezier(0.0, 0, 0.0, 2) 0ms'
            }
          }
        },
        [theme.breakpoints.down('md')]: {
          margin: 'auto'
        },
        '&:before': {
          content: "' '",
          display: 'block',
          width: 82,
          height: 82,
          position: 'absolute',
          border: '4px solid' + theme.palette.primary.main,
          top: -9,
          left: -8.5,
          borderRadius: '50%'
        }
      },
      '.react-multi-carousel-dot-list': {
        position: 'relative',
        order: 1,
        justifyContent: 'flex-start',
        button: {
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
          position: 'relative',
          opacity: 0.6,
          zIndex: 1,
          cursor: 'pointer',
          '&:not(:first-of-type)': {
            marginLeft: -8
          },
          '&:last-child': {
            zIndex: 0
          },
          '&.active': {
            zIndex: 100,
            opacity: 1,
            '&:before': {
              opacity: 1
            }
          },
          '.user-img': {
            width: 64,
            height: 64,
            display: 'block',
            borderRadius: '50%',
            boxShadow: '0 0 0 4px' + theme.palette.common.white,
            img: {
              width: '100%',
              height: '100%'
            }
          },
          '&:before': {
            content: "' '",
            display: 'block',
            width: 80,
            height: 80,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 74,
            position: 'absolute',
            top: -8,
            right: -11,
            opacity: 0
          }
        },
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
          flex: '0 0 100%',
          maxWidth: '100%'
        }
      },
      '.carousel-button-group': {
        '.carousel-control': {
          transform: 'translateY(0)',
          top: 10,
          marginTop: 0,
          '&.prev-btn': {
            left: 0,
            [theme.breakpoints.up('md')]: {
              left: 'auto',
              right: 64
            }
          },
          '&.next-btn': {
            right: 0,
            transform: 'translatey(0) rotate(180deg)'
          },
          [theme.breakpoints.down('md')]: {
            display: 'block',
            top: 18
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 0
      }
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      '.text-h1': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      }
    }
  }
}))
